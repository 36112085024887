import React from 'react';
import { connect } from 'react-redux';
import { Route, Link } from 'react-router-dom';
import styles from '../Header/header.scss';
import globestyle from '../../sass/global.scss';
import actions from '../../redux/actions/app.js';
import validation from '../../validations';
//import TestSeries from '../../components/TestSeries';
import Mainscreen from '../../components/Profiledetails/mainscreen';
import cx from 'classnames';
import Forgotpassword from '../Forgotpassword';
import Otp from '../Otp';
import JoinNow from '../JoinNow';


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 'login',
            otp_type:'phone-verification',
            mobilenumber: { isValid: false, message: '',value:'' },
            password: { isValid: false, message: '',value:'' },
            error: '',
            isLoggedIn: false,
            type: 'password',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showHide = this.showHide.bind(this);
        this.handleOtp = this.handleOtp.bind(this);
        this._child = React.createRef();
    }

    handleSubmit() {
        const mobilenumber = this.refs.mobilenumber.value;
        const password = this.refs.password.value;
        const isValidMobile = validation('mobile', mobilenumber);
        const isValidPassword = validation('password', password);
        //update state with latest username;
        this.setState({mobilenumber: { isValid: false, message: '',value:mobilenumber }});
        this.setState({password: { isValid: false, message: '',value:password }});

        if (isValidMobile.valid && isValidPassword.valid) {
            this.setState({
                mobilenumber: { isValid: true, message: '', value:mobilenumber },
                password: { isValid: true, message: '',value:password },
            });
            const loginData = { phone: mobilenumber, password: password };
            this.props.onLogin(loginData);
        } else {
            this.setState({
                mobilenumber: { isValid: isValidMobile.valid, message: isValidMobile.message,value:mobilenumber },
                password: { isValid: isValidPassword.valid, message: isValidPassword.message, value:password },
            });
        }
    }

    handleOtp(){
        this.Otp.saveStepTwo();
    }

    showHide(e) {
        e.preventDefault();
        this.setState({
            type: this.state.type === 'password' ? 'input' : 'password',
        });
    }
    showScreen = params => {
        this.setState({
            currentStep: params,
        });
    };

    componentDidUpdate(nextProps) {
        if (this.props.loginFailedResult !== nextProps.loginFailedResult && this.props.loginFailedResult.status_code) {
            this.setState({ error: this.props.loginFailedResult.message });
        }
        if (this.props.loginResult !== nextProps.loginResult && this.props.loginResult && this.props.loginResult.user_id) {
            this.setState({ isLoggedIn: true });
            localStorage.setItem('userdetails', JSON.stringify(this.props.loginResult));
            window.location = '/test-series';
            this.props.isLoggedIn(true);
        }
        if (this.props.loginResult !== nextProps.loginResult && this.props.loginResult && this.props.loginResult.next_step && this.props.loginResult.next_step == 'send-otp') {

            const phone = this.props.loginResult.phone;
            const otp_type = this.state.otp_type;
            this.props.sendOtp({ 'phone': phone, 'otp_type': otp_type });
            this.setState({ currentStep: 'otp' });
        }
        if (this.props.otpLoginResult && nextProps.otpLoginResult !== this.props.otpLoginResult) {
            let phone = this.state.mobilenumber;
            let password = this.state.password;
            const loginData = { phone: phone.value, password: password.value };
            this.props.onLogin(loginData);
        }
    }
    stepLogin(step) {
        if (step !== 'login') {
            return null;
        }
        const { mobilenumber, password, error, isLoggedIn, type } = this.state;
        if (isLoggedIn) { return <Route exact path='/profile-details/mainscreen' component={Mainscreen} />; }
        return (
            <div className={cx(styles.loginblock)}>
                <form>
                    <div className={cx(styles.login_title_container)}>
                        <h1>Login</h1>
                    </div>
                    <span className={error ? 'text-danger font-weight-bold d-flex justify-content-center' : 'd-none'}>{error ? error : ''}</span>
                    <div className={cx('row')}>
                        <div className={cx('col')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Mobile No</legend>
                                    <input type='text' ref='mobilenumber' placeholder='Mobile number' className={cx('form-control')} maxLength='10' />
                                </fieldset>
                                <span className={!mobilenumber.isValid ? 'text-danger small font-italic' : 'd-none'}>{!mobilenumber.isValid ? mobilenumber.message : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row ')}>
                        <div className={cx('col')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Password</legend>
                                    <input type={type} ref='password' placeholder='Password' className={cx('form-control')} />
                                    <span className={cx(globestyle.password_show, (type === 'password' ? 'fa fa-eye-slash' : ' fa fa-eye'))} onClick={this.showHide}></span>
                                </fieldset>
                                <span className={!password.isValid ? 'text-danger small font-italic' : 'd-none'}>{!password.isValid ? password.message : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.bottom_container)}>
                        <div className={cx('row')}>
                            <div className={cx('col-md-6')}>
                                <Link to='#' className={cx(styles.forgotpassword)} onClick={() => this.showScreen('forget-pass')}>Forgot Password?</Link>
                            </div>
                            <div className={cx('col-md-6', styles.login_button)}>
                                <button
                                    className={cx('theme-btn', 'blue')}
                                    type='button' onClick={this.handleSubmit}>
                                    LOGIN
                                </button>
                            </div>
                        </div>
                        <div className={cx(styles.join_now_text_container)}>
                            <p>
                                Not Joined Yet?
                                <Link to='#' className={cx('font-weight-bold')} onClick={() => this.showScreen('register')}><u>JOIN NOW</u></Link>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    stepForgetpass(step) {
        if (step !== 'forget-pass') {
            return null;
        }
        return (
            <Forgotpassword />

        );
    }

    stepOtpVerify(step) {
        if (step !== 'otp') {
            return null;
        }
        const phone = this.props.loginResult.phone;
        const otp_type = this.state.otp_type;//this.props.signUpResult.data.otp_type;
        return (
            <div className={cx(styles.otpblock)}>
                <div className={cx(styles.otp_title_container)}>
                    <h1>Login</h1>
                </div>
                <div className={cx(styles.otp_body_container)}>
                    <Otp phone={phone} otpType={otp_type} onRef={ref => (this.Otp = ref)} />
                </div>
                <div className={cx(styles.otp_submit_container)}>
                    <button
                        className={cx('theme-btn', 'blue')}
                        type='button' onClick={this.handleOtp}>
                        VERIFY OTP
                    </button>
                </div>
            </div>



        );
    }
    stepCalljoinnow(step) {
        if (step !== 'register') {
            return null;
        }
        return (
            <JoinNow />

        );
    }

    render() {
        return (
            <div className={cx(styles.join_container)}>
                <React.Fragment>
                    {this.stepLogin(this.state.currentStep)}
                    {this.stepForgetpass(this.state.currentStep)}
                    {this.stepOtpVerify(this.state.currentStep)}
                    {this.stepCalljoinnow(this.state.currentStep)}
                </React.Fragment>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        otpLoginResult: (state.app.otplogin ? state.app.otplogin : null),
        loginFailedResult: state.app.loginfailed,
        loginResult: state.app.login,
        otp: (state.app.otp ? state.app.otp : null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onLogin: (loginData) => dispatch(actions.getLogin(loginData)),
        sendOtp: (otpData) => dispatch(actions.getOtp(otpData)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
