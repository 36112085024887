const Validation = (type, val) => {
    let message = {};
    switch (type) {
    case 'mobile':
        if (!val) {
            message = 'Mobile number cannot be blank.';
            return { message: message, valid: false };
        } else {
            const exp = /^[0][1-9]\d{10}$|^[1-9]\d{9}$/g;
            const checkmobile = val.match(exp);
            if (!checkmobile || checkmobile == null) {
                message = 'Invalid Mobile number.';
                return { message: message, valid: false };
            } else {
                message = 'Valid Mobile number.';
                return { message: message, valid: true };
            }
        }
    case 'pincode':
        if (!val) {
            message = 'Pincode cannot be blank.';
            return { message: message, valid: false };
        } else {
            const exp = /^[1-9][0-9]{5}$/g;
            const checkpincode = val.match(exp);
            if (!checkpincode || checkpincode == null) {
                message = 'Invalid Pincode.';
                return { message: message, valid: false };
            } else {
                message = 'Valid Pincode.';
                return { message: message, valid: true };
            }
        }
    case 'password':
        if (!val) {
            message = 'Password cannot be blank.';
            return { message: message, valid: false };
        } else {
            const checkpassword = val.length >= 6;
            if (!checkpassword) {
                message = 'Password length should be minimum 6 characters.';
                return { message: message, valid: false };
            } else {
                message = 'Valid Password.';
                return { message: message, valid: true };
            }
        }
    case 'name':
        if (!val) {
            message = 'Name cannot be blank.';
            return { message: message, valid: false };
        } else {
            message = 'Valid Name.';
            return { message: message, valid: true };
        }

    case 'subject':
        if (!val) {
            message = 'Subject cannot be blank.';
            return { message: message, valid: false };
        } else {
            message = 'Valid Subject.';
            return { message: message, valid: true };
        }
    case 'query':
        if (!val) {
            message = 'This field cannot be blank.';
            return { message: message, valid: false };
        } else {
            message = 'Valid Query.';
            return { message: message, valid: true };

        }
    case 'email':
        if (!val) {
            message = 'Email cannot be blank.';
            return { message: message, valid: false };
        } else {
            const exp = /\S+@\S+\.\S{2,}/;
            const validEmail = val.match(exp);
            if (!validEmail || validEmail == null) {
                message = 'Invalid email address.';
                return { message: message, valid: false };
            } else {
                message = 'Valid email address.';
                return { message: message, valid: true };
            }
        }
    case 'date':
        if (!val) {
            message = 'date cannot be blank.';
            return { message: message, valid: false };
        } else {
            const exp = /^\d{4}-\d{2}-\d{2}$/;
            const validDate = val.match(exp);
            if (!validDate || validDate == null) {
                message = 'Invalid date format.';
                return { message: message, valid: false };
            } else {
                message = 'Valid date format.';
                return { message: message, valid: true };
            }
        }
    case 'time':
        if (!val) {
            message = 'time cannot be blank.';
            return { message: message, valid: false };
        } else {
            const exp = /^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/;
            const validTime = val.match(exp);
            if (!validTime || validTime == null) {
                message = 'Invalid time format.';
                return { message: message, valid: false };
            } else {
                message = 'Valid time format.';
                return { message: message, valid: true };
            }
        }
    }

};
export default Validation;
