import React from 'react';
import styles from './profiledetails.scss';
import cx from 'classnames';
import validation from '../../validations';
import actions from '../../redux/actions/app.js';
import globestyle from '../../sass/global.scss';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

class Financialdetails extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            Pannumber: { isValid: false, message: '' },
            Accountnumber:{ isValid: false, message: '' },
            Nameinbank:{ isValid: false, message: '' },
            IFSCcode:{ isValid: false, message: '' },
            modal: false,
            submitMessage: '',
            pancopy : [],
            id:'',
            userinfo: null,
            isLoggedIn: true,

        };

        this.toggle = this.toggle.bind(this);
        this.handlesubmit = this.handlesubmit.bind(this);
        this.handleUpload = this.handleUpload.bind(this);

    }
    componentDidMount() {
        this.props.onRef(this);
        this.props.getFinancialdetails();

    }

    componentWillUnmount() {
        this.props.onRef(undefined);

    }

    componentDidUpdate(nextProps) {
        if(this.props.Financialdetailslist!==nextProps.Financialdetailslist && this.props.Financialdetailslist) {
            this.setState({
                name: this.props.Financialdetailslist.data.financial_details.bank_account_name,
                id:this.props.Financialdetailslist.data.financial_details.id,
                ifsc_code:this.props.Financialdetailslist.data.financial_details.ifsc_code,
                bank_account_no:this.props.Financialdetailslist.data.financial_details.bank_account_no,
                pan_url:this.props.Financialdetailslist.data.financial_details.pan_url,
                pan_no:this.props.Financialdetailslist.data.financial_details.pan_no,
                gstin:this.props.Financialdetailslist.data.financial_details.gstin,
                pan_copy: this.props.Financialdetailslist.data.financial_details.pan_url
            });


        }

        if (this.props.submitFinancedata != nextProps.submitFinancedata && this.props.submitFinancedata && this.props.submitFinancedata.status_code == 200) {
            this.setState({
                submitMessage: this.props.submitFinancedata.message,
            });
            this.toggle();
        }
    }
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handlesubmit(){
        const Pannumber = this.refs.pannumber.value;
        const Accountnumber = this.refs.accountnumber.value;
        const Nameinbank = this.refs.nameinbank.value;
        const IFSCcode = this.refs.ifsc.value;
        const Pancopy = this.state.pancopy;
        const Bankname = this.refs.bankname.value;
        const gstin = this.refs.gstin.value;

        const isValidPannumber = validation('query', Pannumber);
        const isValidAccountnumber = validation('query', Accountnumber);
        const isValidNameinbank = validation('query', Nameinbank);
        const isValidIFSCcode = validation('query', IFSCcode);

        if(isValidPannumber.valid &&  isValidAccountnumber.valid && isValidNameinbank.valid && isValidIFSCcode.valid ) {
            this.setState({
                Pannumber: { isValid: true, message: ''},
                Accountnumber:{ isValid: true, message: ''},
                Nameinbank:{ isValid: true, message: ''},
                IFSCcode:{ isValid: true, message: ''},

            });
            const submitData = { pan_no: Pannumber,bank_account_no:Accountnumber,
                Bankname:Bankname,bank_account_name:Nameinbank,ifsc_code:IFSCcode,gstin:gstin,id:this.state.id, pan_photo:Pancopy
            };
            this.props.submitFinancedetails(submitData);
        } else {
            this.setState({
                Pannumber: { isValid: isValidPannumber.valid, message: isValidPannumber.message },
                Accountnumber: { isValid: isValidAccountnumber.valid, message: isValidAccountnumber.message },
                Nameinbank: { isValid: isValidNameinbank.valid, message: isValidNameinbank.message },
                IFSCcode: { isValid: isValidIFSCcode.valid, message: isValidIFSCcode.message },


            });
        }


    }

    handleUpload(event) {
        const target = Array.from(event.target.files);
        if (target) {
            this.setState({
                pancopy : target[0]
            });
        }
    }

    render() {
        const { Pannumber,Accountnumber,Nameinbank,IFSCcode,name,ifsc_code,bank_account_no,pan_no,gstin,submitMessage, pan_copy } = this.state;
        return (
            <div className={cx(styles.refer_index)}>
                <form>
                    <div className={cx(styles.manage_bottom_margin,'row')}>
                        <div className={cx('col-md-3 col-sm-5 col-xs-12 mt-3')}>
                            <div className={cx('form-group')}>

                                <fieldset className={cx(globestyle.fset)}>
                                    <legend className={cx(globestyle.legend,globestyle.small)}>Pan card number</legend>
                                    <input ref='pannumber'
                                        type='text'
                                        value={pan_no?pan_no:''}
                                        className={cx(globestyle.small,'form-control')}
                                        onChange={(event) => this.setState({ pan_no: event.target.value })}
                                    />
                                </fieldset>
                                <span className={!Pannumber.isValid ? 'text-danger small font-italic': 'd-none'}>{!Pannumber.isValid ? Pannumber.message: ''}</span>
                            </div>
                        </div>
                        <div className={cx('col-md-3 col-sm-5 col-xs-12 mt-3')}>
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset)}>
                                    <legend className={cx(globestyle.legend,globestyle.small)}>Pan card copy</legend>
                                    <input
                                        value={pan_copy?pan_copy:''}
                                        ref='pancopy'
                                        type='text'
                                        className={cx(globestyle.small,'form-control')}
                                        onChange={(event) => this.setState({ pan_copy: event.target.value })}
                                    />
                                </fieldset>
                            </div>
                        </div>

                        <div
                            className={cx(
                                styles.uploadicon,
                                'col-md-6 col-sm-6 col-xs-12'
                            )}
                        >


                            <label htmlFor="file-upload" className={cx(styles.customfileupload)}>
                                <div className={cx('row')}>
                                    <div className={cx('col-md-1 ')}><i
                                        className={cx(styles.iconcircle, 'fa fa-upload')}
                                        aria-hidden='true'
                                    ></i></div>
                                    <div className={cx('col-md-11')}>
                                        <label className={cx(styles.label,'pl-2 mt-3')}>
                                            {this.state.pancopy.name}
                                        </label>
                                    </div>

                                </div>

                            </label>
                            <p className={cx(styles.pdfname)}>(scanned PDF or JPEG)</p>
                            <input id="file-upload" type="file" onChange={this.handleUpload} accept=".jpeg, .pdf" />

                        </div>

                    </div>
                    <div className={cx(styles.manage_bottom_margin,'row')}>
                        <div className={cx('col-md-3 col-sm-5 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset)}>
                                    <legend className={cx(globestyle.legend,globestyle.small)}>Bank account number</legend>
                                    <input
                                        ref='accountnumber'
                                        value={bank_account_no?bank_account_no:''}
                                        onChange={(event) => this.setState({ bank_account_no: event.target.value })}
                                        type='text'
                                        className={cx(globestyle.small,'form-control')}
                                    />
                                </fieldset>
                                <span className={!Accountnumber.isValid ? 'text-danger small font-italic': 'd-none'}>{!Accountnumber.isValid ? Accountnumber.message: ''}</span>
                            </div>
                        </div>
                        <div className={cx('col-md-3 col-sm-5 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset)}>
                                    <legend className={cx(globestyle.legend,globestyle.small)}>Bank name</legend>
                                    <input
                                        ref='bankname'
                                        type='text'
                                        className={cx(globestyle.small,'form-control ')}

                                    />
                                </fieldset>
                            </div>
                        </div>
                    </div>



                    <div className={cx(styles.manage_bottom_margin,'row')}>
                        <div className={cx('col-md-3 col-sm-5 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset)}>
                                    <legend className={cx(globestyle.legend,globestyle.small)}>Name in bank account</legend>
                                    <input
                                        ref='nameinbank'
                                        value={name?name:''}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                        type='text'
                                        className={cx(globestyle.small,'form-control')}
                                    />
                                </fieldset>
                                <span className={!Nameinbank.isValid ? 'text-danger small font-italic': 'd-none'}>{!Nameinbank.isValid ? Nameinbank.message: ''}</span>
                            </div>
                        </div>
                        <div className={cx('col-md-3 col-sm-5 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset)}>
                                    <legend className={cx(globestyle.legend,globestyle.small)}>IFSC code </legend>
                                    <input
                                        ref='ifsc'
                                        value={ifsc_code?ifsc_code:''}
                                        onChange={(event) => this.setState({ ifsc_code: event.target.value })}
                                        type='text'
                                        className={cx(globestyle.small,'form-control')}
                                    />
                                </fieldset>
                                <span className={!IFSCcode.isValid ? 'text-danger small font-italic': 'd-none'}>{!IFSCcode.isValid ? IFSCcode.message: ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.manage_bottom_margin,'row')}>
                        <div className={cx('col-md-3 col-sm-5 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset)}>
                                    <legend className={cx(globestyle.legend,globestyle.small)}>GSTIN</legend>
                                    <input
                                        ref='gstin'
                                        value={gstin?gstin:''}
                                        onChange={(event) => this.setState({ gstin: event.target.value })}
                                        type='text'
                                        className={cx(globestyle.small,'form-control')}
                                    />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalBody className={globestyle.messageblock}>
                        <div>
                            <a className={cx('float_right', globestyle.close_button)} onClick={this.toggle}>
                                <i className={cx('far fa-times-circle')}></i>
                            </a>
                        </div>
                        <div className={cx(globestyle.message)}>{submitMessage ? submitMessage : 'Your information saved successfully!!'}</div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        Financialdetailslist: state.app.getfinancedetails,
        submitFinancedata:state.app.submitfinancedetails,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFinancialdetails: () => dispatch(actions.getFinancialdetails()),
        submitFinancedetails: (submitData) => dispatch(actions.editFinancialdetails(submitData)),
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(Financialdetails);
