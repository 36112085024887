import React from 'react';
import styles from './index.scss';
import cx from 'classnames';


const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className='container'>
                <div className={cx(styles.footer_content, 'row')}>
                    <div className={cx(styles.footer_content__options, 'col-sm-12', 'col-md-4')}>
                        <div className={styles.footer_content__option}>About Us</div>
                        <div className={styles.footer_content__option}>Our Vision</div>
                        <div className={styles.footer_content__option}>Why ExamTreasury</div>
                        <div className={styles.footer_content__option}>Our Team</div>
                        <div className={styles.footer_content__option}>Contact Us</div>
                    </div>
                    <div className={cx(styles.footer_content__options, 'col-sm-12', 'col-md-4')}>
                        <div className={styles.footer_content__option}>Important Links</div>
                        <div className={styles.footer_content__option}>Join Us</div>
                        <div className={styles.footer_content__option}>Student Website</div>
                        <div className={styles.footer_content__option}>Feedback / Complaints</div>
                        <div className={styles.footer_content__option}>FAQs</div>
                        <div className={styles.footer_content__option}>ET Teachers App</div>
                    </div>
                    <div className={cx(styles.followUs, 'col-sm-12', 'col-md-4')}>
                        <div className={styles.followUs_content}>Follow Us</div>
                        <div className={styles.followUs_icon}>
                            <i className='fab fa-facebook text-white'></i>
                            <i className='fab fa-linkedin text-white'></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer_termsService}>
                Terms of Services | Privacy Policy | FAQs
            </div>
            <div className={styles.footer_rightsReserved}>
                © 2019 Triginta Technologies, All Right Reserved
            </div>
        </footer>
    );
};

export default Footer;
