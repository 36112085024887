import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions/app.js';
import styles from './revenue.scss';
import globestyle from '../../sass/global.scss';
import cx from 'classnames';
import _ from 'lodash';
import Moment from 'react-moment';

class Revenue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            revenues: null,
        };
    }

    componentDidMount() {
        const revenueParams={ 'mock': 'true' };
        this.props.getRevenues(revenueParams);
    }

    componentDidUpdate(nextProps) {
        if(this.props.revenueDetails!==nextProps.revenueDetails && this.props.revenueDetails) {
            this.setState({ revenues: this.props.revenueDetails});
        }
    }

    render() {
        const { revenues } = this.state;
        const total_revenue = (revenues ? revenues.total_revenue: null);
        return (
            <div className={cx(styles.revenue)}>
                <nav aria-label="breadcrumb">
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to="/test-series">Home</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem, globestyle.active)} aria-current="page"><span>Revenue</span></li>
                    </ol>
                </nav>
                <div className={cx('clearfix')}></div>
                <div className={cx(styles.maincontent)}>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-11')} >
                            <div>
                                <fieldset className={cx(styles.scheduler_border)}>
                                    <legend className={cx(styles.scheduler_border)}>TOTAL (in INR)</legend>
                                    <div className={cx('row')}>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>REVENUE = {total_revenue ? total_revenue.total.toLocaleString(undefined, {maximumFractionDigits:2}): 0}</strong>
                                        </div>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>TRANSFERRED = {total_revenue ? total_revenue.transferred.toLocaleString(undefined, {maximumFractionDigits:2}): 0}</strong>
                                        </div>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>PENDING = {total_revenue ? total_revenue.pending.toLocaleString(undefined, {maximumFractionDigits:2}): 0}</strong>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.revenue_summary_container)}>
                        {revenues && revenues.exams.length>0 ?
                            <div>
                                <div className={cx('row', styles.revenue_summary_header)}>
                                    <div className={cx('col')}>
                                        EXAM NAME
                                    </div>
                                    <div className={cx('col')}>
                                        STARTING DATE
                                    </div>
                                    <div className={cx('col')}>
                                        UNIT SOLD
                                    </div>
                                    <div className={cx('col')}>
                                        REVENUE <span>(INR)</span>
                                    </div>
                                    <div className={cx('col')}>
                                        TRANSFERRED <span>(INR)</span>
                                    </div>
                                    <div className={cx('col')}>
                                        PENDING <span>(INR)</span>
                                    </div>
                                </div>
                                <Link to={{ pathname: '/revenue/exam-test-series/' }}>
                                    {_.map(revenues.exams.length > 0 && revenues.exams, (exam, i) => {
                                        return (
                                            <div className={cx('row', styles.revenue_summary_body)} key={i}>

                                                <div className={cx('col')}>
                                                    {exam.title}
                                                </div>
                                                <div className={cx('col')}>
                                                    <Moment format="YYYY/MM/DD">{exam.start_date}</Moment>
                                                </div>
                                                <div className={cx('col')}>
                                                    {exam.count}
                                                </div>
                                                <div className={cx('col')}>
                                                    {exam.total.toLocaleString(undefined, {maximumFractionDigits:2})}
                                                </div>
                                                <div className={cx('col')}>
                                                    {exam.transferred.toLocaleString(undefined, {maximumFractionDigits:2})}
                                                </div>
                                                <div className={cx('col')}>
                                                    {exam.pending.toLocaleString(undefined, {maximumFractionDigits:2})}
                                                </div>
                                            </div>
                                        );
                                    })
                                    }
                                </Link>

                            </div>
                            : null}
                        {revenues && revenues.exams.length == 0 ? <div className={cx(globestyle.norecords)}>No records found</div> : null}
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps (state) {
    return {
        revenueDetails: state.app.revenues,
    };
}

function mapDispatchToProps (dispatch) {
    return {
        getRevenues: (revenueParams) => dispatch(actions.getRevenues(revenueParams)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Revenue);
