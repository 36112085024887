import React from 'react';
import styles from './testseries.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';
import _ from 'lodash';
import Moment from 'react-moment';
import globestyle from '../../sass/global.scss';
import Schedulepublishbox from '../modalbox/schedulepublish';

class UnPublished extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showdetails: false,
            exam: '',
            test_series_id: '',
            status: '',
            popupkey :''
        };
        this.movetabs = this.movetabs.bind(this);
        this.drafttopublish = this.drafttopublish.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }
    componentWillMount() {
        if (this.props.displayUnpublishedlist && this.props.displayUnpublishedlist.test_series) {
            this.setState({
                'unpublishedlist': this.props.displayUnpublishedlist.test_series,
            });
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        const unpublishedData = { status: 'unpublished', exam: this.props.activeData ? this.props.activeData.activeExamId :''};
        this.props.onUnpublished(unpublishedData);
    }

    componentDidUpdate(nextProps) {
        if (this.props.activeData != nextProps.activeData && this.props.activeData) {
            if (this.props.activeData.activeExamId > 0) {
                const unpublishedData = { status: 'unpublished', exam: this.props.activeData ? this.props.activeData.activeExamId : null };
                this.props.onUnpublished(unpublishedData);
            }
        }
        if (this.props.displayUnpublishedlist!=nextProps.displayUnpublishedlist && this.props.displayUnpublishedlist) {
            if(this.props.displayUnpublishedlist.test_series){
                this.setState({
                    'unpublishedlist': this.props.displayUnpublishedlist.test_series,
                });
            }
        }
        if(this.props.drafttopublish !== nextProps.drafttopublish && this.props.drafttopublish && this.props.drafttopublish.status_code == 200) {
            if(this.props.activeData!=nextProps.activeData && this.props.activeData.activeExamId>0) {
                const unpublishedData = { status: 'unpublished', exam: this.props.activeData ? this.props.activeData.activeExamId:null};
                this.setState({
                    showdetails: false,

                });
                this.props.onUnpublished(unpublishedData);
            }
        }
        if(this.props.schedulepublishdata !== nextProps.schedulepublishdata && this.props.schedulepublishdata.data && this.props.schedulepublishdata.status_code == 200) {
            this.setState({
                showdetails: false
            });
            if(this.props.activeData && this.props.activeData.activeExamId>0) {
                const unpublishedData = { status: 'unpublished', exam: this.props.activeData ? this.props.activeData.activeExamId:null};
                this.props.onUnpublished(unpublishedData);
            }
        }

    }
    movetabs(i) {
        this.setState({
            showdetails: !this.state.showdetails,
            popupkey :i
        });
    }
    drafttopublish(params) {
        const paramsData = {exam:params.exam,test_series_id:params.test_series_id,status:'published'};
        this.props.ondrafttopublishdata(paramsData);

    }
    publish(unpublishedparams){
        this.setState({
            showmodal : true,
            exam_id : unpublishedparams.exam,
            test_series_id : unpublishedparams.test_series_id
        });
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                showdetails: false,
            });
        }
    }

    render() {
        const { unpublishedlist,test_series_id, exam_id } = this.state;
        return (

            <div>
                {unpublishedlist && unpublishedlist.length > 0 ?
                    <div>
                        {
                            _.map(unpublishedlist.length > 0 && unpublishedlist, (unlist, i) => {
                                const subjects = (unlist.subjects.length ? unlist.subjects : []);
                                const unpublishedparams = {exam: unlist.exam,
                                    test_series_id: unlist.id,

                                };
                                let subjectname = '';
                                if(subjects && subjects.length>0) {
                                    let subjectlist = [];
                                    _.map(subjects, (subj) => {
                                        subjectlist.push(subj.title);
                                    });
                                    subjectname = subjectlist.join(', ');
                                }
                                return (
                                    <div className={cx(styles.table_view)} key={i}>
                                        <div className={cx(styles.liststyle)}>
                                            <div className={cx('col-md-12 pl-0')}>
                                                <Link to={{ pathname: '/test-series/upload_test/' + unlist.id, state: { title: unlist.title, examid: unlist.exam, tabIndex : this.props.tabIndex } }} className={cx(styles.flexstyle)} >
                                                    <div className={cx('col-md-5 pl-0')}>
                                                        <p>
                                                            <b>{unlist.title}</b><br />
                                                            <span className={cx(styles.created_date)}>CREATED ON <Moment format="DD-MM-YYYY">{unlist.created_on}</Moment></span>
                                                        </p>
                                                    </div>
                                                    <div className={cx('col-md-2')}>
                                                        <p className={cx(styles.content_color)}>
                                                            {unlist.tests_count} Test
                                                        </p>
                                                    </div>
                                                    <div className={cx('col-md-3')}>
                                                        <p className={cx(styles.content_color)}>{subjectname ? subjectname : ''}</p>
                                                    </div>
                                                    <div className={cx('col-md-2')}>
                                                        {/* {unlist.progress_status == 'In Development' ?
                                                        <p className={cx(styles.development_text)}>{unlist.progress_status}</p> :
                                                        unlist.progress_status == 'unpublished' ?
                                                            <button type='button' className={cx(styles.publish_button)} onClick={this.movetabs}>{unlist.progress_status}</button> :
                                                            <p className={cx(styles.publish_text)}>
                                                                SCHEDULED PUBLISH <br />
                                                                ON 00-00-0000
                                                            </p>
                                                    } */}
                                                        {unlist.progress == 'published' ? <p className={cx(styles.publish_text)}>scheduled publish on <br /><Moment format="DD-MM-YYYY">{unlist.published_on}</Moment></p> : <p className={cx(styles.development_text)}>{unlist.progress_status}</p>}
                                                    </div>
                                                </Link>
                                            </div>
                                            <div>
                                                <i onClick={()=>this.movetabs(i)} className={cx(styles.details_icon, 'fa fa-ellipsis-v')}></i>
                                            </div>
                                            {this.state.showdetails == true && this.state.popupkey == i ?
                                                <div className={cx(styles.popupbox)} ref={this.setWrapperRef}>

                                                    <ul>
                                                        <li onClick={() => this.drafttopublish(unpublishedparams)}>Publish</li>
                                                        <li onClick={() => this.publish(unpublishedparams)} data-toggle='modal' data-target='#schedule-box'>Schedule publish</li>
                                                    </ul>

                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div> :
                    null
                }
                <div>
                    {this.state.showmodal ? <Schedulepublishbox test_series_id={test_series_id} examId={exam_id}  />:null}
                </div>
                {unpublishedlist && unpublishedlist.length==0 ? <div className={cx(globestyle.norecords)}>No records found</div>: null }
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        displayUnpublishedlist: (state.app.unpublishedlist ? state.app.unpublishedlist : null),
        activeData: (state.app.activedata ? state.app.activedata : null),
        drafttopublish : (state.app.drafttopublish ? state.app.drafttopublish : null),
        schedulepublishdata:(state.app.schedulepublish ? state.app.schedulepublish: null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onUnpublished: (unpublishedData) => dispatch(actions.getUnpublishedlist(unpublishedData)),
        ondrafttopublishdata: (paramsData) => dispatch(actions.getDrafttopublish(paramsData)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnPublished);
