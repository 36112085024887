import React from 'react';
import styles from './index.scss';
import globestyle from '../../sass/global.scss';
import cx from 'classnames';

class referAndEarn extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        return true;
    }

    render() {
        return (
            <div className={cx(styles.refer_index)}>
                <div className={cx('row')}>
                    <div className={cx('col')}>
                        <div className={cx(styles.manage_bottom_margin)}>
                            <p className={cx(styles.refer_tagp, styles.refer_tagp_color_normal)}> Your total referral revenue <strong>  3000 INR</strong></p>
                            <p className={cx(styles.refer_tagp, styles.refer_tagp_color_normal)}> Total referral <strong>4</strong></p>
                        </div>
                    </div>
                </div>
                <div className={cx('row')}>
                    <div className={cx('col')}>
                        <p className={cx(styles.refer_tagp, styles.refer_tagp_color_blue)}>
                            Refer ExamTreasury to teacher to join and earn upto Rs xxxx.
                        </p>
                    </div>
                </div>
                <form onSubmit={this.handleSubmit}  action='/refer-and-earn/refer-success'>
                    <div className={cx('row')}>
                        <div className={cx('col-md-4 col-sm-6 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset)}>
                                    <legend className={cx(globestyle.legend)}>Name</legend>
                                    <input type='text' className={cx('form-control')} />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row')}>
                        <div className={cx('col-md-4 col-sm-6 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset)}>
                                    <legend className={cx(globestyle.legend)}>Mobile number</legend>
                                    <input type='text' className={cx('form-control')} />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row')}>
                        <div className={cx('col-md-4 col-sm-6 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset)}>
                                    <legend className={cx(globestyle.legend)}>Email ID</legend>
                                    <input type='text' className={cx('form-control')} />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row')}>
                        <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <button className={cx('theme-btn', 'blue')} type="submit">SEND INVITATION</button>
                                {/* <button type="button" className={cx('btn btn-theme')}>Primary</button> */}
                            </div>
                        </div>
                    </div>
                </form>
                <div className={cx(styles.amount_container)}>
                    <div>
                        <p className={cx(styles.refer_tagp_color_blue_bottom)}>
                            Hide referral payment status
                        </p>
                    </div>
                    <div className={cx('row justify-content-md-center')}>
                        <div className="col col-lg-11" >
                            <div>
                                <fieldset className={cx(styles.scheduler_border)}>
                                    <legend className={cx(styles.scheduler_border)}>TOTAL (in INR)</legend>
                                    <div className="row">
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>REVENUE = 5,54,800</strong>
                                        </div>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>TRANSFERRED = 5,54,800</strong>
                                        </div>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>PENDING = 5,54,800</strong>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default referAndEarn;
