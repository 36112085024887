import React from 'react';
import styles from './eligibilitymodal.scss';
import cx from 'classnames';

const EligibilityModal = () => {
    return (
        <div className="modal fade" role="dialog" id = "eligibility-modal">
            <div className={cx(styles.modal_dialog,'modal-dialog')}>
                <div className={cx(styles.modal_content,'modal-content')}>
                    <div className={cx(styles.modal_header, 'modal-header')}>
                        <button className={cx(styles.backbtn)}><span className={cx(styles.arrow_left, 'fa fa-arrow-left')}></span>Back</button>
                        <button type="button" className={cx('close', styles.btn_width)} data-dismiss="modal"><span className={cx('fa fa-times-circle')}></span></button>
                    </div>
                    <div className={cx('modal-body')}>
                        <div className={cx(styles.header_alignment,'text-center')}>
                            <h1 className={cx(styles.header_height)}><b>Congratulations!</b></h1>
                        </div>
                        <div className={cx(styles.header_alignment, 'text-center')}>
                            <p className={cx(styles.header_height, styles.subheading_font)}><b>48 Teacher with similar eligibility are in our family.</b></p>
                        </div>
                        <div>
                            <div className={cx(styles.label_heading)}>
                                <label className = {cx(styles.label_style)}>Exam they are covering</label>
                            </div>
                            <div>
                                <div className={cx(styles.align_row ,styles.display_row_style)}>
                                    <div className = {cx('row')}>
                                        <div className={cx(styles.logo_block)}>
                                        </div>
                                        <div className = {cx(styles.align_middle)}>
                                            <label className = {cx(styles.label_style)}>JEE mains and advanced</label>
                                        </div>
                                    </div>
                                    <div className = {cx('row')}>
                                        <div className={cx(styles.logo_block)}>
                                        </div>
                                        <div className = {cx(styles.align_middle)}>
                                            <label className = {cx(styles.label_style)}>JEE mains and advanced</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx(styles.align_row ,styles.display_row_style)}>
                                    <div className = {cx('row')}>
                                        <div className={cx(styles.logo_block)}>
                                        </div>
                                        <div className = {cx(styles.align_middle)}>
                                            <label className = {cx(styles.label_style)}>JEE mains and advanced</label>
                                        </div>
                                    </div>
                                    <div className = {cx('row')}>
                                        <div className={cx(styles.logo_block)}>
                                        </div>
                                        <div className = {cx(styles.align_middle)}>
                                            <label className = {cx(styles.label_style)}>JEE mains and advanced</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx('modal-footer', styles.modal_footer)}>
                        <label className = {cx(styles.label_style)}><b>Don't wait, It's the right time</b></label>
                        <button type="button" className={cx('btn', styles.submit_button)} data-dismiss="modal">JOIN NOW</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EligibilityModal;
