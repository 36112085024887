import React from 'react';
import { connect } from 'react-redux';
import styles from './testseries.scss';
import actions from '../../redux/actions/app.js';
import cx from 'classnames';
import _ from 'lodash';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import globestyle from '../../sass/global.scss';

class Published extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publishedlist: [],
        };
    }

    componentWillMount() {
        if (this.props.displayPublishedlist && this.props.displayPublishedlist.test_series) {
            this.setState({
                'publishedlist': this.props.displayPublishedlist.test_series,
            });
        }
    }

    componentDidMount() {
        const publishedData = { status: 'published', exam: this.props.activeData ? this.props.activeData.activeExamId : null };
        this.props.onPublished(publishedData);
    }

    componentDidUpdate(nextProps) {
        if (this.props.displayPublishedlist != nextProps.displayPublishedlist && this.props.displayPublishedlist) {
            if(this.props.displayPublishedlist.test_series){
                this.setState({
                    'publishedlist': this.props.displayPublishedlist.test_series,
                });
            }
        }
        if (this.props.activeData != nextProps.activeData && this.props.activeData) {
            if (this.props.activeData.activeExamId > 0) {
                const publishedData = { status: 'published', exam: this.props.activeData ? this.props.activeData.activeExamId : null };
                this.props.onPublished(publishedData);
            }
        }
    }

    render() {
        const { publishedlist } = this.state;
        return (
            <div>
                {publishedlist && publishedlist.length > 0 ?
                    <div>
                        {
                            _.map(publishedlist.length > 0 && publishedlist, (pulist, i) => {
                                return (
                                    <div className={cx(styles.table_view)} key={i}>
                                        <div className={cx(styles.liststyle)}>
                                            <div className={cx('col-md-11 pl-0')}>
                                                <Link className={cx(styles.flexstyle)} to={{ pathname: '/test-series/upload_test/' + pulist.id, state: { title: pulist.title, examid: pulist.exam, tabIndex: this.props.tabIndex } }} >

                                                    <div className={cx('col-md-9 pl-0')}>
                                                        <p>
                                                            <b>{pulist.title}</b><br />
                                                            <span className={cx(styles.created_date)}>PUBLISHED ON <Moment format="DD-MM-YYYY">{pulist.created_on}</Moment></span>

                                                        </p>
                                                    </div>
                                                    <div className={cx('col-md-2')}>
                                                        <p className={cx(styles.number_font)}>{pulist.units_sold}
                                                            <span className={cx(styles.unit_text_font)}>UNIT SOLD</span>
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className={cx('col-md-1')}>
                                                <i className={cx(styles.details_icon, 'fa fa-ellipsis-v')}></i>
                                            </div>
                                        </div>

                                    </div>
                                );
                            })
                        }
                    </div> : null
                }
                {publishedlist && publishedlist.length == 0 ? <div className={cx(globestyle.norecords)}>No records found</div> : null}
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        displayPublishedlist: (state.app.publishedlist ? state.app.publishedlist : null),
        activeData: (state.app.activedata ? state.app.activedata : null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onPublished: (publishedData) => dispatch(actions.getPublishedlist(publishedData)),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Published);
