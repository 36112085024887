import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './revenue.scss';
import globestyle from '../../sass/global.scss';
import actions from '../../redux/actions/app.js';
import cx from 'classnames';
import _ from 'lodash';
import Moment from 'react-moment';

class examTestSeries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            revenuedetails: null,
        };
    }


    componentDidMount() {
        if (this.props.activeData) {
            const data = { mock: 'true', examid: this.props.activeData.activeExamId };
            this.props.getRevenuesdetails(data);
        }

    }

    componentDidUpdate(nextProps) {
        if (this.props.revenuetestDetails !== nextProps.revenuetestDetails && this.props.revenuetestDetails) {
            this.setState({ revenuedetails: this.props.revenuetestDetails });
        }
        if (this.props.activeData !== nextProps.activeData && this.props.activeData) {
            const data = { mock: 'true', examid: this.props.activeData.activeExamId };
            this.props.getRevenuesdetails(data);

        }
    }


    render() {
        const { revenuedetails } = this.state;
        const total_revenue = (revenuedetails ? revenuedetails.total_revenue : null);
        return (
            <div className={cx(styles.revenue)}>
                <nav aria-label="breadcrumb">
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to="/test-series">Home</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to='/revenue'>Revenue</Link></li>
                    </ol>
                </nav>
                <div className={cx('clearfix')}></div>
                <div className={cx(styles.maincontent)}>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-11')} >
                            <div>
                                <fieldset className={cx(styles.scheduler_border)}>
                                    <legend className={cx(styles.scheduler_border)}>TOTAL (in INR)</legend>
                                    <div className={cx('row')}>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>REVENUE = {total_revenue ? total_revenue.total.toLocaleString(undefined, {maximumFractionDigits:2}) : 0}</strong>
                                        </div>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>TRANSFERRED = {total_revenue ? total_revenue.transferred.toLocaleString(undefined, {maximumFractionDigits:2}) : 0}</strong>
                                        </div>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>PENDING = {total_revenue ? total_revenue.pending.toLocaleString(undefined, {maximumFractionDigits:2}) : 0}</strong>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.revenue_summary_container)}>
                        {revenuedetails && revenuedetails.exams.length > 0 ?
                            <div>
                                <div className={cx('row', styles.revenue_summary_header)}>
                                    <div className={cx('col-3')}>
                                        TEST SERIES NAME
                                    </div>
                                    <div className={cx('col')}>
                                        PUBLISHED DATE
                                    </div>
                                    <div className={cx('col')}>
                                        PRICE <span>(INR)</span>
                                    </div>
                                    <div className={cx('col')}>
                                        UINT SOLD
                                    </div>
                                    <div className={cx('col')}>
                                        REVENUE
                                    </div>
                                    <div className={cx('col')}>
                                        TRANSFERRED <span>(INR)</span>
                                    </div>
                                    <div className={cx('col')}>
                                        PENDING <span>(INR)</span>
                                    </div>
                                </div>

                                {_.map(revenuedetails.exams.length > 0 && revenuedetails.exams, (exam, i) => {
                                    return (
                                        <Link to={{ pathname: '/revenue/test-detail/' + exam.id, state: { examTitle: exam.title,examDate:exam.published_date} }} key={i}>
                                            <div className={cx('row', styles.revenue_summary_body)} key={i}>
                                                <div className={cx('col-3')}>
                                                    {exam.title}
                                                </div>
                                                <div className={cx('col')}>
                                                    <Moment format="DD-MM-YYYY">{exam.published_date}</Moment>
                                                </div>
                                                <div className={cx('col')}>
                                                    {exam.price}
                                                </div>
                                                <div className={cx('col')}>
                                                    {exam.count}
                                                </div>
                                                <div className={cx('col')}>
                                                    {exam.total.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                </div>
                                                <div className={cx('col')}>
                                                    {exam.transferred.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                </div>
                                                <div className={cx('col')}>
                                                    {exam.pending.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                })
                                }

                            </div>
                            : null}
                        {revenuedetails && revenuedetails.exams.length == 0 ? <div className={cx(globestyle.norecords)}>No records found</div> : null}
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        revenuetestDetails: state.app.revenuedetails,
        activeData: (state.app.activedata ? state.app.activedata : null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRevenuesdetails: (data) => dispatch(actions.getRevenuedetails(data)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(examTestSeries);
