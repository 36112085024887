import React from 'react';
// import { Redirect } from 'react-router-dom';
import styles from './index.scss';
import cx from 'classnames';
// import _ from 'lodash';

class referSuccess extends React.Component {

    render() {
        return (
            <div className={cx(styles.refer_success)}>
                <div className={cx('row')}>
                    <div className={cx('col')}>
                        <div className={cx(styles.manage_bottom_margin)}>
                            <p className={cx(styles.refer_tagp, styles.refer_tagp_color_normal)}> Your total referral revenue <strong>  3000 INR</strong></p>
                            <p className={cx(styles.refer_tagp, styles.refer_tagp_color_normal)}> Total referral <strong>4</strong></p>
                        </div>
                    </div>
                </div>
                <div className={cx('row')}>
                    <div className={cx('col')}>
                        <p className={cx(styles.refer_tagp, styles.refer_tagp_color_blue)}>
                            Refer ExamTreasury to teacher to join and earn upto Rs xxxx.
                        </p>
                    </div>
                </div>
                <div className={cx('row')}>
                    <div className={cx('col')}>
                        <p className={(cx(styles.success_message))}>
                            Thank you for referring ExamTreasury <br/>
                            We will keep you updated on the joining status of Amit Kumar <br />
                            <a className={(cx(styles.ref_another))} href=''>Refer Another</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}


export default referSuccess;
