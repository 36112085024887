import React from 'react';
import { Link } from 'react-router-dom';
import globestyle from '../../sass/global.scss';
import styles from '../HomePage/index.scss';
import pagestyle from './joinnow.scss';
import cx from 'classnames';
import validation from '../../validations';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';
import Otp from '../Otp';
import CallRequest from '../CallRequest';
import Mainscreen from '../../components/Profiledetails/mainscreen';
import Login from '../Login';

class JoinNow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            isLoggedIn: false,
            error: '',
            username: '',
            password_data: '',
            name: { isValid: false, message: '' },
            phone: { isValid: false, message: '' },
            email: { isValid: false, message: '' },
            password: { isValid: false, message: '' },
            confirm_password: { isValid: false, message: '' },
            passwordtype: 'password',
            cpasswordtype: 'password',
        };
        this.showHide = this.showHide.bind(this);
        this._child = React.createRef();
    }

    componentWillMount() {
        this.props.onLogin();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.signUpResult !== this.props.signUpResult && this.props.signUpResult) {
            if (this.props.signUpResult.status_code == 200 && this.props.signUpResult.status == 'failed') {
                this.setState({ error: this.props.signUpResult.message });
            } else if (this.props.signUpResult.status_code == 409 || this.props.signUpResult.status_code == 500) {
                this.setState({ error: this.props.signUpResult.message });
            } else {
                const phone = this.props.signUpResult.data.user_profile.phone;
                const otp_type = this.props.signUpResult.data.otp_type;
                this.props.sendOtp({ 'phone': phone, 'otp_type': otp_type });
                this.next();
            }
        }
        if (this.props.loginFailedResult !== prevProps.loginFailedResult && this.props.loginFailedResult.status_code) {
            this.setState({ error: this.props.loginFailedResult.message });
        }
        if (this.props.loginResult !== prevProps.loginResult && this.props.loginResult.user_id) {
            this.setState({ isLoggedIn: true });
            localStorage.setItem('userdetails', JSON.stringify(this.props.loginResult));
            this.props.isLoggedIn(true);
        }

        if (this.props.otpLoginResult && prevProps.otpLoginResult !== this.props.otpLoginResult) {
            this.next();
        }

        if (this.props.callRqst && prevProps.callRqst !== this.props.callRqst) {
            if(this.props.callRqst.status_code != 200){
                this.setState({ error: this.props.callRqst.message });
            } else {
                const loginData = { phone: this.state.username, password: this.state.password_data };
                this.props.onLogin(loginData);
            }
        }
    }


    handleSubmit = () => {
        if (this.state.currentStep == 1) {
            this.saveStepOne();
        }
        if (this.state.currentStep == 2) {

            this.Otp.saveStepTwo();
        }
        if (this.state.currentStep == 3) {
            this.CallRequest.saveStepThree();
        }
    }

    next() {
        let currentStep = this.state.currentStep;
        currentStep = currentStep >= 2 ? 3 : currentStep + 1;
        this.setState({
            currentStep: currentStep,
        });
    }

    saveStepOne() {
        const name = this.refs.name.value;
        const phone = this.refs.phone.value;
        const email = this.refs.email.value;
        const password = this.refs.password.value;
        const confirm_password = this.refs.confirm_password.value;

        const isValidName = validation('name', name);
        const isValidPhone = validation('mobile', phone);
        const isValidEmail = validation('email', email);
        const isValidPassword = validation('password', password);
        let isValidCnf = {};
        if (password != confirm_password || confirm_password == '') {
            isValidCnf = { message: 'Password not matched and should not be blank', valid: false };
        } else {
            isValidCnf = { message: 'Password matched', valid: true };
        }
        if (isValidName.valid && isValidPhone.valid && isValidEmail.valid && isValidPassword.valid && isValidCnf.valid) {
            this.setState({
                username: phone,
                password_data: password
            });
            const signUpData = {
                phone: phone, name: name, email: email, country_code: '91', password: password
            };
            this.props.signUp(signUpData);
        } else {
            this.setState({
                name: { isValid: isValidName.valid, message: isValidName.message },
                phone: { isValid: isValidPhone.valid, message: isValidPhone.message },
                email: { isValid: isValidEmail.valid, message: isValidEmail.message },
                password: { isValid: isValidPassword.valid, message: isValidPassword.message },
                confirm_password: { isValid: isValidCnf.valid, message: isValidCnf.message },
            });
        }
    }

    // ===== wizard
    _next = () => {

        let currentStep = this.state.currentStep;
        currentStep = currentStep >= 2 ? 3 : currentStep + 1;
        this.setState({
            currentStep: currentStep,
        });


    }

    _prev = () => {
        let currentStep = this.state.currentStep;
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        this.setState({
            currentStep: currentStep
        });
    }

    showHide(type){
        if(type==='pass') {
            this.setState({
                passwordtype: this.state.passwordtype === 'password' ? 'input' : 'password',
            });
        }
        else if(type==='conpass') {
            this.setState({
                cpasswordtype: this.state.cpasswordtype === 'password' ? 'input' : 'password',
            });
        }

    }
    showScreen = params => {
        this.setState({
            currentStep: params,
        });
    };


    stepOne(step) {
        if (step !== 1) {
            return null;
        }
        const { name,isLoggedIn, phone, email, password, confirm_password, error, passwordtype, cpasswordtype } = this.state;
        if (isLoggedIn) { return < Mainscreen />; }
        return (
            <div>
                <span className={error ? 'text-danger font-weight-bold d-flex justify-content-center' : 'd-none'}>{error ? error : ''}</span>
                <form>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-8')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Name</legend>
                                    <input ref='name' type='text' className={cx('form-control')} />
                                </fieldset>
                                <span className={!name.isValid ? 'text-danger small font-italic' : 'd-none'}>{!name.isValid ? name.message : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-8')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Mobile number</legend>
                                    <input ref='phone' type='text' className={cx('form-control')} maxLength='10' />
                                </fieldset>
                                <span className={!phone.isValid ? 'text-danger small font-italic' : 'd-none'}>{!phone.isValid ? phone.message : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-8')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Email ID</legend>
                                    <input ref='email' type='text' className={cx('form-control')} />
                                </fieldset>
                                <span className={!email.isValid ? 'text-danger small font-italic' : 'd-none'}>{!email.isValid ? email.message : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-8')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Create password</legend>
                                    <input type={passwordtype} ref='password' className={cx('form-control')} />
                                    <span className={cx(globestyle.password_show, (passwordtype === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'))} onClick={() => this.showHide('pass')}></span>
                                </fieldset>
                                <span className={!password.isValid ? 'text-danger small font-italic' : 'd-none'}>{!password.isValid ? password.message : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-8')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Confirm password</legend>
                                    <input type={cpasswordtype} ref='confirm_password' className={cx('form-control')} />
                                    <span className={cx(globestyle.password_show, (cpasswordtype === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'))} onClick={() => this.showHide('conpass')}></span>
                                </fieldset>
                                <span className={!confirm_password.isValid ? 'text-danger small font-italic' : 'd-none'}>{!confirm_password.isValid ? confirm_password.message : ''}</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    stepTwo(step) {
        if (step !== 2) {
            return null;
        }
        const phone = this.props.signUpResult ? this.props.signUpResult.data.user_profile.phone : null;
        const otp_type = this.props.signUpResult ? this.props.signUpResult.data.otp_type : null;
        return (
            <Otp phone={phone} otpType={otp_type} onRef={ref => (this.Otp = ref)}  />
        );
    }
    stepThree(step) {
        if (step !== 3) {
            return null;
        }
        return (
            <CallRequest headermessage='Thanks for your interest. We will reach you soon.' paragraph='You can also schedule time for call' onRef={ref => (this.CallRequest = ref)} />
        );
    }
    stepFour(step) {
        if (step !== 4) {
            return null;
        }
        return (
            <Login />
        );
    }
    userLogin() {
        // alert(this.state.username);
        const loginData = { phone: this.state.username, password: this.state.password_data };
        this.props.onLogin(loginData);
    }
    previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (
                <button
                    className="btn btn-secondary"
                    type="button" onClick={this._prev}>
                    Previous
                </button>
            );
        }
        return null;
    }
    nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep <= 3) {
            return (
                <div className={cx('row justify-content-md-center')}>
                    <div className={cx('col col-lg-8')} >
                        {currentStep == 3 ? (<a className={cx(pagestyle.skip)} onClick={() => this.userLogin()}> Skip </a>) : null}
                        <button
                            className={cx('theme-btn', 'blue')}
                            onClick={this.handleSubmit}
                            type="button">
                            NEXT
                        </button>
                    </div>
                </div>

            );
        }
        return null;
    }

    render() {
        return (
            <div className={cx(styles.join_container)}>
                <React.Fragment>
                    {this.state.currentStep !== 4 ?
                        <div>
                            <div className={cx(styles.join_title_navcontainer)}>

                                <h1>Join Now</h1>
                                <ol>
                                    <li className={(this.state.currentStep == 1 ? styles.bullet_active : '')}></li>
                                    <li className={(this.state.currentStep == 2 ? styles.bullet_active : '')}></li>
                                    <li className={(this.state.currentStep == 3 ? styles.bullet_active : '')}></li>
                                </ol>
                            </div>
                            <div>
                                {this.stepOne(this.state.currentStep)}
                                {this.stepTwo(this.state.currentStep)}
                                {this.stepThree(this.state.currentStep)}

                                <div className={cx(styles.join_button)}>
                                    {this.nextButton()}
                                </div>
                                <div className={cx(styles.join_button_login)}>
                                    <Link to='#' className={cx(pagestyle.login_link)} onClick={() => this.showScreen(4)}>Login</Link>
                                </div>
                            </div>
                        </div> : this.stepFour(this.state.currentStep)}
                </React.Fragment>
            </div>
        );
    }
}



function mapStateToProps(state) {
    return {
        signUpResult: (state.app.signup ? state.app.signup : null),
        otp: (state.app.otp ? state.app.otp : null),
        otpLoginResult: (state.app.otplogin ? state.app.otplogin : null),
        callRqst: (state.app.callrequest ? state.app.callrequest : null),
        loginFailedResult: state.app.loginfailed,
        loginResult: state.app.login,
    };
}

function mapDispatchToProps(dispatch) {

    return {
        signUp: (signUpData) => dispatch(actions.getSignUp(signUpData)),
        sendOtp: (otpData) => dispatch(actions.getOtp(otpData)),
        onLogin: (loginData) => dispatch(actions.getLogin(loginData)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JoinNow);
