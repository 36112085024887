import React from 'react';
import globestyle from '../../sass/global.scss';
import styles from '../HomePage/index.scss';
import pagestyle from './requestcall.scss';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';
import cx from 'classnames';
import Otp from '../Otp';
import CallRequest from '../CallRequest';
import validation from '../../validations';
import * as moment from 'moment';

class RequestaCall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            error: '',
            name: { isValid: false, message: '', value: '' },
            phone: { isValid: false, message: '', value: '' },
        };

    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState) {

        if (this.props.callRqst && prevProps.callRqst !== this.props.callRqst) {
            if (this.props.callRqst.status_code != 200) {
                this.setState({ error: this.props.callRqst.message });
            }
            else {
                this._next();
            }
        }

        if (this.props.otpmatch && prevProps.otpmatch !== this.props.otpmatch && this.props.otpmatch.data.result == 'VERIFIED') {
            this._next();
        }
    }
    handleSubmit = () => {
        if (this.state.currentStep == 1) {
            this.saveStepOne();
        }
        if (this.state.currentStep == 2) {
            this.Otp.saveStepTwo();
        }
        if (this.state.currentStep == 3) {
            this.CallRequest.saveStepThree();
        }
    }

    saveStepOne() {
        const name = this.refs.name.value;
        const phone = this.refs.phone.value;
        const isValidName = validation('name', name);
        const isValidPhone = validation('mobile', phone);
        if (isValidName.valid && isValidPhone.valid) {
            this.props.sendOtpWsignUp({ name: name, phone: phone });
            this.props.sendOtp({ phone: phone, otp_type: 'call-request' });
            this._next();
        } else {
            this.setState({
                name: { isValid: isValidName.valid, message: isValidName.message },
                phone: { isValid: isValidPhone.valid, message: isValidPhone.message },
            });
        }
    }



    // ===== wizard
    _next = () => {
        let currentStep = this.state.currentStep;
        currentStep = currentStep >= 3 ? 4 : currentStep + 1;
        this.setState({
            currentStep: currentStep,
            stepIsSuccess: false,
        });
    }



    _prev = () => {
        let currentStep = this.state.currentStep;
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        this.setState({
            currentStep: currentStep
        });
    }

    stepOne(step) {
        if (step !== 1) {
            return null;
        }
        const { name, phone, error, } = this.state;
        return (
            <div>
                <span className={error ? 'text-danger font-weight-bold d-flex justify-content-center' : 'd-none'}>{error ? error : ''}</span>
                <form>
                    <div className={cx(pagestyle.title_container1)}>
                        <h3>our counsellor will assist you for all your queries</h3>
                    </div>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-8')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Name</legend>
                                    <input ref='name' type='text' className={cx('form-control')} />
                                </fieldset>
                                <span className={!name.isValid ? 'text-danger small font-italic' : 'd-none'}>{!name.isValid ? name.message : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-8')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Mobile number</legend>
                                    <input ref='phone' type='text' className={cx('form-control')} maxLength='10' />
                                </fieldset>
                                <span className={!phone.isValid ? 'text-danger small font-italic' : 'd-none'}>{!phone.isValid ? phone.message : ''}</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    stepTwo(step) {
        if (step !== 2) {
            return null;
        }
        const phone =this.props.callRequestWsignUp? this.props.callRequestWsignUp.phone:null;
        const otp_type =  'call-request';
        return (
            <Otp phone={phone} otpType={otp_type} onRef={ref => (this.Otp = ref)} />
        );
    }
    stepThree(step) {
        if (step !== 3) {
            return null;
        }
        return (
            <CallRequest headermessage='What time will be good to contact you?'  onRef={ref => (this.CallRequest = ref)} />
        );
    }
    stepFour(step) {
        if (step !== 4) {
            return null;
        }
        return (
            <div className={(cx(pagestyle.thnak_you_block))}>
                <h1>Thank for your interest. We will reach you soon</h1>
                <p>We will call you between {this.CallRequest ? moment(this.CallRequest.state.fromTime.value).format('LT'):''} to {this.CallRequest ? moment(this.CallRequest.state.toTime.value).format('LT'):''} <span>Please be nearby your phone</span></p>
            </div>
        );
    }
    previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (
                <button type="button" onClick={this._prev} className={cx(pagestyle.backbtn)}>
                    <span className={cx(pagestyle.arrow_left, 'fa fa-arrow-left')}></span> Back
                </button>
            );
        }
        return null;
    }
    nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep <= 3) {
            return (
                <div className={cx('row justify-content-md-center')}>
                    <div className={cx('col col-lg-8')} >
                        <button
                            className={cx('theme-btn', 'blue')}
                            onClick={this.handleSubmit}
                            type="button">
                            NEXT
                        </button>
                    </div>
                </div>

            );
        }
        return null;
    }

    render() {
        return (
            <div className={cx(styles.join_container)}>
                {this.state.currentStep != 4 ? (
                    <React.Fragment>
                        <div className={cx(pagestyle.modal_header, 'modal-header')}>
                            {this.previousButton()}
                        </div>
                        <div className={cx(styles.join_title_navcontainer)}>
                            <h1>Request a call</h1>
                            <ol>
                                <li className={(this.state.currentStep == 1 ? styles.bullet_active : '')}></li>
                                <li className={(this.state.currentStep == 2 ? styles.bullet_active : '')}></li>
                                <li className={(this.state.currentStep == 3 ? styles.bullet_active : '')}></li>
                            </ol>
                        </div>
                        {this.stepOne(this.state.currentStep)}
                        {this.stepTwo(this.state.currentStep)}
                        {this.stepThree(this.state.currentStep)}
                        <div className={cx(styles.join_button, pagestyle.bottom_space)}>
                            {this.nextButton()}
                        </div>
                    </React.Fragment>
                ) : (<div>{this.stepFour(this.state.currentStep)}</div>)}
            </div>
        );
    }
}




function mapStateToProps(state) {

    return {
        callRequestWsignUp: state.app.callrqstdata,
        otp: (state.app.otp ? state.app.otp : null),
        otpmatch: (state.app.otplogin ? state.app.otplogin : null),
        callRqst: (state.app.callrequest ? state.app.callrequest : null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sendOtpWsignUp: (otpData) => dispatch(actions.sendOtpWsignUp(otpData)), //for resend  purpose
        sendOtp: (otpData) => dispatch(actions.getOtp(otpData)),
        // verifyOtp: (otpCode) => dispatch(actions.getVerifyOtp(otpCode)), //verify entred otp
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestaCall);


