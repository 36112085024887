import React from 'react';
import cx from 'classnames';
import globestyle from '../../sass/global.scss';
import styles from './newtest.scss';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';
import _ from 'lodash';

class Addnewtest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subjectList: [],
            MakeItem:'',
            datalist: null,
            parentlist: null,
            title: { isValid: false, message: '' },
            parent: { isValid: false, message: '' },
            subject: { isValid: false, message: '' },
            error: '',
            displayMenu: false,
            allsubjects: false,
            subjectSelected: [],
            displayIndex:''
        };
        this.submitcreate = this.submitcreate.bind(this);
        this.handleTestType = this.handleTestType.bind(this);
        this.handleAllChecked = this.handleAllChecked.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    }

    componentWillMount() {
        if(this.props.dataset) {
            this.setState({
                datalist: this.props.dataset,
            });
            this.setState({
                subjectList: this.props.dataset.subjects,
            });
        }
    }

    componentDidUpdate(nextProps) {
        if(this.props.parentList!==nextProps.parentList && this.props.parentList) {
            this.setState({
                parentlist: this.props.parentList.tests,
            });
        }
        if(this.props.addNewTestFailed!==nextProps.addNewTestFailed && this.props.addNewTestFailed? this.props.addNewTestFailed.status_code:null) {
            this.setState({ error: this.props.addNewTestFailed.message});
        }
    }

    submitcreate() {
        const title = this.refs.title.value;
        const sequence_no = this.state.displaySequence;
        const time_limit = this.state.displayDuration;
        const linking = this.state.testtype;
        // const parent_id = this.refs.parent.value;
        const parent_id = '';
        const exam_id = this.state.datalist.exam_id;
        const test_series_id = this.state.datalist.test_series_id;
        const allsubject = this.state.allsubjects;
        const subjectlist = this.state.subjectSelected;

        let isValidTitle = null;
        let isValidParent = null;
        let isValidSubject = null;

        if(!title) {
            isValidTitle = { isValid: false, message: 'Title cannot be blank.' };
        } else {
            isValidTitle = { isValid: true, message: '' };
        }
        if(!parent_id && linking==='child') {
            isValidParent = { isValid: false, message: 'Parent cannot be blank.' };
        } else {
            isValidParent = { isValid: true, message: '' };
        }
        if(allsubject==false && !subjectlist.length) {
            isValidSubject = { isValid: false, message: 'Select the subject.' };
        } else {
            isValidSubject = { isValid: true, message: '' };
        }

        this.setState({
            title: isValidTitle,
            parent: isValidParent,
            subject: isValidSubject,
        });

        if(isValidTitle.isValid && isValidParent.isValid && isValidSubject.isValid) {
            let submitData = {
                title: title,
                linking: linking,
                linked_to: (parent_id ? parent_id: '' ),
                sequence_no: sequence_no,
                time_limit: time_limit,
                all_subjects: allsubject,
                exam_id: exam_id,
                test_series_id: test_series_id,
            };

            if(subjectlist && subjectlist.length && allsubject==false) {
                let subjectIds = [];
                this.state.subjectSelected.map((item) => {
                    if(item.checked==true) {
                        subjectIds.push(parseInt(item.id));
                    }
                });
                submitData.subject_ids = subjectIds;
            }

            this.props.onCreateTestData(submitData);
        }
    }

    handleTestType(type) {
        this.setState({
            testtype:type
        });
        if(type==='child') {
            const params = {exam_id: this.state.datalist.exam_id, test_series_id: this.state.datalist.test_series_id};
            this.props.onParentData(params);
        }
    }

    handleAllChecked(event) {
        const allsubjects = event.target.checked;
        let checkboxes = document.getElementsByName('subjects[]');
        let subjectlist = [];
        if(allsubjects) {
            for(let i in checkboxes) {
                if(checkboxes[i].checked==false) {
                    checkboxes[i].checked=true;
                    if(checkboxes[i].value) {
                        subjectlist.push({id: checkboxes[i].value, checked: true});
                    }
                }
            }
        } else {
            for(let j in checkboxes) {
                if(checkboxes[j].checked==true) {
                    checkboxes[j].checked=false;
                    if(checkboxes[j].value) {
                        subjectlist.push({id: checkboxes[j].value, checked: false});
                    }
                }
            }
        }
        this.setState({
            allsubjects: allsubjects,
            subjectSelected: subjectlist,
        });
    }

    handleChecked() {
        let checkboxes = document.getElementsByName('subjects[]');
        let subjectlist = [];
        for(var j in checkboxes) {
            if(checkboxes[j].value) {
                subjectlist.push({id: checkboxes[j].value, checked: checkboxes[j].checked});
            }
        }
        this.setState({
            subjectSelected: subjectlist,
        });
    }

    showDropdownMenu(index) {
        this.setState({ displayMenu: true,displayIndex:index }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });

    }
    handleChange (seq) {
        this.setState({displaySequence:seq});
    }
    handleDuration(dot){
        this.setState({displayDuration:dot});

    }

    render() {
        const { title, parent,displayIndex,testtype, error, subject, subjectList, allsubjects, parentlist,displaySequence,displayDuration } = this.state;
        const Data = [1, 2, 3, 4];
        const Data2 = ['Single', 'Parent', 'Child'];
        const Data3 = [3, 2, 1 ];
        return (
            <div className={cx(styles.newtestblock)}>
                <form>
                    <div className={cx(styles.header_alignment, 'text-center')}>
                        <h4 className={cx(styles.header_height)}><b>Add new test</b></h4>
                    </div>
                    <span className={error ? 'text-danger font-weight-bold d-flex justify-content-center': 'd-none'}>{error ? error: ''}</span>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-12')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont, globestyle.small)}>Title</legend>
                                    <input type='text' ref='title' className={cx('form-control', globestyle.small)} />
                                </fieldset>
                                <span className={cx(styles.spantext, 'row')}>Ex.NDA & NA | PRACTISE TEST 2 | 2019</span>
                                <span className={!title.isValid ? 'text-danger small font-italic': 'd-none'}>{!title.isValid ? title.message: ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-12')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont, globestyle.small)}>Sequence no</legend>
                                    <div className={cx(styles.selectdiv)}>
                                        <div className='dropvalue' onClick={() =>this.showDropdownMenu(1)}>{displaySequence?displaySequence: 'Select sequence'}</div>
                                        {this.state.displayMenu && displayIndex ==1 ?
                                            <ul>
                                                {Data.map((item, i) => {
                                                    return (
                                                        <li onClick={() => this.handleChange(item)} key={i} id={i}>{item}
                                                        </li>
                                                    );
                                                }

                                                )}
                                            </ul> : null
                                        }
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col ')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont, globestyle.small)}>Duration of Test</legend>
                                    <div className={cx(styles.selectdiv)}>
                                        <div className='dropvalue' onClick={() => this.showDropdownMenu(2)}>{displayDuration?displayDuration: 'Select duration'}</div>
                                        {this.state.displayMenu && displayIndex ==2 ?
                                            <ul >
                                                {Data3.map((item, i) => {
                                                    return (
                                                        <li onClick={() => this.handleDuration(item)} key={i} id={i}>{item}
                                                        </li>
                                                    );
                                                }

                                                )}
                                            </ul> : null
                                        }
                                    </div>

                                </fieldset>
                            </div>
                        </div>

                        <div className={cx('col ')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend  className={cx(globestyle.legend, globestyle.bluefont, globestyle.small)}>Test Type</legend>
                                    <div className={cx(styles.selectdiv)}>
                                        <div className='dropvalue' onClick={() => this.showDropdownMenu(3)}>{testtype?testtype:'Select Testtype'}</div>
                                        {this.state.displayMenu && displayIndex ==3 ?
                                            <ul >
                                                {Data2.map((item, i) => {
                                                    return (
                                                        <li onClick={()=>this.handleTestType(item.toLowerCase())} key={i} id={i}>{item}
                                                        </li>
                                                    );
                                                }

                                                )}
                                            </ul> : null
                                        }
                                    </div>

                                </fieldset>
                            </div>
                        </div>
                    </div>
                    {parentlist && parentlist.length>0 ?
                        <div className={cx('row justify-content-md-center')}>
                            <div className={cx('col col-lg-12')} >
                                <div className={cx('form-group')}>
                                    <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                        <legend className={cx(globestyle.legend, globestyle.bluefont, globestyle.small)}>Parent</legend>
                                        {/* <select ref='parent' className={cx('col-xl-12', styles.selectdiv, styles.small)}>{Data2.map(MakeItem2)}</select>
                                        {
                                            _.map(parentlist, (plist, i) => {
                                                return (
                                                    <option value="" key={i}>{plist.title}</option>
                                                );
                                            })
                                        } */}
                                    </fieldset>
                                    <span className={!parent.isValid ? 'text-danger small font-italic': 'd-none'}>{!parent.isValid ? parent.message: ''}</span>
                                </div>
                            </div>
                        </div>: null}
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-12')}>
                            <div className={cx(styles.sub_title_container)}>
                                <h3>Subject Covered in Test Series</h3>
                            </div>
                            <span className={!subject.isValid ? 'text-danger small font-italic': 'd-none'}>{!subject.isValid ? subject.message: ''}</span>
                            <div>

                                <div className={cx(styles.checkboxlabel)}>
                                    <input onClick={this.handleAllChecked} type="checkbox" value="0" /><p className={cx('pl-1')}>All Subjects</p>
                                </div>
                                <div className={cx('clearfix')}></div>
                                <div className={cx('row')}>
                                    {
                                        _.map(subjectList, (main_item) => {
                                            return (
                                                <div className={cx('col-md-4', styles.checkboxlabel)} key={main_item.subject_id}>
                                                    <input onClick={this.handleChecked} type="checkbox" value={main_item.subject_id} name="subjects[]" disabled={allsubjects ? true : false} />
                                                    <p className={cx('pl-1')}> {main_item.title}</p>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                </form>
                <div className={cx('row justify-content-md-center')}>
                    <div className={cx(styles.adjustinputbox, 'col-md-3 col-sm-6 col-xs-12')}>
                        <div className={cx('form-group')}>
                            <button className={cx('theme-btn', 'blue')} type='submit' onClick={this.submitcreate}>
                                CREATE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {
        activeData: (state.app.activedata ? state.app.activedata : null),
        parentList: (state.app.listofparenttest ? state.app.listofparenttest : null),
        addNewTestFailed: (state.app.addnewtestfailed ? state.app.addnewtestfailed : null),
        // subjects: (state.app.subjectlists ? state.app.subjectlists :null),
    };
}

function mapDispatchToProps (dispatch) {
    return {
        onCreateTestData: (submitData) => dispatch(actions.addNewTest(submitData)),
        onParentData: (data) => dispatch(actions.getListofparenttest(data)),
        // subjectList: (examid) => dispatch(actions.getSubjectList(examid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Addnewtest);
