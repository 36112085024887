import React from 'react';
import { Modal, ModalBody} from 'reactstrap';
import styles from './style.scss';
import cx from 'classnames';
import JoinNow from '../JoinNow';
import Login from '../Login';
import RequestaCall from '../RequestaCall';
import NewTestSeries from '../NewTestSeries';
import Addnewtest from '../Addnewtest';

class PopUpModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        return (
            <div className={cx(styles.modal_container)}>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={cx(styles.modal_container,this.props.className)} backdrop={'static'} centered={true}>
                    <ModalBody>
                        <div>
                            <a className={cx('float_right',styles.close_button)} onClick={this.toggle}>
                                <i className={cx('far fa-times-circle')}></i>
                            </a>
                        </div>
                        {this.props.modelcontent=='register'? <JoinNow /> :null}

                        {this.props.modelcontent=='requestcall' ? <RequestaCall /> : null}
                        {this.props.modelcontent=='newtestseries' ? <NewTestSeries /> : null}
                        {this.props.modelcontent=='addnewtest'? <Addnewtest dataset={this.props.dataset} />:null}
                        {this.props.modelcontent=='login'? <Login isLoggedIn={this.props.isLoggedIn} /> :null}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


export default PopUpModal;
