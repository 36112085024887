import React from 'react';
import { Link } from 'react-router-dom';
import styles from './testseries.scss';
import globestyle from '../../sass/global.scss';
import cx from 'classnames';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';
import Moment from 'react-moment';
import _ from 'lodash';
import PopUpModal from './../PopUpModal';
import Image from '../../images/FolderIcon.svg'; // Import using relative path


const img_styles = {
    paperContainer: {
        backgroundImage: `url(${Image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }
};

let test_series_id = 0;
let exam_id = 0;

class UploadTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listoftests:[],
            activePopup: '',
            datalist: null,
            showexamfolder : false,
            showrow : true,
        };

        this.showPopup = this.showPopup.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentWillMount() {
        if (this.props.displayTestlist && this.props.displayTestlist.tests.length) {
            this.setState({
                listoftests: this.props.displayTestlist,
            });
        }
        if(this.props.activeData && this.props.activeData.activeExamId) {
            if(!test_series_id) {
                test_series_id = this.props.location.pathname.replace(this.props.match.url+'/','');
            }
            exam_id = this.props.activeData.activeExamId;
            const listoftestData = { examid: exam_id, testseriesid: test_series_id };
            this.setState({
                datalist: {
                    exam_id: exam_id,
                    test_series_id: test_series_id,
                }
            });
            this.props.onFetchtestlist(listoftestData);
        }

    }

    componentDidUpdate(nextProps){
        if (this.props.displayTestlist!=nextProps.displayTestlist && this.props.displayTestlist && this.props.displayTestlist.test_series) {
            this.setState({
                listoftests: this.props.displayTestlist,
            });
        }
        if(this.props.activeData!=nextProps.activeData && this.props.activeData && this.props.activeData.activeExamId && exam_id==0) {
            if(!test_series_id) {
                test_series_id = this.props.location.pathname.replace(this.props.match.url+'/','');
            }
            exam_id = this.props.activeData.activeExamId;
            const listoftestData = { examid: exam_id, testseriesid: test_series_id };
            this.setState({
                datalist: {
                    exam_id: exam_id,
                    test_series_id: test_series_id,
                }
            });
            this.props.onFetchtestlist(listoftestData);
        }
        if(this.props.newTestData && this.props.newTestData!=nextProps.newTestData) {
            this.PopUpModal.toggle();
            const listoftestData = { examid: exam_id, testseriesid: test_series_id };
            this.setState({
                datalist: {
                    exam_id: exam_id,
                    test_series_id: test_series_id,
                }
            });
            this.props.onFetchtestlist(listoftestData);
        }
    }

    showPopup = (event) => {
        event.preventDefault();
        this.setState({
            activePopup: 'addnewtest',
            datalist: {
                exam_id: exam_id,
                test_series_id: test_series_id,
                subjects: this.props.displayTestlist.test_series.subjects,
            }
        });
        this.PopUpModal.toggle();
    };
    componentWillUnmount() {
        test_series_id = 0;
    }
    handleBack() {
        this.props.history.push({pathname: '/test-series', tabIndex:this.props.location.state ? this.props.location.state.tabIndex : 0});
    }
    examfolder(i){
        this.setState({
            showexamfolder : !this.state.showexamfolder,
            showrow : i
        });
    }
    render() {
        const { listoftests, activePopup, datalist, showexamfolder, showrow } = this.state;
        return (
            <div>
                <nav aria-label="breadcrumb">
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to="/test-series">Home</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to="/">Test Series</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem, globestyle.active)} aria-current="page"><span>Tests</span></li>
                    </ol>
                </nav>
                <div className={cx('clearfix')}></div>
                <div className={cx(styles.maincontent)}>
                    <div>
                        <div className={cx('row')}>
                            <div className={cx('col-md-12 col-sm-12')}>
                                <ul className={cx(styles.header_menu)}>
                                    <Link to='#'><li onClick={this.handleBack}><i className={cx('fa fa-arrow-left')} aria-hidden="true"></i></li></Link>
                                    <li><h2>{listoftests.test_series && listoftests.test_series.title ? listoftests.test_series.title: null}</h2></li>
                                    <li><i className={cx(styles.pencil_icon, 'fa fa-pencil-alt')} aria-hidden="true"></i><p className={cx(styles.text_style)}>{listoftests.test_series && listoftests.test_series.progress_status ? '(' + listoftests.test_series.progress_status + ')': null}</p></li>
                                </ul>
                            </div>
                        </div>
                        <div className={cx('row', styles.actionblock)}>
                            <div className={cx('col-md-6 col-sm-6')}>
                                <div className={cx(styles.upload_box)}>
                                    <Link to="#" onClick={this.showPopup}><i className={cx('fa fa-plus')}></i>Add New Test
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className={cx('row')}>
                            { listoftests.tests && listoftests.tests.length>0 ?
                                <div className={cx('col-md-12 col-sm-12', styles.listblock)}>
                                    <ul>
                                        {
                                            _.map(listoftests.tests, (testlist, i) => {
                                                return (
                                                    <div key={i} className={cx(styles.examlist)}>
                                                        <div className={cx(styles.exam_description_div)}>
                                                            <h3 className={cx(styles.test_heading)}>
                                                                {testlist.sequence_no} TEST <i className={cx('fa fa-angle-right')} aria-hidden="true"></i>
                                                            </h3>
                                                            <div className={cx(styles.exam_description)}>
                                                                <div className={cx('col-md-8 col-sm-8')}>
                                                                    <h4>
                                                                        {testlist.title}
                                                                    </h4>
                                                                    <p className={cx(styles.created_date)}>
                                                                        CREATED ON <Moment format="DD-MM-YYYY">{testlist.created_on}</Moment>
                                                                    </p>
                                                                </div>
                                                                <div className={cx('col-md-3')}>
                                                                    <p className={cx(styles.exam_status)}>
                                                                        {testlist.status}
                                                                    </p>
                                                                </div>
                                                                <div className={cx('col-md-1')}>
                                                                    <i className={cx(styles.details_icon, 'fa fa-ellipsis-v')}></i>
                                                                </div>
                                                            </div>
                                                            {testlist.subjects && testlist.subjects.length>0 && showexamfolder && showrow == i ?
                                                                <div className={cx(styles.exam_folders)}>
                                                                    {
                                                                        _.map(testlist.subjects, (subjects, i) => {
                                                                            const url = '/test-series/upload-files/'+datalist.test_series_id+'/'+testlist.id+'/'+subjects.subject_id;
                                                                            return (
                                                                                <div className={cx('col-md-3')} key={i}>
                                                                                    <Link to={url}>
                                                                                        <div className={cx(styles.subjects_details)} style={img_styles.paperContainer}>
                                                                                            {subjects.title}
                                                                                            <span className={cx(styles.uploads_details)}>{subjects.uploaded_file_count} UPLOADED FILES</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>

                                                                            );
                                                                        }
                                                                        )}
                                                                </div> : null }
                                                            {testlist.subjects && testlist.subjects.length >0  ?
                                                                <div className={cx(styles.chevron)} onClick = {() => this.examfolder(i)}>
                                                                    <Link to='#'><i className={cx(styles.up_icon, showexamfolder && showrow == i ? 'fa fa-chevron-up' :'fa fa-chevron-down')} aria-hidden="true"></i></Link>
                                                                </div> : null}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>: null}
                        </div>
                    </div>
                </div>
                <div>
                    <PopUpModal modelcontent={activePopup} dataset={datalist} onRef={ref => (this.PopUpModal = ref)} />
                </div>
            </div>
        );
    }
}
function mapStateToProps (state) {
    return {
        displayTestlist: (state.app.listoftests ? state.app.listoftests: null),
        activeData: (state.app.activedata ? state.app.activedata : null),
        newTestData: (state.app.addnewtest ? state.app.addnewtest : null),
    };
}

function mapDispatchToProps (dispatch) {
    return {
        onFetchtestlist: (listoftestData) => dispatch(actions.getListoftest(listoftestData)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadTest);
