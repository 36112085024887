import React from 'react';
import { Link } from 'react-router-dom';
import styles from './setting.scss';
import globestyle from '../../sass/global.scss';
import cx from 'classnames';
import validation from '../../validations';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            type: 'newpassword',
            password: { isValid: false, message: '' },
            newpassword: { isValid: false, message: '' },
            confirmpassword: { isValid: false, message: '' },
            passwordtype: 'password',
            cpasswordtype: 'password',
            passwordResult:'',
        };
        this.submitPassword = this.submitPassword.bind(this);
    }

    componentDidUpdate(nextProps) {
        if (this.props.passwordResult !== nextProps.passwordResult && this.props.passwordResult) {
            if (this.props.passwordResult.status_code == 200) {
                this.setState({ passwordResult: this.props.passwordResult, passwordFailedresult:''});
            }
        }
        if (this.props.passwordFailedresult !== nextProps.passwordFailedresult && this.props.passwordFailedresult) {
            this.setState({ passwordFailedresult: this.props.passwordFailedresult});

        }
    }

    submitPassword() {
        const password = this.refs.oldpassword.value;
        const newpassword = this.refs.newpassword.value;
        const confirmpassword = this.refs.confirmpassword.value;
        const isValidPassword = validation('password', password);
        const isValidNewPassword = validation('password', newpassword);
        const isValidConfirmPassword = validation('password', confirmpassword);

        let isValidCnf = {};
        if (newpassword != confirmpassword || confirmpassword == '') {
            isValidCnf = { message: 'Password not matched and should not be blank.', valid: false };
        } else {
            isValidCnf = { message: 'Password matched', valid: true };
        }

        if(isValidPassword.valid && isValidNewPassword.valid && isValidConfirmPassword.valid && isValidCnf.valid  ) {
            this.setState({
                password: { isValid: true, message: '' },
                newpassword: { isValid: true, message: '' },
                confirmpassword: { isValid: true, message: '' },
            });
            const paswordData = { password: password, newpassword: newpassword,confirmpassword:confirmpassword };
            this.props.onsubmitData(paswordData);

        } else {
            this.setState({
                password: { isValid: isValidPassword.valid, message: isValidPassword.message },
                newpassword: { isValid: isValidNewPassword.valid, message: isValidConfirmPassword.message },
                confirmpassword: { isValid: isValidCnf.valid, message: isValidCnf.message },
            });
        }

    }

    showHide(type){
        if(type==='pass') {
            this.setState({
                passwordtype: this.state.passwordtype === 'password' ? 'input' : 'password',
            });
        }
        else if(type==='conpass') {
            this.setState({
                cpasswordtype: this.state.cpasswordtype === 'password' ? 'input' : 'password',
            });
        }

    }
    render() {
        const{password,newpassword,confirmpassword,passwordtype,passwordFailedresult, cpasswordtype,passwordResult} = this.state;
        return (
            <div className={cx(styles.main_block)}>
                <nav aria-label="breadcrumb">
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to="/test-series">Home</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem, globestyle.active)} aria-current="page"><span>Settings</span></li>
                    </ol>
                </nav>
                {passwordResult?
                    <div className={cx('alert alert-success')}>
                        <span className={cx(styles.setheight)}>Your account login password has been changed.Please login again.</span>
                    </div> : null
                }
                {passwordFailedresult?
                    <div className={cx('alert alert-danger')}>
                        <span className={cx(styles.setheight)}>{passwordFailedresult?passwordFailedresult:''}</span>
                    </div> : null

                }
                <div className={cx('clearfix')}></div>
                <div className={cx(styles.maincontent)}>
                    <div className={cx('row')}>
                        <div className={cx('col')}>
                            <h1 className={cx(styles.page_title)}>Change password</h1>
                        </div>
                    </div>
                    <form>
                        <div className={cx('row')}>
                            <div className={cx('col-md-3 col-sm-5 col-xs-12')}>
                                <div className={cx('form-group')}>
                                    <fieldset className={cx(globestyle.fset)}>
                                        <legend className={cx(globestyle.legend,globestyle.small)}>Old password</legend>
                                        <input type={cpasswordtype} ref='oldpassword' className={cx(globestyle.small,'form-control')} />
                                        <span className={cx(globestyle.password_show, (cpasswordtype === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'))} onClick={() => this.showHide('conpass')}></span>
                                    </fieldset>
                                    <span className={!password.isValid ? 'text-danger small font-italic': 'd-none'}>{!password.isValid ? password.message: ''}</span>
                                </div>
                            </div>
                        </div>


                        <div className={cx('row')}>
                            <div className={cx('col-md-3 col-sm-5 col-xs-12')}>
                                <div className={cx('form-group')}>
                                    <fieldset className={cx(globestyle.fset)}>
                                        <legend className={cx(globestyle.legend,globestyle.small)}>New password</legend>
                                        <input type={passwordtype} ref='newpassword' className={cx(globestyle.small,'form-control')} />
                                        <span className={cx(globestyle.password_show, (passwordtype === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'))} onClick={() => this.showHide('pass')}></span>
                                    </fieldset>
                                    <span className={!newpassword.isValid ? 'text-danger small font-italic': 'd-none'}>{!newpassword.isValid ? newpassword.message: ''}</span>
                                </div>
                            </div>
                        </div>

                        <div className={cx('row')}>
                            <div className={cx('col-md-3 col-sm-5 col-xs-12')}>
                                <div className={cx('form-group')}>
                                    <fieldset className={cx(globestyle.fset)}>
                                        <legend className={cx(globestyle.legend,globestyle.small)}>Confirm new password</legend>
                                        <input  type='text' ref='confirmpassword' className={cx(globestyle.small,'form-control')} />
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <span className={!confirmpassword.isValid ? 'text-danger small font-italic': 'd-none'}>{!confirmpassword.isValid ? confirmpassword.message: ''}</span>
                    </form>
                    <div className={cx('row')}>
                        <div className={cx('col-md-4 col-sm-5 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <button className={cx(styles.setheight,'theme-btn', 'blue')} type="submit" onClick={this.submitPassword}>CHANGE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {
        passwordResult: state.app.changepassword,
        passwordFailedresult:state.app.changepasswordfailed
    };
}

function mapDispatchToProps (dispatch) {
    return {
        onsubmitData: (paswordData) => dispatch(actions.getChangepassword(paswordData)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings);
