import React from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';
import cx from 'classnames';
import style from './testseries.scss';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import MathJax from 'react-mathjax';
// eslint-disable-next-line no-unused-vars
let params = {};
let exam_id = 0;
let test_series_id = 0;
let test_id = 0;
let subject_id = 0;

// let active_issue = 0;
// let active_question = 0;

class ViewDevelopedQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            developedcontent: [],
            comment: { isValid: false, message: '', qid: 0 },
            questionComment: { question_id: 0, comments: '' },
            issue: false,

        };
        this.handleBack = this.handleBack.bind(this);
    }
    componentDidMount() {
        let data = this.props.location.pathname.replace(this.props.match.url + '/', '');
        data = data.split('/');
        exam_id = data[0];
        test_series_id = data[1];
        test_id = data[2];
        subject_id = data[3];
        params = { exam_id: exam_id, test_series_id: test_series_id, test_id: test_id, subject_id: subject_id };
        this.props.viewDevelopedContent(params);
    }
    componentDidUpdate(nextProps) {
        if (this.props.developedcontent !== nextProps.developedcontent && this.props.developedcontent) {
            this.setState({ developedcontent: this.props.developedcontent.data });
        }
        if (this.props.comment !== nextProps.comment && this.props.comment) {
            let question_comment = this.state.questionComment;
            question_comment.comments = this.props.comment.data.issue.comments;
            this.setState({ questionComment: question_comment });
        }
        if (this.props.created_issue !== nextProps.created_issue && this.props.created_issue) {
            let question_comment = this.state.questionComment;
            question_comment.comments = this.props.created_issue.data.issue ? this.props.created_issue.data.issue.comments : '';
            this.setState({ questionComment: question_comment });
        }

    }


    // eslint-disable-next-line no-unused-vars
    handelCommentClickCreateIssue(checked, qid, issue) {
        let comment = document.getElementById('textarea_' + qid).value;
        checked = checked || this.state.issue ? true : false;
        let activeissue = '';
        if (issue) {
            activeissue = issue.filter(function (data) {
                return !data.resolved;
            });
        }

        let question_comment = this.state.questionComment;
        question_comment.question_id = qid;
        // eslint-disable-next-line no-unused-vars
        let isValidComment = null;
        if (comment.length === 0) {
            isValidComment = { isValid: false, message: 'Comment field is required', qid: qid };
        } else {
            isValidComment = { isValid: true, message: '', qid: qid };
        }
        this.setState({
            comment: isValidComment,
            questionComment: question_comment
        });
        if (isValidComment.isValid && checked === true && issue == null) {
            this.props.createIssue({ issue: comment, question_id: qid, test_series_id: params.test_series_id, test_id: params.test_id });
        }
        if (isValidComment.isValid && checked === true && issue.length > 0) {
            this.props.addComment({ comment: comment, question_id: qid, issue_id: activeissue[0].id, test_series_id: params.test_series_id, test_id: params.test_id });
        }
        document.getElementById('textarea_' + qid).value = '';
    }
    handelRadioClickResolveIssue(issue) {
        if (issue) {
            let activeissue = issue.filter(function (data) {
                return !data.resolved;
            });
            this.props.issueResolved({ issue_id: activeissue[0].id, test_series_id: params.test_series_id, test_id: params.test_id });
        }

    }
    newPostedComment() {
        let comments = this.state.questionComment.comments;
        return (
            <div>
                <p className={cx('text-left')}></p>
                {
                    _.map(comments, (iss, i) => {
                        return (
                            <div key={i} >
                                <p>{iss.comment}</p>
                            </div>
                        );
                    })
                }
            </div>
        );


    }
    actualComment(issue) {
        if (issue) {
            let activeissue = issue.filter(function (data) {
                return !data.resolved;
            });
            const final_comments = activeissue.length > 0 ? activeissue[0] : [];
            return (
                <div>
                    <p className={cx('text-left')}>{final_comments.issue}</p>
                    {
                        _.map(final_comments.comments, (iss, i) => {
                            return (
                                <div key={i} >
                                    <p>{iss.comment}</p>
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
    }

    setradioval() {
        this.setState({
            issue: true
        });
    }
    handleBack() {
        this.props.history.push('/upload-files');
    }
    // developedcontent

    render() {
        const { developedcontent, comment, questionComment } = this.state;
        const createMarkup = (data) => {
            return { __html: data };
        };
        return (
            <MathJax.Provider>
                <div className={cx(style.developed_content_container)}>
                    <div className={cx('row', style.show_grid, style.show_grid_header_color)}>
                        <div className={cx('col-md-12')}>
                            <div className={cx(style.developed_content_header)}>
                                <div className={cx('row')}>
                                    <div className={cx('col-md-11 col-sm-11')}>
                                        <h2 className={cx('text-center')}>DEVELOPED CONTENT</h2>
                                    </div>
                                    <div onClick={this.handleBack} className={cx('col-md-1 col-sm-1')}>
                                        <i
                                            className={cx(
                                                style.closeicon,
                                                'far fa-times-circle ml-3'
                                            )}
                                        />

                                    </div>
                                </div>

                                <div>
                                    <Link to='/view-developed-question/'>
                                        <span className={cx('fa fa-eye')}></span>
                                        View source file
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {developedcontent.length > 0 ?
                        <div className={cx('row', style.show_grid)}>
                            <div className={cx('col-md-12', style.no_data_available)}>
                                <h1>No content available</h1>
                            </div>
                        </div> :
                        <div>
                            {
                                _.map(developedcontent.questions, (data, index) => {
                                    let checked = data.issue && data.issue.length > 0 ? true : false;
                                    let count = 0;
                                    return (
                                        <div key={index} className={cx('row', style.show_grid, style.question_bottom)}>
                                            <div className={cx('col-md-9', style.question_container_color)}>

                                                <div className={cx('all_questions')}>
                                                    <div className={cx('row')}>
                                                        <div className={cx('col-md-5')}>
                                                            <strong>Question {data.id} :</strong>
                                                        </div>
                                                        <div className={cx('col-md-7')}>
                                                            <div className={cx(style.issue_resolve_container)}>
                                                                <strong>Mark issue with this question</strong>
                                                                <label className={cx('radio-inline')}>
                                                                    <input className={cx('mr-1')} type="radio" name={`optradio_${index}`} defaultChecked={checked} value='issue' onClick={() => this.setradioval()} />Issue
                                                                </label>
                                                                <label className={cx('radio-inline')}>
                                                                    <input className={cx('mr-1')} type="radio" name={`optradio_${index}`} value='resolved' onClick={() =>
                                                                        window.confirm('Are you sure you wish to Resolved this question?') &&
                                                                        this.handelRadioClickResolveIssue(data.issue)
                                                                    } />Resolved
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={cx('row')}>
                                                        <div className={cx('col-md-12')}>
                                                            <div className={cx(style.question)}>
                                                                <div dangerouslySetInnerHTML={createMarkup(data.question)} />
                                                                <div className={cx(style.mathjaxstyle)}>
                                                                    <MathJax.Node formula={data.question} />
                                                                </div>
                                                                <div className={cx('text-center mt-2 pt-1')}>
                                                                    <img className={cx(style.optionimage)} src={data.image ? data.image : ''}></img>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {data.question_style == 'subjective' ? null


                                                        : data.question_style == 'matrix' ?
                                                            <div className={cx('row')}>
                                                                <div className={cx('col')}>
                                                                    <p className={cx('pt-1 pb-2 text-justify')}><strong>Matrix List 1</strong></p>

                                                                    {_.map(data.matrix_list_1, (matval1, i) => {

                                                                        return (
                                                                            <div key={i} className={cx('pb-3')}>
                                                                                <p className={cx('pt-1 text-justify', style.optionstyle)}>({matval1.option_title}) <strong>{matval1.choice}</strong></p>
                                                                                {matval1.image ?
                                                                                    <img className={cx(style.optionimage)} src={matval1.image}></img> : null}

                                                                            </div>
                                                                        );

                                                                    })}
                                                                </div>
                                                                <div className={cx('col')}>
                                                                    <p className={cx('pt-1 pb-2 text-justify')}><strong>Matrix List 2</strong></p>
                                                                    {_.map(data.matrix_list_2, (matval2, i) => {

                                                                        return (
                                                                            <div key={i} className={cx('pb-3')}>
                                                                                <p className={cx('pt-1 text-justify', style.optionstyle)}>({matval2.option_title}) <strong>{matval2.choice}</strong></p>
                                                                                {matval2.image ?
                                                                                    <img className={cx(style.optionimage)} src={matval2.image}></img> : null}

                                                                            </div>
                                                                        );

                                                                    })}
                                                                </div>
                                                            </div>
                                                            : <div className={cx('row')}>
                                                                <div className={cx('col-md-12 pb-0')}>
                                                                    <strong>Options{' '}:</strong>
                                                                </div>
                                                                {_.map(data.choices, (opt) => {
                                                                    opt.image ? count++ : '';
                                                                })}
                                                                {
                                                                    _.map(data.choices, (opt, i) => {
                                                                        return (
                                                                            <div key={i} className={cx(count < data.choices.length && count != 0 ? 'col-md-12' : 'col-md-6')}>
                                                                                <div>
                                                                                    <p className={cx('pt-1 text-justify', style.optionstyle)}>({opt.option_title}) <strong>{opt.choice}</strong>{opt.is_right_choice == true ? <i className={cx(' fa fa-check-circle', style.checkicon)} ></i> : null} </p>
                                                                                    {opt.image ?
                                                                                        <img className={cx(count < data.choices.length && count != 0 ? style.optionimagerow : style.optionimagecol)} src={opt.image}></img> : null}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }

                                                            </div>
                                                    }
                                                    {data.matrix_right_choice ?
                                                        <div className={cx('col')}>
                                                            <div className={cx('mt-5 pb-2')}><strong>Right Choice</strong></div>
                                                            {_.map(JSON.parse(data.matrix_right_choice), (right_choice, i) => {
                                                                return (
                                                                    <div className={cx('row')} key={i}>
                                                                        <span className={cx(style.matrixindexval)}>{String.fromCharCode(97 + i)}</span>
                                                                        {
                                                                            _.map(right_choice, (u, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        {i == 0 ? <div className={cx('text-center')}>{index + 1}</div> : null}
                                                                                        <div className={cx(style.matrixbox)}>
                                                                                            <p className={cx(u == 1 ? style.matrixvaltrue : style.matrixvalfalse)}></p>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                );
                                                            })}
                                                        </div> : null}
                                                    {data.solutions && data.solutions == '' ? null :
                                                        <div className={cx('row')}>
                                                            <div className={cx('col-md-12 mt-5 text-capitalize')}>
                                                                <strong>solution{' '}:</strong>
                                                            </div>
                                                            {
                                                                _.map(data.solutions, (rightans, i) => {
                                                                    return (
                                                                        <div key={i} className={cx('col-md-12')}>
                                                                            {_.map(rightans.steps, (u, i) => {
                                                                                return (
                                                                                    <div key={i}>
                                                                                        <div dangerouslySetInnerHTML={createMarkup(u.solution_text)} />
                                                                                        <div className={cx(style.mathjaxstyle)}>
                                                                                            <MathJax.Node formula={u.solution_text} />
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}

                                                                        </div>
                                                                    );

                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>


                                            </div>
                                            <div className={cx('col-md-3', style.question_reply_color)}>
                                                <div className={cx(style.corrections)}>
                                                    <div className={cx(style.corrections_title)}>
                                                        <strong>corrections</strong>
                                                    </div>
                                                    <div>
                                                    </div>
                                                    <div className={cx(style.corrections_body)}>
                                                        {
                                                            questionComment.question_id == data.id ?
                                                                this.newPostedComment() : this.actualComment(data.issue)

                                                        }
                                                    </div>
                                                </div>

                                                <div className={cx(style.reply_box, 'clearfix')}>
                                                    <textarea placeholder="Reply here" className={cx('float-right')} ref='comment' rows='3' id={'textarea_' + data.id}></textarea>
                                                    <div>
                                                        <span className={!comment.isValid && comment.qid == data.id ? 'float-right text-danger small font-italic' : 'd-none'}>{!comment.isValid && comment.qid == data.id ? comment.message : ''}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button className={cx('theme-btn blue float-right', style.button)} onClick={() => data.issue || this.state.issue ? this.handelCommentClickCreateIssue(checked, data.id, data.issue) : null} type="button">Reply</button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                </div>
            </MathJax.Provider>
        );
    }
}


function mapStateToProps(state) {
    return {
        developedcontent: state.app.developedcontent,
        comment: state.app.addcomment,
        created_issue: state.app.createissue,
        resolved: state.app.resolved
    };
}

function mapDispatchToProps(dispatch) {
    return {
        viewDevelopedContent: (params) => dispatch(actions.getDevelopedContent(params)),
        createIssue: (params) => dispatch(actions.getCreateIssue(params)),
        addComment: (params) => dispatch(actions.getAddComment(params)),
        issueResolved: (params) => dispatch(actions.issueResolved(params)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewDevelopedQuestion);
