import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Draft from './draft.js';
import Unpublished from './unpublished.js';
import Published from './published.js';
import 'react-tabs/style/react-tabs.scss';
import styles from './testseries.scss';
import globestyle from '../../sass/global.scss';
import PopUpModal from './../PopUpModal';
import cx from 'classnames';

class TabBarMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: this.props.location.tabIndex ? parseInt(this.props.location.tabIndex) : 0,
            activePopup: '',
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState) {
        //for closing popModel
        if (prevProps.savedTestSeries !== this.props.savedTestSeries && this.props.savedTestSeries) {
            this.PopUpModal.toggle();
        }
    }
    handleSelect(key) {
        this.setState({ tabIndex: key });
    }

    movefiles() {
        this.setState({
            details: !this.state.details,
        });
    }

    showPopup = params => {
        this.setState({
            activePopup: params,
        });
        this.PopUpModal.toggle();
    };


    render() {
        const { activePopup } = this.state;
        return (
            <div>
                <nav aria-label="breadcrumb">
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to='/test-series'>Home</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem, globestyle.active)} aria-current="page"><span>Test Series</span></li>
                    </ol>
                </nav>
                <div className="clearfix"></div>
                <div className={cx(styles.maincontent)}>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.handleSelect(tabIndex)} className={cx('row')}>
                        <div className={cx('col-md-1')}>
                            <div className={cx(styles.align_add_icon)}>
                                <a  onClick={() => this.showPopup('newtestseries')}> <i className={cx('fa fa-plus')}></i> </a>
                            </div>
                        </div>
                        <div className={cx('col-md-11')}>
                            <TabList className={cx(styles.tabs_list, 'nav nav-pills nav-justified')}>
                                <Tab className={cx('nav-item icon-draft', this.state.tabIndex === 0 ? styles.redactive : null)}>Drafts</Tab>
                                <Tab className={cx('nav-item icon-draft', this.state.tabIndex === 1 ? styles.orangeactive : null)}>Unpublished</Tab>
                                <Tab className={cx('nav-item icon-draft', this.state.tabIndex === 2 ? styles.greenactive : null)}>Published</Tab>
                            </TabList>
                        </div>
                        <div className={cx('col-md-12')}>
                            <TabPanel><Draft /></TabPanel>
                            <TabPanel><Unpublished tabIndex = "1"/></TabPanel>
                            <TabPanel><Published tabIndex = "2"/></TabPanel>
                        </div>
                    </Tabs>
                </div>
                <PopUpModal modelcontent={activePopup} onRef={ref => (this.PopUpModal = ref)} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        savedTestSeries: state.app.testseries
    };
}


export default connect(
    mapStateToProps
)(TabBarMenu);
