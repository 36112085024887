import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './header.scss';
import PopUpModal from './../PopUpModal';
import actions from '../../redux/actions/app.js';
import cx from 'classnames';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.showPopup = this.showPopup.bind(this);
    }

    componentWillMount() {
        this.props.onLogin();
    }

    componentDidUpdate(nextProps) {
        if(this.props.loginResult!==nextProps.loginResult && this.props.loginResult && this.props.loginResult.user_id) {
            localStorage.setItem('userdetails', JSON.stringify(this.props.loginResult));
            this.props.isLoggedIn(true);
        }
    }

    showPopup = (event) => {
        event.preventDefault();
        this.PopUpModal.toggle();
    }

    render() {
        return (
            <nav className={cx('navbar', 'navbar-expand-md', 'navbar-light', 'bg-transparent',
                'justify-content-sm-start', styles.navbarHeader)} >
                <div className='container-fluid d-flex justify-content-md-between'>
                    <a className='navbar-brand order-1 order-lg-0 ml-lg-0 ml-2' href='/'>
                        <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/et-logo.png' alt='' className={styles.brandLogo} />
                    </a>
                    <button className='navbar-toggler align-self-start' type='button' id='navbarButton'
                        style={{ margin: 'auto 0px' }}>
                        <span className='navbar-toggler-icon'></span>
                    </button>
                    <div className='collapse navbar-collapse flex-md-row justify-content-md-end d-flex flex-column flex-md-row justify-content-md-end mobileMenu p-md-0' id='navbarContainer'>
                        <ul className={cx('navbar-nav text-bmyc align-self-stretch', styles.navbarContainer)}>
                            <li className='nav-item d-none d-md-block' id='moreOptions'>
                                <Link to='#' className='nav-link font-Roboto text-md-center text-bmyc text-sm-left px-4 py-4'>More</Link>
                            </li>
                            <li className='nav-item d-none d-md-block' id='login'>
                                <Link to='#' className='nav-link font-Roboto text-md-center text-bmyc text-sm-left px-4 py-4' onClick={this.showPopup}>Login</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <PopUpModal modelcontent={'login'} onRef={ref => (this.PopUpModal = ref)} isLoggedIn={this.props.isLoggedIn} />
                </div>
            </nav>
        );
    }
}

function mapStateToProps (state) {
    return {
        loginResult: state.app.login,
    };
}

function mapDispatchToProps (dispatch) {
    return {
        onLogin: (loginData) => dispatch(actions.getLogin(loginData)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
