import React from 'react';
import { Link } from 'react-router-dom';
import styles from './profiledetails.scss';
import { connect } from 'react-redux';
import globestyle from '../../sass/global.scss';
import cx from 'classnames';

class Mainscreen extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            userinfo: null,
        };
    }

    componentWillMount() {
        if(this.props.loginResult) {
            const userinfo = {
                user_id: this.props.loginResult.user_id,
                name: this.props.loginResult.user_profile.name,
            };
            this.setState({
                userinfo
            });
        }
    }


    render() {
        const { userinfo } = this.state;
        return (
            <div className={cx(styles.refer_index)}>
                <nav aria-label="breadcrumb">
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to='#'>Home</Link></li>
                        {/* <li className={cx('breadcrumb-item', globestyle.breadcrumbitem, globestyle.active)} aria-current="page"><span>Help</span></li> */}
                    </ol>
                </nav>

                <div className={cx('clearfix')}></div>
                <div className={cx(styles.maincontent)}>
                    <div className={cx('row')}>
                        <div className={cx('col')}>
                            <div className={cx(styles.manage_bottom_margin)}>
                                <p className={cx(styles.refer_tagp)}>
                                    <strong>Hello {userinfo?userinfo.name:''} !</strong>
                                </p>
                            </div>
                            <div className={cx(styles.manage_bottom_margin)}>
                                <p className={cx(styles.refer)}>
                                    <strong>Your account is not verified yet</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.manage_border,'row ml-2 mr-2')}>

                        <div className={cx('col-md-5 col-sm-6 col-xs-12 mt-3 p-2')}>
                            <div className={cx('form-group pl-2')}>
                                <span>Meanwhile please complete <strong>Myprofile</strong></span>
                                <p className={cx(styles.manage_para)}>Fill personal details,professional details & Financial details</p>
                            </div>
                        </div>
                        <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <span className={cx(styles.incomplete,'text-danger font-weight-bold d-flex justify-content-center mt-3 p-2')}>Incomplete</span>
                            </div>
                        </div>
                        <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <Link to='/Callprofile'>
                                    <button className={cx('theme-btn', 'btn btn-success mt-4 ml-2 p-2')} type='submit' onClick={this.handlesubmit}>
                                        Complete My Profile Info
                                    </button>
                                </Link>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {
        loginResult: state.app.login,
    };
}



export default connect(
    mapStateToProps,
)(Mainscreen);
