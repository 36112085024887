import React from 'react';
import styles from './modalbox.scss';
import cx from 'classnames';
import globestyle from '../../sass/global.scss';
// DatePicker from 'react-custom-date-picker';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import * as moment from 'moment';



class Schedulepublishbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message:'',
            exam:'',
            test_series_id:'',
            selectedDate: { isValid: false, message: '', value: null },

        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }
    handleDateChange = date => {
        this.setState({ selectedDate: { isValid: false, message: '', value: date } });
    }
    componentWillMount() {
        if (this.props.displayfailedmessage ) {
            this.setState({
                'message': this.props.displayfailedmessage,
            });
        }
    }


    componentDidUpdate(nextProps) {
        if (this.props.displayfailedmessage!=nextProps.displayfailedmessage && this.props.displayfailedmessage) {
            this.setState({
                message: this.props.displayfailedmessage,
            });
        }
    }
    onsubmit() {
        const { selectedDate} = this.state;
        let date = moment(selectedDate.value).format();
        let data = {
            exam:this.props.examId,
            test_series_id:this.props.test_series_id,
            publish_on :date
        };
        this.props.onsubmitdetails(data);
    }
    render() {
        const { selectedDate } = this.state;
        return (
            <div className='modal fade' role='dialog'  id='schedule-box'>
                <div className={cx(styles.modal_dialog, 'modal-dialog')}>
                    <div className={cx(styles.add_modal_content, 'modal-content')}>
                        <div className={cx(styles.add_modal_header, 'modal-header')}>
                            <button type='button' className={cx('close', styles.btn_width)} data-dismiss='modal'><span className={cx('fa fa-times-circle')}></span></button>
                        </div>
                        <div className={cx('modal-body')}>
                            <div className={cx(styles.header_alignment, 'text-center')}>
                                <h4 className={cx(styles.header_height)}><b>Schedule publish</b></h4>
                            </div>
                            <div>
                                <p className={cx(styles.spantext,'row justify-content-md-center')}>Test will publish automatically on the date</p>
                                <p  className={cx(styles.spantext,'row justify-content-md-center text-danger small font-italic')}>{this.state.message}</p>
                            </div>
                            <div className={cx('col')}>
                                <div className={cx('row justify-content-md-center')}>
                                    <div className={cx(styles.adjustinputbox, 'col col-lg-9')}>
                                        <div className={cx('form-group')}>
                                            <fieldset className={cx(globestyle.fset,globestyle.blueborder)}>
                                                <legend className={cx(globestyle.legend,globestyle.bluefont, globestyle.small)}>Date</legend>
                                                <div className={cx(styles.colstyle, 'col-md-6')}>
                                                    <div>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <DateTimePicker
                                                                keyboard
                                                                format="YYYY-MM-DD LT"
                                                                disablePast={false}
                                                                value={selectedDate.value}
                                                                mode="datetime"
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    placeholder: 'Select date',
                                                                }}
                                                                onChange={this.handleDateChange.bind(this)}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                        <span className={!selectedDate.isValid ? 'text-danger small font-italic' : 'd-none'}>{!selectedDate.isValid ? selectedDate.message : ''}</span>
                                                    </div>

                                                </div>
                                            </fieldset>

                                        </div>
                                    </div>
                                </div>
                                <div className={cx('row justify-content-md-center')}>
                                    <div className={cx(styles.adjustinputbox, 'col-md-3 col-sm-6 col-xs-12')}>
                                        <div className={cx('form-group')}>
                                            <button className={cx('theme-btn', 'blue')} type='submit' onClick={this.onsubmit}>
                                                SCHEDULE
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        displayfailedmessage: (state.app.statusfailed ? state.app.statusfailed: null),
        schedulepublishdata:(state.app.schedulepublish ? state.app.schedulepublish: null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onsubmitdetails: (data) => dispatch(actions.getschedulepublish(data)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Schedulepublishbox);
