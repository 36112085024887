import React from 'react';
import styles from './profiledetails.scss';
import { connect } from 'react-redux';
import cx from 'classnames';
import actions from '../../redux/actions/app.js';
import { Link } from 'react-router-dom';
import globestyle from '../../sass/global.scss';


class ViewProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo: null,
        };

    }



    componentDidMount() {
        this.props.getProffesionaldetails();
        this.props.onGetprofiledetails();
    }
    componentDidUpdate(nextProps) {
        if (this.props.proffesionalDetails !== nextProps.proffesionalDetails &&  this.props.proffesionalDetails.length>0 ) {
            this.setState({ displayData: this.props.proffesionalDetails.data.professional_details
            });
        }
        if(this.props.profileDetails !== nextProps.profileDetails && this.props.profileDetails) {
            this.setState({
                profile_name : this.props.profileDetails.data.profile_details.name,
                profile_photo:this.props.profileDetails.data.profile_details.profile_photo,
            });

        }

    }

    render() {
        const { profile_name,displayData,profile_photo} = this.state;
        return (
            <div className={cx(styles.refer_index)}>
                <nav aria-label="breadcrumb">
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to='/test-series'>Home</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem, globestyle.active)} aria-current="page"><span>MY PROFILE</span></li>
                    </ol>
                </nav>
                <div className={cx('container')}>
                    <div className={cx('row')}>
                        <div className={cx('col-md-12 col-sm-12 col-xs-12')}>
                            <p className={cx(styles.refer_tagp, styles.refer_tagp_color_normal)}>
                                <strong>Your profile will look like this.</strong>{' '}
                            </p>
                        </div>
                    </div>
                    <div className={cx('row')}>
                        <div className={cx('col-md-3 col-sm-4 col-xs-12')}>
                            <div className={cx(styles.refer_index)}>
                                <div className={cx('row')}>
                                    <div className={cx('col')}>
                                        <div className={cx(styles.manage_bottom_margin)}>
                                            {profile_photo == null ?
                                                <img className={cx(
                                                    styles.manage_profile_image
                                                )} src={require('images/dummyuser.png')} alt='Profile.jpeg' /> :
                                                <img className={cx(
                                                    styles.manage_profile_image
                                                )} src={profile_photo} alt='Profile.jpeg' />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className={cx('col-md-9 col-sm-8 col-xs-12 mt-4')}>
                            <div className={cx('row')}>
                                <div className={cx('col')}>
                                    <div className={cx(styles.manage_bottom_margin)}>
                                        <p className={cx(styles.textdetails,styles.manage_bottom_margin)}>Name</p>
                                        <p className={cx(styles.text)}>{profile_name?profile_name:''}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('row')}>
                                <div className={cx('col')}>
                                    <div className={cx(styles.manage_bottom_margin)}>
                                        <p className={cx(styles.textdetails,styles.manage_bottom_margin)}>BIO</p>
                                        <p className={cx(styles.text)}>{displayData?displayData.bio:''}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('row')}>
                                { displayData && displayData ?
                                    <div className={cx('col')}>
                                        <div className={cx(styles.manage_bottom_margin)}>
                                            <p className={cx(styles.textdetails, styles.manage_bottom_margin)}>Education</p>
                                            {displayData && displayData.education_details.map((u, i) => {
                                                return (
                                                    <p className={cx(styles.text)} key={i}>{u.degree}{' '}in{' '}{u.course}{' '}({u.year})</p>
                                                );
                                            })}
                                        </div>
                                    </div> : null
                                }
                            </div>
                            <div className={cx('row')}>
                                <div className={cx('col')}>
                                    <div className={cx(styles.manage_bottom_margin)}>
                                        <p className={cx(styles.textdetails,styles.manage_bottom_margin)}>Faculty Type</p>
                                        <p className={cx(styles.text)}>{displayData?displayData.faculty_type:''}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('row')}>
                                {displayData && displayData ?
                                    <div className={cx('col')}>
                                        <div className={cx(styles.manage_bottom_margin)}>
                                            <p className={cx(styles.textdetails, styles.manage_bottom_margin)}>Teaching Experience</p>
                                            { displayData && displayData.teaching_experiences.map((u,i) => {
                                                return(
                                                    <p className={cx(styles.text)} key={i}>{u.experience}</p>
                                                );
                                            })
                                            }
                                        </div>

                                    </div>
                                    : null}
                            </div>
                            <div className={cx('row mt-5')}>
                                <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <Link to={{pathname :'/callprofile', state : {currentStep : 2}}}>
                                            <button
                                                className={cx(globestyle.graybutton,'btn btn-outline-primary')}
                                                type='submit' onRef={ref => (this.proffdetails = ref)}
                                            >
                                                EXIT PREVIEW
                                            </button>
                                        </Link>


                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>
                </div>


            </div>


        );
    }
}

function mapStateToProps (state) {
    return {
        profileDetails:state.app.getprofiledetails,
        proffesionalDetails: state.app.getproffesionaldetails,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getProffesionaldetails: () => dispatch(actions.getProffesionaldetails()),
        onGetprofiledetails:()=>dispatch(actions.getProfiledetails()),

    };
}


export default connect(
    mapStateToProps,mapDispatchToProps
)(ViewProfile);

