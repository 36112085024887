import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';
import styles from '../HomePage/index.scss';


class Otp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otpCount: 6,
        };
    }
    componentDidMount() {
        this.props.onRef(this);
    }
    // eslint-disable-next-line no-unused-vars
    handleKeyUp(i) {
        let nid = i+1;
        if(this.state.otpCount >= nid ){
            this[`otp${nid}`].focus();
        }

    }
    componentWillUnmount() {
        this.props.onRef(undefined);
    }
    componentDidUpdate(nextProps) {
        if (this.props.otpmatch!=nextProps.otpmatch && this.props.otpmatch) {
            this.setState({
                'otpMessage': this.props.otpmatch.message,
            });
        }
    }
    resendOtp =()=>{
        const submitOtpagain ={ 'phone': this.props.phone, 'otp_type': this.props.otpType };
        this.props.sendOtp(submitOtpagain);
    }
    saveStepTwo() {
        // eslint-disable-next-line no-unused-vars
        let otp = '';
        let message ='';
        for (let i = 1; i <= this.state.otpCount; i++) {
            otp += this[`otp${i}`].value;
        }
        const exp = /^[0-9]{6}$/g;
        const checkotp = otp.match(exp);
        if (!checkotp || checkotp == null) {
            message = 'Incorrect Otp.';
            this.setState({
                'otpMessage': message,
            });
        }
        if(message==''){
            const vcode = this.props.otp.data.verification_code;
            this.props.verifyOtp({ verification_code: vcode, otp: otp, otp_type: this.props.otpType });
        }

    }

    render() {
        const{otpMessage} = this.state;
        return (
            <div className={cx(styles.otp_container)}>
                <p  className={cx('row justify-content-md-center text-danger small font-italic')}>{otpMessage?otpMessage:null}</p>
                <h1>Enter OTP sent on {this.props.phone}</h1>
                <form>
                    <div className={cx(styles.otp_box_container)}>
                        <div className={cx('row justify-content-md-center')}>
                            <div className={cx('col col-lg-8')} >
                                <div className={cx('row')}>
                                    {(() => {
                                        const otpInput = [];
                                        for (let i = 1; i <= this.state.otpCount; i++) {
                                            otpInput.push(
                                                <div className={cx('col')} key={i}>
                                                    <input type='text'
                                                        onChange={() => this.handleKeyUp(i)}
                                                        ref={input => {
                                                            this[`otp${i}`] = input;
                                                        }}
                                                        maxLength='1' />
                                                </div>
                                            );
                                        }
                                        return otpInput;
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <p className={cx(styles.resend_otp)}>Didn&rsquo;t get? <a onClick={this.resendOtp}>RESEND</a></p>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        otp: (state.app.otp ? state.app.otp : null),
        otpmatch: (state.app.otplogin ? state.app.otplogin : null),
    };
}

function mapDispatchToProps(dispatch) {

    return {
        sendOtp: (otpData) => dispatch(actions.getOtp(otpData)), //for resend  purpose
        verifyOtp: (otpCode) => dispatch(actions.getVerifyOtp(otpCode)), //verify entred otp
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Otp);