import React from 'react';
import styles from '../Header/header.scss';
import globestyle from '../../sass/global.scss';
import cx from 'classnames';
import {  Link } from 'react-router-dom';
import validation from '../../validations';
import actions from '../../redux/actions/app.js';
import { connect } from 'react-redux';
import Login from '../Login';




class Forgotpassword extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            activeScreen:'forgetpass',
            mobilenumber: { isValid: false, message: '' },
            password: { isValid: false, message: '' },
            email: { isValid: false, message: '' },
            type: 'password',
            passwordResult:'',
            error:'',
            passwordFailed:'',

        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showHide = this.showHide.bind(this);
        this.changeScreen = this.changeScreen.bind(this);
    }

    componentDidUpdate(nextProps) {
        if (this.props.passwordResult !== nextProps.passwordResult && this.props.passwordResult) {
            this.setState({ passwordResult: this.props.passwordResult,activeScreen:'success' });
        }
        if (this.props.passwordFailed !== nextProps.passwordFailed && this.props.passwordFailed) {
            this.setState({ passwordFailed: this.props.passwordFailed });
        }


    }
    handleSubmit() {
        const mobilenumber = this.refs.mobilenumber.value;
        const password = this.refs.password.value;
        const email = this.refs.email.value;
        const isValidMobile = validation('mobile', mobilenumber);
        const isValidPassword = validation('password', password);
        const isValidEmail = validation('email', email);

        if (isValidMobile.valid && isValidPassword.valid && isValidEmail.valid ) {
            this.setState({
                mobilenumber: { isValid: true, message: '' },
                password: { isValid: true, message: '' },
                email: { isValid: true, message: '' },
            });
            const submitData = { phone: mobilenumber, password: password,email:email };
            this.props.onForgotpassword(submitData);
        } else {
            this.setState({
                mobilenumber: { isValid: isValidMobile.valid, message: isValidMobile.message },
                password: { isValid: isValidPassword.valid, message: isValidPassword.message },
                email: { isValid: isValidEmail.valid, message: isValidEmail.message },
            });
        }
    }
    showHide(e) {
        e.preventDefault();
        this.setState({
            type: this.state.type === 'password' ? 'input' : 'password',
        });
    }

    showForgetPasswordScreen(screen) {
        if(screen != 'forgetpass'){
            return null;
        }
        const { mobilenumber, password, type, passwordFailed,email } = this.state;
        return (
            <div className={cx(styles.loginblock)}>
                <form>
                    <div className={cx(styles.login_title_container)}>
                        <h1>Forgot Password</h1>
                    </div>
                    <span className={passwordFailed ? 'text-danger font-weight-bold d-flex justify-content-center' : 'd-none'}>{passwordFailed ? passwordFailed : ''}</span>
                    <div className={cx('row')}>
                        <div className={cx('col')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Mobile No</legend>
                                    <input type='text' ref='mobilenumber' placeholder='Mobile number' className={cx('form-control')} maxLength='10' />
                                </fieldset>
                                <span className={!mobilenumber.isValid ? 'text-danger small font-italic' : 'd-none'}>{!mobilenumber.isValid ? mobilenumber.message : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row ')}>
                        <div className={cx('col')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>Email ID</legend>
                                    <input ref='email' type='text' placeholder='Email Id ' className={cx('form-control')} />
                                </fieldset>
                                <span className={!email.isValid ? 'text-danger small font-italic' : 'd-none'}>{!email.isValid ? email.message : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row ')}>
                        <div className={cx('col')} >
                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                    <legend className={cx(globestyle.legend, globestyle.bluefont)}>New Password</legend>
                                    <input type={type} ref='password' placeholder='Password' className={cx('form-control')} />
                                    <span className={cx(globestyle.password_show, (type === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'))} onClick={this.showHide}></span>
                                </fieldset>
                                <span className={!password.isValid ? 'text-danger small font-italic' : 'd-none'}>{!password.isValid ? password.message : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.bottom_container,'row justify-content-md-center')}>
                        <div className={cx('col-md-6 row justify-content-md-center', styles.login_button)}>
                            <button
                                className={cx('theme-btn', 'blue')}
                                type='button' onClick={this.handleSubmit}>
                                SUBMIT
                            </button>
                        </div>

                    </div>
                </form>
            </div>

        );
    }

    successScreen(screen){
        if(screen != 'success'){
            return null;
        }
        return(
            <div className={cx(styles.loginblock)}>
                <div className={cx(styles.modal_body)}>
                    <div className={cx(styles.modal_title_container)}>
                        <h1>Done!!</h1>
                        <span>Your account login password has been changed.Please login again</span>

                    </div>
                    <div className={cx('row')}>
                        <div className={cx('col-md-12 row justify-content-md-center')}>
                            <Link to='#' onClick={this.changeScreen}>Login Here</Link>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
    changeScreen(){
        this.setState({
            activeScreen:'login'
        });

    }
    loginScreen(screen){
        if(screen != 'login'){
            return null;

        }
        return (
            <Login/>
        );
    }
    render() {

        return (
            <div className={cx(styles.join_container)}>
                <React.Fragment>
                    {this.loginScreen(this.state.activeScreen)}
                    {this.showForgetPasswordScreen(this.state.activeScreen)}
                    {this.successScreen(this.state.activeScreen)}
                </React.Fragment>
            </div>


        );
    }
}

function mapStateToProps(state) {
    return {
        passwordResult: state.app.newpassword,
        passwordFailed:state.app.passwordfailed
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onForgotpassword: (submitData) => dispatch(actions.getForgotpassword(submitData)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Forgotpassword);
