import React, { Component, Fragment } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import getStore from './redux/store';
import Header from './components/Header';
import InnerHeader from './components/InnerHeader';
import referAndEarn from './components/referAndEarn';
import referSuccess from './components/referAndEarn/referSuccess';
import Revenue from './components/revenue';
import Doubts from './components/Doubts';
import doubtDetail from './components/Doubts/doubtDetail';
import examTestSeries from './components/revenue/examTestSeries';
import testDetail from './components/revenue/testDetail';
import Settings from './components/Settings';
import Footer from './components/Footer';
import SideBar from './components/SideBar';
import HomePage from './components/HomePage';
import ExamPage from './components/ExamPage';
import TestSeries from './components/TestSeries';
import UnPublished from './components/TestSeries/unpublished';
import Published from './components/TestSeries/published';
import UploadFiles from './components/TestSeries/upload_files';
import UploadTest from './components/TestSeries/upload_test';
import Helpform from './components/Help';
import ViewDevelopedQuestion from './components/TestSeries/view_developed_question';
import cx from 'classnames';
import Myprofile from './components/Profiledetails/myprofile';
import Mainscreen from './components/Profiledetails/mainscreen';
import Financialdetails from './components/Profiledetails/financedetails';
import Proffessionaldetails from './components/Profiledetails/proffdetails';
import Callprofile from './components/Callprofile';
import ViewProfile from './components/Profiledetails/viewprofile';


require('./app.scss');

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
        };
        this.handleLoggedIn = this.handleLoggedIn.bind(this);
    }

    handleLoggedIn = (data) => {
        this.setState({isLoggedIn: data});
    }

    render() {
        // const Router = HashRouter;
        const store = getStore();
        return (
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Fragment>
                            {
                                this.state.isLoggedIn == false ?
                                    <div>
                                        <Header isLoggedIn={this.handleLoggedIn} />
                                        <Route exact path='/' component={HomePage} />
                                        <Route path='/exam' component={ExamPage} />
                                        <Footer />
                                    </div> :
                                    <div className={cx('container-fluid')}>
                                        <div className={cx('row')}>
                                            <SideBar activeExam={this.handleSidebar} />
                                            <div className={cx('col-xl-10', 'col-lg-9', 'col-xs-12', 'rightblock')}>
                                                <InnerHeader isLoggedIn={this.handleLoggedIn} />
                                                <div className={cx('clearfix')}></div>
                                                <Route exact path='/test-series' component={TestSeries}/>
                                                <Route path="/doubts" component={Doubts} />
                                                <Route path="/doubts/doubt-detail" component={doubtDetail} />
                                                <Route path="/settings" component={Settings} />
                                                <Route path='/refer-and-earn' component={referAndEarn} />
                                                <Route path='/refer-and-earn/refer-success' component={referSuccess} />
                                                <Route exact path='/revenue' component={Revenue} />
                                                <Route path='/Help' component={Helpform} />
                                                <Route path='/revenue/exam-test-series' component={examTestSeries} />
                                                <Route path='/revenue/test-detail' component={testDetail} />
                                                <Route path='/test-series/upload-files' component={UploadFiles} />
                                                <Route path='/test-series/unpublished' component={UnPublished} />
                                                <Route path='/test-series/published' component={Published} />
                                                <Route path='/test-series/upload_test' component={UploadTest} />
                                                <Route path='/test-series/view-developed-question' component={ViewDevelopedQuestion} />
                                                <Route path='/myprofile' component={Myprofile} />
                                                <Route path='/profile-details/mainscreen' component={Mainscreen} />
                                                <Route path='/financedetails' component={Financialdetails} />
                                                <Route path='/proffdetails' component={Proffessionaldetails} />
                                                <Route path='/callprofile' component={Callprofile} />
                                                <Route path='/viewprofile' component={ViewProfile} />


                                            </div>

                                        </div>
                                    </div>}
                        </Fragment>
                    </Switch>
                </Router>
            </Provider>
        );
    }
}

export default App;
