/* eslint-disable no-unused-vars */
import axios from 'axios';
const serverURL = 'http://staging.examtreasury.com';
const AuthStr = 'Basic OTE4NDQ4ODc4ODQ5OnRyaWdpbnRhMzA=';
//const AuthStr = 'Basic OTE4NDQ4ODc4ODQ5OjEyMzQ1Ng==';
//const AuthStr = 'Basic OTE4NDQ4ODc4ODQ4OnRyaWdpbnRhMzA=';
//const AuthStr = 'Basic OTE4MTcxMzY1ODY0OjEyMzQ1Ng==';
export function getExams() {
    return axios.get('/api/v1.0/exams/');
}

export function getBoards({ examSlug }) {
    return axios.get(`/api/v1.0/exams/${examSlug}/boards/`);
}

export function getLogin(loginData) {
    if (loginData) {
        return axios.post(serverURL + '/api/v1.0/teachers/login/', loginData);
    } else {
        let returnarr = {};
        const userdetails = (localStorage.getItem('userdetails') ? JSON.parse(localStorage.getItem('userdetails')) : null);
        if (userdetails != null) {
            returnarr = {
                data: {
                    data: userdetails,
                    status_code: 200,
                }
            };
            return returnarr;
        }
    }

}
export function getSendOtpWsignUp(signUpdata) {
    let returnarr = {};
    const callRequestData = { name: signUpdata.name, phone: signUpdata.phone };
    if (callRequestData !=null) {
        returnarr = {
            data: {
                data: callRequestData,
                status_code: 200,
            }
        };
    }
    return returnarr;
}

export function getSignUp(SignUpData) {
    var form_data = new FormData();
    for (var key in SignUpData) {
        form_data.append(key, SignUpData[key]);
    }
    return axios.post(serverURL + '/api/v1.0/teachers/signup/', form_data);
}
export function getOtp(otpData) {
    var form_data = new FormData();
    for (var key in otpData) {
        form_data.append(key, otpData[key]);
    }
    return axios.post(serverURL + '/api/v1.0/send-otp/', form_data);
}

export function getVerifyOtp(otpData) {
    var form_data = new FormData();
    form_data.append('verification_code', otpData.verification_code);
    form_data.append('otp', otpData.otp);
    if(otpData.otp_type == 'phone-verification'){
        return axios.post(serverURL + '/api/v1.0/otp-login/', form_data);
    }else{
        return axios.post(serverURL + '/api/v1.0/verify-otp/', form_data);
    }

}

export function getRequestCall(userInfo) {
    var form_data = new FormData();
    for (var key in userInfo) {
        form_data.append(key, userInfo[key]);
    }
    return axios.post(serverURL + '/api/v1.0/teachers/request-call/', form_data);
}
/** To get the list of Testseries(draft,published,unpublished)*/
export function getTestSerieslist(params) {
    const querystring = '?status=' + params.status + '&exam=' + params.exam;
    return axios.get(serverURL + '/api/v1.0/teachers/test-series-list/' + querystring, { headers: { Authorization: AuthStr }, crossDomain: true });
}

/** To get the list of test*/
export function getListoftest(params) {
    return axios.get(serverURL + '/api/v1.0/teachers/' + params.examid + '/' + params.testseriesid + '/tests-list/', { headers: { Authorization: AuthStr }, crossDomain: true });
}

/** To get the list of exams*/
export function getListofexams(params) {
    const querystring = '?status=' + params.status1 + '&status=' + params.status2;
    return axios.get(serverURL + '/api/v1.0/teachers/registered-exams/' + querystring, { headers: { Authorization: AuthStr }, crossDomain: true });
}
export function getExamList(params) {
    const querystring = '?status=' + params.status1 + '&status=' + params.status2;
    return axios.get(serverURL + '/api/v1.0/exams/exams-list/' + querystring, { headers: { Authorization: AuthStr }, crossDomain: true });
}

/** move draft to published list*/
export function getDrafttopublish(params) {
    const p = { 'status': params.status };
    return axios.post(serverURL + '/api/v1.0/teachers/' + params.exam + '/' + params.test_series_id + '/update-status/', p, { headers: { Authorization: AuthStr }, crossDomain: true });
}

/** move draft to unpublish list*/
export function getDrafttounpublish(params) {
    const p = { 'status': params.status };
    return axios.post(serverURL + '/api/v1.0/teachers/' + params.exam + '/' + params.test_series_id + '/update-status/', p, { headers: { Authorization: AuthStr }, crossDomain: true });
}

/**move to schedule publish in draft list and unpublished list  */
export function getschedulepublish(params) {
    var form_data = new FormData();
    form_data.append('publish_on', params.publish_on);
    return axios.post(serverURL + '/api/v1.0/teachers/' + params.exam + '/' + params.test_series_id + '/schedule-publish/', form_data, { headers: { Authorization: AuthStr }, crossDomain: true });
}

/**api to add new test  */
export function addNewTest(params) {
    let form_data = new FormData();
    for ( var key in params ) {
        form_data.append(key, params[key]);
    }
    return axios.post(serverURL + '/api/v1.0/teachers/' + params.exam_id + '/' + params.test_series_id + '/create-test/', form_data, { headers: { Authorization: AuthStr }, crossDomain: true });
}

/** to add new test series */
export function getaddnewtestseries(params) {
    const querystring = '?exam_id=' + params.exam_id;
    return axios.get(serverURL + '/api/v1.0/teachers/{exam_id}/create-test-series/' + querystring, { headers: { Authorization: AuthStr }, crossDomain: true });
}

export function getSubjectList(params) {
    return axios.get(serverURL + '/api/v1.0/exams/' + params.examid + '/exam-tree/', { headers: { Authorization: AuthStr }, crossDomain: true });
}

/**api to get the list of parent test in add new test */
export function getListofparenttest(params) {
    return axios.get(serverURL + '/api/v1.0/teachers/' + params.exam_id + '/' + params.test_series_id + '/parent-tests/', { headers: { Authorization: AuthStr }, crossDomain: true });
}

//To get List of Uploaded files
export function getUploadedFiles({ exam_id, test_series_id, test_id, subject_id }) {
    return axios.get(serverURL + `/api/v1.0/teachers/${exam_id}/${test_series_id}/${test_id}/${subject_id}/files/`, { headers: { Authorization: AuthStr }, crossDomain: true });
}

//To add Uploaded files
export function uploadFiles({ exam_id, test_series_id, test_id, subject_id, uploads }) {
    var form_data = new FormData();
    if(uploads) {
        for(let i=0; i<uploads.length; i++) {
            form_data.append('uploads', uploads[i]);
        }
    }
    return axios.post(serverURL +`/api/v1.0/teachers/${exam_id}/${test_series_id}/${test_id}/${subject_id}/upload-files/`, form_data, {headers: {Authorization: AuthStr}, crossDomain: true});
}

//To delete Uploaded file
export function deleteFile({ exam_id, test_series_id, test_id, subject_id, upload_id }) {
    return axios.delete(serverURL +`/api/v1.0/teachers/${exam_id}/${test_series_id}/${test_id}/${subject_id}/files/`, { data: {upload_id: upload_id}, headers: {Authorization: AuthStr}, crossDomain: true});
}


//To Update the Uploaded file
export function uploadChangeFile({ exam_id, test_series_id, test_id, subject_id, uploads, upload_id }) {
    var form_data = new FormData();
    if(uploads) {
        form_data.append('upload', uploads);
        form_data.append('upload_id', upload_id);
    }
    return axios.put(serverURL +`/api/v1.0/teachers/${exam_id}/${test_series_id}/${test_id}/${subject_id}/files/`, form_data, { headers: {Authorization: AuthStr}, crossDomain: true});
}

//To add new test series
export function getSaveTestSeries(param1, params2) {
    var form_data = new FormData();
    form_data.append('title', param1.title);
    form_data.append('year', param1.year);
    form_data.append('all_subjects', param1.all_subjects);
    if(param1.subject_ids && param1.subject_ids.length>0) {
        form_data.append('subject_ids', param1.subject_ids);
    }
    return axios.post(serverURL + '/api/v1.0/teachers/' + params2.examid + '/create-test-series/', form_data, { headers: { Authorization: AuthStr }, crossDomain: true });
}

//To get list of all revenues
export function getRevenues(params) {
    const querystring = '?mock=' + params.mock;
    return axios.get(serverURL + '/api/v1.0/teachers/revenue/'+ querystring, { headers: { Authorization: AuthStr }, crossDomain: true });
}

/** To get the revenue details of exams */
export function getRevenuedetails(params) {
    const querystring = '?mock=' + params.mock;
    return axios.get(serverURL + '/api/v1.0/teachers/revenue/' + params.examid + '/'+querystring, { headers: { Authorization: AuthStr }, crossDomain: true });
}

/** To get the revenue details of test series */
export function getRevenuefortestseries(params) {
    const querystring = '?mock=' + params.mock;
    return axios.get(serverURL + '/api/v1.0/teachers/revenue/' + params.examid + '/' + params.testseriesid + '/'+querystring, { headers: { Authorization: AuthStr }, crossDomain: true });
}


//To get active exam ID
export function getActiveData(data) {
    let returnarr = {};
    if (data) {
        localStorage.setItem('activeData', JSON.stringify({ activeExamId: data }));
        returnarr = {
            data: {
                data: { activeExamId: parseInt(data) },
                status_code: 200,
            }
        };
        return returnarr;
    } else {
        const activedata = (localStorage.getItem('activeData') ? JSON.parse(localStorage.getItem('activeData')) : null);
        if (activedata) {
            returnarr = {
                data: {
                    data: { activeExamId: parseInt(activedata) },
                    status_code: 200,
                }
            };
            return returnarr;
        }
    }
}

/** To Register for a new exam */
export function getRegisterforexam(params) {
    const exam_id = { 'exam_id': params };
    return axios.post(serverURL + '/api/v1.0/teachers/register-exam/', exam_id, { headers: { Authorization: AuthStr }, crossDomain: true });
}

/** To Register for a new exam */
export function getSubmitquery(params){
    const submit = {'subject':params.subject,'query':params.query};
    return axios.post(serverURL +'/api/v1.0/teachers/register-query/',submit, {headers: {Authorization: AuthStr}, crossDomain: true});
}

/** To Forgot Password */
export function getForgotpassword(params){
    const keys = {'phone':params.phone,'new_password':params.password,'email':params.email};
    return axios.post(serverURL +'/api/v1.0/forgot-password/',keys, {headers: {Authorization: AuthStr}, crossDomain: true});
}

/** To Change Password */
export function getChangepassword(params){
    const newData={'new_password':params.newpassword,'old_password':params.password};
    return axios.post(serverURL +'/api/v1.0/teachers/change-password/',newData, {headers: {Authorization: AuthStr}, crossDomain: true});
}

/** To Submit the test for development */
export function submitTest({ exam_id, test_series_id, test_id }){
    return axios.post(serverURL +`/api/v1.0/teachers/${exam_id}/${test_series_id}/${test_id}/`, '', {headers: {Authorization: AuthStr}, crossDomain: true});
}

/** To get view developed content */
export function getDevelopedContent(params){
    // 1/22/50/1
    // return axios.get(serverURL +`/api/v1.0/teachers/${params.exam_id}/${params.test_series_id}/${params.test_id}/${params.subject_id}/view-developed-content/`, {headers: {Authorization: AuthStr}, crossDomain: true});
    return axios.get(serverURL +'/api/v1.0/teachers/1/22/1/50/1/view-developed-content/', {headers: {Authorization: AuthStr}, crossDomain: true});
}

/** To comment on question issue */
export function getAddComment(params){
    // {comment:comment,question_id:qid,test_series_id:params.test_series_id,test_id:params.test_id}
    var form_data = new FormData();
    form_data.append('comment', params.comment);
    form_data.append('issue_id', params.issue_id);

    return axios.post(serverURL +`/api/v1.0/teachers/${params.test_series_id}/${params.test_id}/record-issue-comment/
    `, form_data, {headers: {Authorization: AuthStr}, crossDomain: true});
}



/** To comment on question issue */
export function getCreateIssue(params){
    var form_data = new FormData();
    form_data.append('issue', params.issue);
    form_data.append('question_id', params.question_id);
    return axios.post(serverURL +`/api/v1.0/teachers/${params.test_series_id}/${params.test_id}/raise-issue/`, form_data, {headers: {Authorization: AuthStr}, crossDomain: true});
}

/** To comment on question issue */
export function issueResolved(params){
    var form_data = new FormData();
    form_data.append('issue_id', params.issue_id);
    return axios.post(serverURL +`/api/v1.0/teachers/${params.test_series_id}/${params.test_id}/resolve-issue/`, form_data, {headers: {Authorization: AuthStr}, crossDomain: true});
}

/**To edit financial details */
export function editFinancialdetails(params){
    var form_data = new FormData();
    form_data.append('id', params.id);
    form_data.append('bank_account_name', params.bank_account_name);
    form_data.append('Bankname', params.Bankname);
    form_data.append('bank_account_no', params.bank_account_no);
    form_data.append('gstin', params.gstin);
    form_data.append('ifsc_code', params.ifsc_code);
    form_data.append('pan_no', params.pan_no);
    form_data.append('pan_photo', params.pan_photo);
    return axios.post(serverURL +'/api/v1.0/teachers/edit-financial-details/',form_data, {headers: {Authorization: AuthStr}, crossDomain: true});
}



/**To get financial details */
export function getFinancialdetails(){
    return axios.get(serverURL +'/api/v1.0/teachers/edit-financial-details/', {headers: {Authorization: AuthStr}, crossDomain: true});
}

/**To edit proffesional details */
export function editProffesionaldetails(params){
    const submit = {'bio':params.Bio,'faculty_type':params.faculty_type,'teaching_experiences':params.teaching_experiences,'education_details':params.education_details};
    return axios.post(serverURL +'/api/v1.0/teachers/edit-professional-details/',submit,  {headers: {Authorization: AuthStr}, crossDomain: true});
}

/**To get proffesional details */
export function getProffesionaldetails(){
    return axios.get(serverURL +'/api/v1.0/teachers/edit-professional-details/', {headers: {Authorization: AuthStr}, crossDomain: true});
}



/**To edit profile details */
export function editProfiledetails(params){
    var form_data = new FormData();
    form_data.append('dob', params.dob);
    form_data.append('pincode', params.pincode);
    form_data.append('country_id', params.country_id);
    form_data.append('state_id', params.state_id);
    form_data.append('city_id', params.city_id);
    form_data.append('name', params.name);
    form_data.append('phone', params.phone);
    form_data.append('email', params.email);
    form_data.append('address', params.address);
    form_data.append('profile_photo', params.profile_photo);
    return axios.post(serverURL +'/api/v1.0/teachers/edit-profile/',form_data, {headers: {Authorization: AuthStr}, crossDomain: true});
}

/**To get profile details */
export function getProfiledetails(){
    return axios.get(serverURL +'/api/v1.0/teachers/edit-profile/', {headers: {Authorization: AuthStr}, crossDomain: true});
}

/**To fetch cities,states and cities*/
export function fetchcities(){
    return axios.get(serverURL +'/api/v1.0/fetch-cities/', {headers: {Authorization: AuthStr}, crossDomain: true});
}

/**To edit user phone number*/
export function toeditPhonenumber(params){
    var form_data = new FormData();
    form_data.append('verification_code', params.verification_code);
    form_data.append('otp', params.otp);
    return axios.post(serverURL +'/api/v1.0/edit-phone/',form_data, {headers: {Authorization: AuthStr}, crossDomain: true});
}
