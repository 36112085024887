import React from 'react';
import styles from './callprofile.scss';
import cx from 'classnames';
import Proffessionaldetails from '../Profiledetails/proffdetails';
import Financialdetails from '../Profiledetails/financedetails';
import Myprofile from '../Profiledetails/myprofile';
import globestyle from '../../sass/global.scss';
import { Link } from 'react-router-dom';



class Callprofile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: this.props.location.state ? this.props.location.state.currentStep : 1,

        };
        this._child = React.createRef();
        this.saveButton = this.saveButton.bind(this);
    }


    // ===== wizard
    _next = () => {

        let currentStep = this.state.currentStep;
        currentStep = currentStep >= 2 ? 3 : currentStep + 1;
        this.setState({
            currentStep: currentStep,
        });


    }
    _savenext = () => {
        this._next();
        this.state.currentStep == 1 ? this.Myprofile.submitProfiledetails() : this.Proffessionaldetails.handlesubmit();
    }

    _prev = () => {
        let currentStep = this.state.currentStep;
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        this.setState({
            currentStep: currentStep
        });
    }





    stepOne(step) {
        if (step !== 1) {
            return null;
        }
        return (
            <Myprofile  onRef={ref => (this.Myprofile = ref)}  />
        );
    }

    stepTwo(step) {
        if (step !== 2) {
            return null;
        }

        return (
            <Proffessionaldetails  onRef={ref => (this.Proffessionaldetails = ref)}  />
        );
    }
    stepThree(step) {
        if (step !== 3) {
            return null;
        }
        return (
            <Financialdetails  onRef={ref => (this.Financialdetails = ref)} />
        );
    }

    previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (
                <div className={cx('row')}>
                    <div className={cx('col')} >
                        <button
                            type='button' className={cx(styles.outlinebutton,'btn btn-outline-primary')}
                            type="button" onClick={this._prev}>
                            BACK
                        </button>
                    </div>
                </div>
            );
        }
        return null;
    }
    nextButton() {
        let currentStep = this.state.currentStep;
        return (
            <div>
                <button
                    className={cx(globestyle.rightbutton,'theme-btn', 'blue')}
                    onClick={currentStep == 3 ? this.submit : this._savenext }
                    type="button">
                    {currentStep == 3 ? 'SUBMIT' : 'SAVE & NEXT' }
                </button>
            </div>
        );
    }
    saveButton() {
        this.state.currentStep == 1 ? this.Myprofile.submitProfiledetails() : this.state.currentStep == 2 ? this.Proffessionaldetails.handlesubmit() : this.Financialdetails.handlesubmit();
    }
    submit = () => {
        this.Financialdetails.handlesubmit();
    }

    render() {
        return (
            <div className={cx(styles.join_container)}>
                <nav aria-label="breadcrumb">
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to='/test-series'>Home</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem, globestyle.active)} aria-current="page"><span>MY PROFILE</span></li>
                    </ol>
                </nav>
                <React.Fragment>
                    <div className={cx(styles.join_title_navcontainer)}>
                        <ol>
                            <li>
                                <ul>
                                    <li className = {cx(styles.labeldisplaystyle)}>Personal details</li>
                                    <li>
                                        <ul className = {cx(styles.linestyle)}>
                                            <li></li>
                                            <li className={(this.state.currentStep == 1 ? styles.bullet_active : styles.bullet)}></li>
                                            <li className = {cx(styles.dottedstyle)}></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li className = {cx(styles.labeldisplaystyle)}>Professional Details</li>
                                    <li>
                                        <ul className = {cx(styles.linestyle)}>
                                            <li className = {cx(styles.dottedstyle)}></li>
                                            <li className={(this.state.currentStep == 2 ? styles.bullet_active : styles.bullet)}></li>
                                            <li className = {cx(styles.dottedstyle)}></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li className = {cx(styles.labeldisplaystyle)}>Financial Details</li>
                                    <li>
                                        <ul className = {cx(styles.linestyle)}>
                                            <li className = {cx(styles.dottedstyle)}></li>
                                            <li className={(this.state.currentStep == 3 ? styles.bullet_active : styles.bullet)}></li>
                                            <li></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    {this.stepOne(this.state.currentStep)}
                    {this.stepTwo(this.state.currentStep)}
                    {this.stepThree(this.state.currentStep)}
                    <div className = {cx(styles.alignright)}>
                        {this.state.currentStep == 2 ?
                            <div className = {cx(styles.buttonalignment)}>
                                <Link to='/viewprofile'>
                                    <button type='button' className={cx(globestyle.graybutton,'btn btn-outline-primary')} type='button'>
                                        PREVIEW PROFILE
                                    </button>
                                </Link>
                            </div>: null}
                        <div className = {cx(styles.buttonalignment)}>
                            {this.previousButton()}
                        </div>
                        <div className = {cx(styles.buttonalignment)}>
                            <button type='button' className={cx(styles.outlinebutton,'btn btn-outline-primary')} onClick={this.saveButton}>
                                SAVE
                            </button>
                        </div>
                        <div>
                            {this.nextButton()}
                        </div>
                        {this.state.currentStep == 3 ? this.submit :null}
                    </div>
                </React.Fragment>
            </div>
        );
    }
}







export default (Callprofile);
