import React from 'react';
import { Link } from 'react-router-dom';
import styles from './doubts.scss';
import cx from 'classnames';
// import _ from 'lodash';

class Doubts extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={cx(styles.main_block)}>
                <div className={cx('row')}>
                    <div className={cx('col-md-12')}>
                        <div className={cx(styles.doubts_listings)}>
                            <ol>
                                <li className={cx(styles.listing)}>
                                    <div className={cx('row')}>
                                        <div className={cx('col-md-12')}>
                                            <h1>TEST SERIES NAME
                                                <i className={cx(styles.details_icon, 'float-right fa fa-ellipsis-v')}></i>
                                                <span>ASKED ON 12-12-18</span>
                                            </h1>
                                        </div>
                                    </div>
                                    <div className={cx('row')}>
                                        <div className={cx('col-md-2', styles.center_image)}>
                                            <img src={require('images/thum_doubt.jpg')} width="100" alt="Paper" />
                                        </div>
                                        <div className={cx('col-md-8')}>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&rsquo;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages</p>
                                        </div>
                                        <div className={cx('col-md-2')}>
                                            <Link to='/doubts/doubt-detail' className={cx('theme-btn', 'green',styles.view_button)}>View</Link>
                                        </div>
                                    </div>
                                </li>
                                <li className={cx(styles.listing)}>
                                    <div className={cx('row')}>
                                        <div className={cx('col-md-12')}>
                                            <h1>TEST SERIES NAME
                                                <i className={cx(styles.details_icon, 'float-right fa fa-ellipsis-v')}></i>
                                                <span>ASKED ON 12-12-18</span>
                                            </h1>
                                        </div>
                                    </div>
                                    <div className={cx('row')}>
                                        <div className={cx('col-md-2', styles.center_image)}>
                                            <img src={require('images/thum_doubt.jpg')} width="100" alt="Paper" />
                                        </div>
                                        <div className={cx('col-md-8')}>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&rsquo;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages</p>
                                        </div>
                                        <div className={cx('col-md-2')}>
                                            <Link to='/doubts/doubt-detail' className={cx('theme-btn', 'green',styles.view_button)}>View</Link>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Doubts;
