/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import actionTypes from './../actionTypes';

const defaultState = _.get(global.window, '__INIT_STATE__.app', {
    exams: null,
    boards: null,
    login: null,
    signup: null,
    loginfailed: null,
    unpublishedlist: null,
    publishedlist: null,
    listoftests: null,
    listofexams: null,
    examlist: null,
    drafttopublish: null,
    drafttounpublish: null,
    schedulepublish: null,
    statusfailed: null,
    addnewtest: null,
    addnewtestseries: null,
    listofparenttest: null,
    addtestseries: null,
    uploadedfiles: null,
    revenues: null,
    revenuedetails: null,
    changepasswordfailed:null,
    //revenueTestseries:null,
    activedata: null,
    registerforexam: null,
    callrqstdata: null,
    submitquery: null,
    newpassword: null,
    changepassword: null,
    passwordfailed: null,
    submittest: null,
    developedcontent: null,
    createissue:null,
    addcomment: null,
    resolved: null,
    submitfinancedetails:null,
    submitproffesionaldetails:null,
    submitprofiledetails:null,
    getproffesionaldetails:null,
    getprofiledetails:null,
    newphonenumber:null,
    statedetails :null
});

export default handleActions({
    // TODO: Remove the below line after 'meta' is used or removed
    // eslint-disable-next-line no-unused-vars
    [actionTypes.APP_GET_EXAMS](state, { payload, error, meta }) {
        if (!error) {
            const exams = _.get(payload, 'data.exams');
            return {
                ...state,
                exams,
            };
        }

        return state;
    },

    // TODO: Remove the below line after 'meta' is used or removed
    // eslint-disable-next-line no-unused-vars
    [actionTypes.APP_GET_BOARDS](state, { payload, error, meta }) {
        if (!error) {
            const boards = _.get(payload, 'data.boards');
            return {
                ...state,
                boards,
            };
        }
        return state;
    },

    [actionTypes.APP_LOGIN](state, { payload, error }) {
        if (!error && payload && payload.data.status_code == 200) {
            const login = payload.data.data;
            return {
                ...state,
                login,
            };
        } else if (payload && payload.response) {
            const loginfailed = (payload.response ? payload.response.data : null);
            return {
                ...state,
                loginfailed,
            };
        }
    },
    [actionTypes.GET_DRAFT_LIST](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const draftlist = payload.data.data;
            return {
                ...state,
                draftlist,
            };
        }
    },

    // Sign UP
    [actionTypes.APP_SIGNUP](state, { payload }) {
        const signup = typeof payload.response !== 'undefined' ? payload.response.data : payload.data;
        return {
            ...state,
            signup,
        };

    },

    // Send OTP
    [actionTypes.APP_SENDOTP](state, { payload, error }) {
        if (!error) {
            const otp = !payload ? null : payload.data;
            return {
                ...state,
                otp,
            };
        }
    },

    //OTP Login
    // eslint-disable-next-line no-unused-vars
    [actionTypes.APP_VERIFY_OTP](state, { payload, error }) {
        const otplogin = typeof payload.response !== 'undefined' ? payload.response.data : payload.data;
        // const otplogin = !payload ? null : payload.data;
        return {
            ...state,
            otplogin,
        };

    },

    //OTP Login
    // eslint-disable-next-line no-unused-vars
    [actionTypes.APP_REQUEST_CALL](state, { payload, error }) {

        const callrequest = typeof payload.response !== 'undefined' ? payload.response.data : payload.data;
        // const callrequest = !payload ? null : payload.data;
        return {
            ...state,
            callrequest,
        };

    },

    [actionTypes.GET_UNPUBLISHED_LIST](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const unpublishedlist = payload.data.data;
            return {
                ...state,
                unpublishedlist,
            };
        }
    },
    [actionTypes.GET_PUBLISHED_LIST](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const publishedlist = payload.data.data;
            return {
                ...state,
                publishedlist,
            };
        }
    },
    [actionTypes.GET_LIST_OF_TEST](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const listoftests = payload.data.data;
            return {
                ...state,
                listoftests,
            };
        }
    },
    [actionTypes.GET_LIST_OF_EXAMS](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const listofexams = payload.data.data;
            return {
                ...state,
                listofexams,
            };
        }
    },
    [actionTypes.GET_EXAM_LIST](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const examlist = payload.data.data;
            return {
                ...state,
                examlist,
            };
        }
    },
    [actionTypes.MOVE_DRAFT_TO_PUBLISH](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const drafttopublish = payload.data;
            return {
                ...state,
                drafttopublish,
            };
        }
    },
    [actionTypes.MOVE_DRAFT_TO_UNPUBLISHED](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const drafttounpublish = payload.data;
            return {
                ...state,
                drafttounpublish,
            };
        }
    },

    [actionTypes.SCHEDULE_PUBLISH](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const schedulepublish = payload.data;
            return {
                ...state,
                schedulepublish,
            };
        } else if (payload && payload.response) {
            const statusfailed = (payload.response ? payload.response.data.message : null);
            return {
                ...state,
                statusfailed,
            };
        }
    },


    [actionTypes.ADD_NEW_TEST](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const addnewtest = payload.data.data.test;
            return {
                ...state,
                addnewtest,
            };
        } else if (payload && payload.response) {
            const addnewtestfailed = (payload.response ? payload.response.data : null);
            return {
                ...state,
                addnewtestfailed,
            };
        }

    },
    [actionTypes.ADD_NEW_TEST_SERIES](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const addnewtestseries = payload.data.data;
            return {
                ...state,
                addnewtestseries,
            };
        }
    },

    [actionTypes.APP_EXAM_SUBJECT_LIST](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const subjectlists = payload.data.data;
            return {
                ...state,
                subjectlists,
            };
        }
    },



    [actionTypes.GET_LIST_OF_PARENT_TEST](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const listofparenttest = payload.data.data;
            return {
                ...state,
                listofparenttest,
            };
        }
    },
    [actionTypes.APP_UPLOADED_FILES](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const uploadedfiles = payload.data.data;
            return {
                ...state,
                uploadedfiles,
            };
        }
    },
    [actionTypes.ADD_UPLOADED_FILES](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const uploadedfiles = payload.data;
            return {
                ...state,
                uploadedfiles,
            };
        }
    },

    [actionTypes.ADD_TEST_SERIES](state, { payload, error }) {

        if (!error && payload.data.status_code == 200) {
            const testseries = payload.data;
            return {
                ...state,
                testseries,
            };
        }
    },

    [actionTypes.DELETE_TEST_FILE](state, { payload, error }) {

        if (!error && payload.data.status_code == 200) {
            const deletedfile = payload.data;
            return {
                ...state,
                deletedfile,
            };
        }
    },

    [actionTypes.UPDATE_TEST_FILE](state, { payload, error }) {

        if (!error && payload.data.status_code == 200) {
            const updatedfile = payload.data;
            return {
                ...state,
                updatedfile,
            };
        }
    },

    [actionTypes.GET_ALL_REVENUE](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const revenues = payload.data.data;
            return {
                ...state,
                revenues,
            };
        }
    },

    [actionTypes.GET_REVENUE_DETAILS](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const revenuedetails = payload.data.data;
            return {
                ...state,
                revenuedetails,
            };
        }
    },

    [actionTypes.GET_REVENUE_FOR_TEST_SERIES](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const revenueTestseries = payload.data.data;
            return {
                ...state,
                revenueTestseries,
            };
        }
    },
    [actionTypes.GET_ACTIVE_DATA](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const activedata = payload.data.data;
            return {
                ...state,
                activedata,
            };
        }
    },

    [actionTypes.REGISTER_FOR_EXAM](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const registerforexam = payload.data;
            return {
                ...state,
                registerforexam,
            };
        } else if (payload && payload.response) {
            const registerfailed = (payload.response ? payload.response.data.message : null);
            return {
                ...state,
                registerfailed,
            };
        }
    },

    [actionTypes.SEND_OTP_WSIGNUP](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const callrqstdata = payload.data.data;
            return {
                ...state,
                callrqstdata,
            };
        }
    },

    [actionTypes.SUBMIT_QUERY](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const submitquery = payload.data;
            return {
                ...state,
                submitquery,
            };
        }
    },
    [actionTypes.FORGOT_PASSWORD](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const newpassword = payload.data;
            return {
                ...state,
                newpassword,
            };
        } else if (payload && payload.response) {
            const passwordfailed = (payload.response ? payload.response.data.message : null);
            return {
                ...state,
                passwordfailed,
            };
        }
    },

    [actionTypes.CHANGE_PASSWORD](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const changepassword = payload.data;
            return {
                ...state,
                changepassword,
            };
        }else if (payload && payload.response) {
            const changepasswordfailed = (payload.response ? payload.response.data.message : null);

            return {
                ...state,
                changepasswordfailed,
            };
        }
    },

    [actionTypes.APP_SUBMIT_TEST](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const submittest = payload.data;
            return {
                ...state,
                submittest,
            };
        }
    },

    [actionTypes.DEVELOPED_CONTENT](state, { payload, error }) {
        const developedcontent = typeof payload.response !== 'undefined' ? payload.response.data : payload.data;
        return {
            ...state,
            developedcontent,
        };

    },

    [actionTypes.CREATE_ISSUE](state, { payload, error }) {
        const createissue = typeof payload.response !== 'undefined' ? payload.response.data : payload.data;
        return {
            ...state,
            createissue,
        };

    },

    [actionTypes.ADD_COMMENT](state, { payload, error }) {
        const addcomment = typeof payload.response !== 'undefined' ? payload.response.data : payload.data;
        return {
            ...state,
            addcomment,
        };
    },
    [actionTypes.RESOLVED](state, { payload, error }) {
        const resolved = typeof payload.response !== 'undefined' ? payload.response.data : payload.data;
        return {
            ...state,
            resolved,
        };
    },


    [actionTypes.FINANCIAL_DETAILS](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const submitfinancedetails = payload.data;
            return {
                ...state,
                submitfinancedetails,
            };
        }
    },

    [actionTypes.GET_FINANCIAL_DETAILS](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const getfinancedetails = payload.data;
            return {
                ...state,
                getfinancedetails,
            };
        }
    },

    [actionTypes.GET_PROFFESSIONAL_DETAILS](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const getproffesionaldetails = payload.data;
            return {
                ...state,
                getproffesionaldetails,
            };
        }
    },

    [actionTypes.PROFFESSIONAL_DETAILS](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const submitproffesionaldetails = payload.data;
            return {
                ...state,
                submitproffesionaldetails,
            };
        }
    },

    [actionTypes.PROFILE_DETAILS](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const submitprofiledetails = payload.data;
            return {
                ...state,
                submitprofiledetails,
            };
        }
        else if (payload && payload.response) {
            const numberMessage = (payload.response ? payload.response.data.message : null);
            return {
                ...state,
                numberMessage,
            };
        }
    },

    [actionTypes.GET_PROFILE_DETAILS](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const getprofiledetails = payload.data;
            return {
                ...state,
                getprofiledetails,
            };
        }
    },

    [actionTypes.FETCH_STATES_CITIES](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const statedetails = payload.data;
            return {
                ...state,
                statedetails,
            };
        }
    },
    [actionTypes.EDIT_PHONE_NUMBER](state, { payload, error }) {
        if (!error && payload.data.status_code == 200) {
            const newphonenumber = payload.data;
            return {
                ...state,
                newphonenumber,
            };
        }
    },





}, defaultState);
