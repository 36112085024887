import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './revenue.scss';
import globestyle from '../../sass/global.scss';
import actions from '../../redux/actions/app.js';
import cx from 'classnames';
import _ from 'lodash';
import Moment from 'react-moment';

let test_series_id = 0;
let exam_id = 0;
class testDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            revenueTestseries: null,
            //testId:this.props.location.state.test_id
        };
        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        if(this.props.activeData && this.props.activeData.activeExamId) {
            if(!test_series_id) {
                test_series_id = this.props.location.pathname.replace(this.props.match.url+'/','');
            }
            exam_id = this.props.activeData.activeExamId;
            const data = { examid: exam_id, testseriesid: test_series_id,mock:'true' };


            this.props.getRevenuestestseries(data);
        }

    }

    componentDidUpdate(nextProps) {
        if (this.props.revenueTestseries !== nextProps.revenueTestseries && this.props.revenueTestseries) {
            this.setState({ revenueTestseries: this.props.revenueTestseries });
        }
        if(this.props.activeData!=nextProps.activeData && this.props.activeData && this.props.activeData.activeExamId && exam_id==0 ) {
            if(!test_series_id) {
                test_series_id = this.props.location.pathname.replace(this.props.match.url+'/','');
            }
            exam_id = this.props.activeData.activeExamId;
            const data = { examid: exam_id, testseriesid: test_series_id,mock:'true' };

            this.props.getRevenuestestseries(data);
        }

    }
    componentWillUnmount() {
        test_series_id = 0;
    }
    handleBack() {
        this.props.history.push('/revenue/exam-test-series');
    }
    render() {
        const examtitle=(this.props.location.state? this.props.location.state.examTitle:'');
        const examdate=(this.props.location.state?this.props.location.state.examDate:'');
        const { revenueTestseries } = this.state;
        const total_revenue = (revenueTestseries ? revenueTestseries.total_revenue : null);
        return (
            <div className={cx(styles.revenue)}>
                <nav aria-label='breadcrumb'>
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to='/test-series'>Home</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to='/revenue'>Revenue</Link></li>
                    </ol>
                </nav>
                <div className={cx('clearfix')}></div>
                <div className={cx(styles.maincontent)}>
                    <div className={cx('row')}>
                        <div className={cx('col')} >
                            <div className={cx('float-left')}>
                                <h1 className={cx(styles.page_title)}> <Link to='#'><i onClick={this.handleBack} className={cx('fa fa-arrow-left')}></i></Link> {examtitle?examtitle:''}<span>(Published on {''}<Moment format="DD-MM-YYYY">{examdate?examdate:''}</Moment>)</span></h1>
                            </div>
                            <div className={cx('float-right')}>
                                <h1 className={cx(styles.amount)}> &#x20b9; 780</h1>
                            </div>
                        </div>
                    </div>
                    <div className={cx('row justify-content-md-center')}>
                        <div className={cx('col col-lg-11')} >
                            <div>
                                <fieldset className={cx(styles.scheduler_border)}>
                                    <legend className={cx(styles.scheduler_border)}>TOTAL (In INR)</legend>
                                    <div className={cx('row')}>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>REVENUE ={total_revenue ? total_revenue.total.toLocaleString(undefined, {maximumFractionDigits:2}) : null}</strong>
                                        </div>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>TRANSFERRED = {total_revenue ? total_revenue.transferred.toLocaleString(undefined, {maximumFractionDigits:2}) : null}</strong>
                                        </div>
                                        <div className={cx('col')}>
                                            <strong className={cx(styles.top_revenue_text)}>PENDING = {total_revenue ? total_revenue.pending.toLocaleString(undefined, {maximumFractionDigits:2}) : null}</strong>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.revenue_summary_container)}>
                        {revenueTestseries && revenueTestseries.orders.length > 0 ?
                            <div>
                                <div className={cx('row', styles.revenue_summary_header)}>
                                    <div className={cx('col-3')}>
                                        <p>ORDER ID</p>
                                    </div>
                                    <div className={cx('col')}>
                                        OFFER
                                    </div>
                                    <div className={cx('col')}>
                                        REVENUE <span>(INR)</span>
                                    </div>
                                    <div className={cx('col')}>
                                        CANCELLATION PERIOD
                                    </div>
                                    <div className={cx('col')}>
                                        TRANSFERRED <span>(INR)</span>
                                    </div>
                                    <div className={cx('col')}>
                                        PENDING <span>(INR)</span>
                                    </div>
                                </div>
                                {_.map(revenueTestseries.orders.length > 0 && revenueTestseries.orders, (order, i) => {
                                    return (
                                        <div className={cx('row', styles.revenue_summary_body)} key={i}>
                                            <div className={cx('col-3')}>
                                                {order.unique_id}
                                            </div>
                                            <div className={cx('col')}>
                                                Discount {order.discount} %
                                            </div>
                                            <div className={cx('col')}>
                                                {parseFloat(order.revenue).toLocaleString(undefined, {maximumFractionDigits:2})}
                                            </div>
                                            <div className={cx('col')}>
                                                {order.cancellation_period == true ? <span>Yes</span> : <span>No</span>}
                                            </div>

                                            <div className={cx('col')}>
                                                {parseFloat(order.transferred).toLocaleString(undefined, {maximumFractionDigits:2})}
                                            </div>
                                            <div className={cx('col')}>
                                                {parseFloat(order.pending).toLocaleString(undefined, {maximumFractionDigits:2})}
                                            </div>
                                        </div>
                                    );
                                })
                                }

                            </div>
                            : null
                        }
                        {revenueTestseries && revenueTestseries.orders.length == 0 ? <div className={cx(globestyle.norecords)}>No records found</div> : null}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        revenueTestseries: state.app.revenueTestseries,
        activeData: (state.app.activedata ? state.app.activedata : null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRevenuestestseries: (data) => dispatch(actions.getRevenuefortestseries(data)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(testDetail);
