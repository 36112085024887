import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './testseries.scss';
import actions from '../../redux/actions/app.js';
import cx from 'classnames';
import _ from 'lodash';
import Moment from 'react-moment';
import Schedulepublishbox from '../modalbox/schedulepublish';
import globestyle from '../../sass/global.scss';
import { Modal, ModalBody } from 'reactstrap';

class TestSeries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: false,
            examId: 0,
            popupkey :'',
            showmodal: false,
            exam_id : '',
            test_series_id : ''
        };
        this.movefiles = this.movefiles.bind(this);
        this.toggle = this.toggle.bind(this);
        this.drafttopublish = this.drafttopublish.bind(this);
        this.drafttounpublish = this.drafttounpublish.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    componentWillMount() {
        if (this.props.displayDraftlist && this.props.displayDraftlist.test_series.length) {
            this.setState({
                'draftlist': this.props.displayDraftlist.test_series,
            });
        }

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        if(this.props.activeData && this.props.activeData.activeExamId>0) {
            const draftData = { status: 'draft', exam: this.props.activeData.activeExamId };
            this.props.onDraft(draftData);
        }
    }

    componentDidUpdate(nextProps) {
        if (this.props.displayDraftlist!=nextProps.displayDraftlist && this.props.displayDraftlist) {
            if(this.props.displayDraftlist.test_series){
                this.setState({
                    'draftlist': this.props.displayDraftlist.test_series,
                });
            }
        }
        if (this.props.savedTestSeries !== nextProps.savedTestSeries && this.props.savedTestSeries) {
            this.refreshDraftData();
            this.toggle();
        }
        if (this.props.activeData != nextProps.activeData && this.props.activeData) {
            if (this.props.activeData.activeExamId > 0) {
                const draftData = { status: 'draft', exam: this.props.activeData.activeExamId };
                this.props.onDraft(draftData);
                this.props.subjectList({ examid: this.props.activeData.activeExamId }); //geting subject lists
            }
        }
        if(this.props.drafttopublish !== nextProps.drafttopublish && this.props.drafttopublish && this.props.drafttopublish.status_code == 200) {
            if(this.props.activeData && this.props.activeData.activeExamId>0) {
                const draftData = { status: 'draft', exam: this.props.activeData.activeExamId };
                this.setState({
                    details: false,

                });
                this.props.onDraft(draftData);
            }
        }
        if(this.props.drafttounpublish !== nextProps.drafttounpublish && this.props.drafttounpublish && this.props.drafttounpublish.status_code == 200) {
            if(this.props.activeData && this.props.activeData.activeExamId>0) {
                const draftData = { status: 'draft', exam: this.props.activeData.activeExamId };
                this.setState({
                    details: false,

                });
                this.props.onDraft(draftData);
            }
        }
        if(this.props.schedulepublishdata !== nextProps.schedulepublishdata && this.props.schedulepublishdata.data && this.props.schedulepublishdata.status_code == 200) {
            this.setState({
                details: false
            });
            if(this.props.activeData && this.props.activeData.activeExamId>0) {
                const draftData = { status: 'draft', exam: this.props.activeData.activeExamId };
                this.props.onDraft(draftData);
            }
        }


    }
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));

    }


    refreshDraftData() {
        if(this.props.activeData && this.props.activeData.activeExamId>0) {
            const draftData = { status: 'draft', exam: this.props.activeData.activeExamId };
            this.props.onDraft(draftData);
        }
    }

    movefiles(i) {
        this.setState({
            details: !this.state.details,
            popupkey : i
        });
    }
    drafttopublish(params) {
        const paramsData = {exam:params.exam,test_series_id:params.test_series_id,status:'published'};
        this.props.ondrafttopublishdata(paramsData);

    }
    drafttounpublish(params) {
        const paramsData = {exam:params.exam,test_series_id:params.test_series_id,status:'unpublished'};
        this.props.ondrafttounpublishdata(paramsData);
    }
    publish(draftparams){
        this.setState({
            showmodal : true,
            exam_id : draftparams.exam,
            test_series_id : draftparams.test_series_id
        });
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if(this.state.details){
                this.setState({
                    details: false,
                });
            }

        }
    }


    render() {
        const { draftlist, test_series_id, exam_id} = this.state;
        return (
            <div>
                {draftlist && draftlist.length > 0 ?
                    <div>
                        {
                            _.map(draftlist.length > 0 && draftlist, (dlist, i) => {
                                const subjects = (dlist.subjects.length ? dlist.subjects : []);
                                const draftparams = {exam: dlist.exam,
                                    test_series_id: dlist.id,

                                };
                                let subjectname = '';
                                if(subjects && subjects.length>0) {
                                    let subjectlist = [];
                                    _.map(subjects, (subj) => {
                                        subjectlist.push(subj.title);
                                    });
                                    subjectname = subjectlist.join(', ');
                                }
                                return (
                                    <div className={cx(styles.table_view)} key={i}>
                                        <div className={cx(styles.liststyle)}>
                                            <div className={cx('col-md-12 pl-0')}>
                                                <Link to={{ pathname: '/test-series/upload_test/' + dlist.id, state: { title: dlist.title, examid: dlist.exam } }} className={cx(styles.flexstyle)} >
                                                    <div className={cx('col-md-5 pl-0')}>
                                                        <p>
                                                            <b>{dlist.title}</b>
                                                            <span className={cx(styles.created_date)}>created on <Moment format="DD-MM-YYYY">{dlist.created_on}</Moment></span>
                                                        </p>
                                                    </div>
                                                    <div className={cx('col-md-2')}>
                                                        <p className={cx(styles.content_color)}>

                                                            {dlist.tests_count} test
                                                        </p>
                                                    </div>
                                                    <div className={cx('col-md-3')}>
                                                        <p className={cx(styles.content_color)}>{subjectname ? subjectname : ''}</p>
                                                    </div>
                                                    <div className={cx('col-md-2')}>

                                                        {dlist.progress_status == 'Published' ? <p className={cx(styles.publish_text)} onClick={this.movefiles}>ready to publish</p> : <p className={cx(styles.development_text)} onClick={this.movefiles}>{dlist.progress_status}</p>}

                                                    </div>
                                                </Link>
                                            </div>
                                            <div>
                                                <i onClick={() => this.movefiles(i)} className={cx(styles.details_icon, 'fa fa-ellipsis-v')}></i>
                                            </div>
                                            {this.state.details == true && this.state.popupkey == i ?
                                                <div className={cx(styles.popupbox)} ref={this.setWrapperRef}>
                                                    <ul>
                                                        <li onClick={() => this.drafttopublish(draftparams)}>Publish</li>
                                                        <li onClick={() => this.publish(draftparams)} data-toggle='modal' data-target='#schedule-box'>Schedule publish</li>
                                                        <li onClick={() => this.drafttounpublish(draftparams)}>Move to unpublished</li>
                                                    </ul>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>

                                );
                            })
                        }
                    </div> :
                    null


                }
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalBody className={globestyle.messageblock}>
                        <div>
                            <a className={cx('float_right', globestyle.close_button)} onClick={this.toggle}>
                                <i className={cx('far fa-times-circle')}></i>
                            </a>
                        </div>
                        <div className={cx(globestyle.message)}>{'Your information saved successfully!!'}</div>
                    </ModalBody>
                </Modal>
                <div>
                    {this.state.showmodal ? <Schedulepublishbox test_series_id={test_series_id} examId={exam_id}  />:null}
                </div>
                {draftlist && draftlist.length==0 ? <div className={cx(globestyle.norecords)}>No records found</div>: null }
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        displayDraftlist: (state.app.draftlist ? state.app.draftlist : null),
        savedTestSeries: (state.app.testseries ? state.app.testseries : null),
        activeData: (state.app.activedata ? state.app.activedata : null),
        drafttopublish : (state.app.drafttopublish ? state.app.drafttopublish : null),
        drafttounpublish : (state.app.drafttounpublish ? state.app.drafttounpublish : null),
        schedulepublishdata:(state.app.schedulepublish ? state.app.schedulepublish: null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onDraft: (draftData) => dispatch(actions.getDraftlist(draftData)),
        ondrafttopublishdata: (paramsData) => dispatch(actions.getDrafttopublish(paramsData)),
        ondrafttounpublishdata: (paramsData) => dispatch(actions.getDrafttounpublish(paramsData)),
        subjectList: (examid) => dispatch(actions.getSubjectList(examid)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestSeries);
