import React from 'react';
import { connect } from 'react-redux';
import styles from './profiledetails.scss';
import actions from '../../redux/actions/app.js';
import cx from 'classnames';
import globestyle from '../../sass/global.scss';
import validation from '../../validations';
import { Modal, ModalBody } from 'reactstrap';
import _ from 'lodash';

class Proffessionaldetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            facultyType: [],
            Bio: { isValid: false, message: '' },
            experience: { isValid: false, message: '', index: '' },
            degree: { isValid: false, message: '' },
            course: { isValid: false, message: '' },
            year: { isValid: false, message: '' },
            others: [],
            education: [],
            displayMenu: false,
            displayMenuIndex: '',
            dropdownno: '',
            faculty: ''
        };
        this.toggle = this.toggle.bind(this);
        this.handlesubmit = this.handlesubmit.bind(this);
        this.handleExperience = this.handleExperience.bind(this);
        this.handleEducation = this.handleEducation.bind(this);
        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    }
    componentDidMount() {
        this.props.onRef(this);
        this.props.getProffesionaldetails();

    }
    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    componentDidUpdate(nextProps) {
        if (this.props.proffesionalDetails !== nextProps.proffesionalDetails && this.props.proffesionalDetails) {
            this.setState({ facultyType: this.props.proffesionalDetails.data.faculty_type_options });
        }
        if (this.props.proffesionalDetails !== nextProps.proffesionalDetails && this.props.proffesionalDetails) {
            this.setState({ bioofteacher: this.props.proffesionalDetails.data.professional_details.bio, faculty: this.props.proffesionalDetails.data.professional_details.faculty_type, faculty_type: this.props.proffesionalDetails.data.faculty_type_options[this.props.proffesionalDetails.data.professional_details.faculty_type] });
        }
        if (this.props.proffesionalDetails !== nextProps.proffesionalDetails && this.props.proffesionalDetails.data.professional_details) {
            if (this.props.proffesionalDetails.data.professional_details.education_details.length == undefined) {
                this.setState({
                    education: [{}]
                });

            } else {
                this.setState({
                    education: this.props.proffesionalDetails.data.professional_details.education_details
                });
            }
        }
        if (this.props.proffesionalDetails !== nextProps.proffesionalDetails && this.props.proffesionalDetails.data.professional_details) {
            if (this.props.proffesionalDetails.data.professional_details.teaching_experiences.length == undefined) {
                this.setState({
                    others: [{}]
                });

            } else {
                this.setState({
                    others: this.props.proffesionalDetails.data.professional_details.teaching_experiences
                });
            }
        }
    }
    handlesubmit() {
        const Bio = this.refs.bio.value;
        let isValidexperience = '';
        const facultytype = this.state.faculty;
        const isValidBio = validation('query', Bio);
        this.state.others.map((u) => {
            if (u.experience == '') {
                isValidexperience = validation('query', u.experience);
            }
        });
        if (isValidBio.valid && (isValidexperience.valid == true || isValidexperience.valid == undefined)) {
            const data = { Bio: Bio, education_details: this.state.education, teaching_experiences: this.state.others, faculty_type: facultytype };
            this.props.saveProfdetails(data);
            this.toggle();
        } else {
            this.setState({
                Bio: { isValid: isValidBio.valid, message: isValidBio.message },
                experience: { isValid: isValidexperience.valid, message: isValidexperience.message }
            });
        }
    }
    addExperience() {
        let lastIndex = _.lastIndexOf(this.state.others);
        let others = [...this.state.others];
        others[lastIndex] = { 'duration': '', 'experience': '' };
        this.setState({
            others
        });
    }
    removeExperience(index) {
        this.state.others.splice(index, 1);
        this.setState({ others: this.state.others });
    }

    addEducation() {
        this.setState({ education: [...this.state.education, ''] });
    }

    removeEducation(index) {
        this.state.education.splice(index, 1);
        this.setState({ education: this.state.education });
    }
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    handleExperience(i, value) {
        let others = [...this.state.others];
        others[i] = { 'duration': value, 'experience': this.refs['experience' + i].value };
        this.setState({
            others
        });
    }

    handleEducation(i) {
        let education = [...this.state.education];
        education[i] = { 'year': this.refs['year' + i].value, 'course': this.refs['course' + i].value, 'degree': this.refs['degree' + i].value };
        this.setState({
            education
        });
    }

    showDropdownMenu(index = null, dropdownno) {
        this.setState({ displayMenu: true, disaplyMenuIndex: index, dropdownno: dropdownno }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });

    }
    onTypeChange = (value) => {
        this.setState({
            faculty: value,
            faculty_type: this.state.facultyType[value]
        });
    }

    render() {
        const { facultyType, faculty_type, Bio, bioofteacher, experience, disaplyMenuIndex, dropdownno } = this.state;
        return (
            <div className={cx(styles.refer_index)}>
                <form>
                    <div className={cx('row')}>
                        <div
                            className={cx(
                                'col-md-12 col-sm-12 col-xs-12'
                            )}
                        >
                            <div className={cx('row mt-4')}>
                                <div className={cx('col-md-11 col-sm-12 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <fieldset className={cx(globestyle.fset, globestyle.lessradius)}>
                                            <legend className={cx(globestyle.legend, globestyle.small)}>Bio</legend>
                                            <textarea placeholder='Hey there....'
                                                ref='bio'
                                                value={bioofteacher ? bioofteacher : ''}
                                                onChange={(event) => this.setState({ bioofteacher: event.target.value })}
                                                className={cx('form-control')}
                                                rows='4' />
                                        </fieldset>
                                        <span className={!Bio.isValid ? 'text-danger small font-italic' : 'd-none'}>{!Bio.isValid ? Bio.message : ''}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className={cx(styles.textdetails)}>
                            Education
                        </p>
                        {this.state.education.map((u, index) => {
                            return (
                                <div key={index} className={cx('row')}>
                                    <div
                                        className={cx(
                                            'col-md-3 col-sm-4 col-xs-12'
                                        )}
                                    >
                                        <div className={cx('form-group')}>
                                            <fieldset className={cx(globestyle.fset)}>
                                                <legend className={cx(globestyle.legend, globestyle.small)}>Degree</legend>
                                                <input
                                                    type='text'
                                                    ref={`degree${index}`}
                                                    className={cx(globestyle.small, 'form-control')}
                                                    onChange={() => this.handleEducation(index)}
                                                    value={u.degree}
                                                />
                                            </fieldset>
                                        </div>
                                    </div>

                                    <div
                                        className={cx(
                                            'col-md-3 col-sm-4 col-xs-12'
                                        )}
                                    >
                                        <div className={cx('form-group')}>
                                            <fieldset className={cx(globestyle.fset)}>
                                                <legend className={cx(globestyle.legend, globestyle.small)}>Course</legend>
                                                <input
                                                    ref={`course${index}`}
                                                    type='text'
                                                    onChange={() => this.handleEducation(index)}
                                                    className={cx(globestyle.small, 'form-control')}
                                                    value={u.course}
                                                />
                                            </fieldset>
                                        </div>

                                    </div>
                                    <div
                                        className={cx(
                                            'col-md-3 col-sm-4 col-xs-12'
                                        )}
                                    >
                                        <div className={cx('form-group')}>
                                            <fieldset className={cx(globestyle.fset)}>
                                                <legend className={cx(globestyle.legend, globestyle.small)}>Year</legend>
                                                <input
                                                    type='text'
                                                    ref={`year${index}`}
                                                    onChange={() => this.handleEducation(index)}
                                                    className={cx(globestyle.small, 'form-control')}
                                                    value={u.year}
                                                />
                                            </fieldset>
                                        </div>

                                    </div>
                                    {index !== 0 ?
                                        <div className={cx(styles.add_more, 'mt-3')}>
                                            <a
                                                onClick={() => this.removeEducation(index)}
                                                className={cx(styles.add_more_btn)}
                                            >
                                                <i className={cx('fa fa-minus fa-1x')} />
                                                Remove education
                                            </a>
                                        </div> : null}
                                </div>

                            );
                        })}
                        <div className={cx(styles.add_more)}>
                            <a
                                onClick={(e) => this.addEducation(e)}
                                className={cx(styles.add_more_btn)}
                            >
                                <i className={cx('fa fa-plus fa-1x')} />
                                Add more education
                            </a>
                        </div>
                    </div>
                    <div className={cx('row')}>
                        <div
                            className={cx(
                                'col-md-3 col-sm-4 col-xs-12'
                            )}
                        >
                            <div>
                                <p className={cx(styles.otherdetails)}>
                                    Faculty Type
                                </p>
                            </div>

                            <div className={cx('form-group')}>
                                <fieldset className={cx(globestyle.fset, globestyle.fsetwl)}>
                                    <div className={cx(globestyle.selectdiv)}>
                                        <div className='dropvalue' onClick={() => this.showDropdownMenu('', 1)}>{faculty_type ? faculty_type : 'SELECT FACULTY TYPE'}</div>
                                        {this.state.displayMenu && dropdownno == 1 ?
                                            <ul>
                                                {
                                                    _.map(facultyType, (facultyType, index) => {
                                                        return (
                                                            <li onClick={() => this.onTypeChange(index)} key={index}>{facultyType}</li>
                                                        );
                                                    })
                                                }
                                            </ul> : null
                                        }
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p className={cx(styles.otherdetails)}>
                                Teaching Experience

                            </p>
                        </div>
                        {
                            this.state.others.map((other, index) => {
                                return (
                                    <div key={index} className={cx('row')}>
                                        <div
                                            className={cx(
                                                'col-md-3 col-sm-4 col-xs-12'
                                            )}
                                        >
                                            <div className={cx('form-group mt-2')}>
                                                <fieldset className={cx(globestyle.fset, globestyle.fsetwtlegend)}>
                                                    <input
                                                        ref={`experience${index}`}
                                                        type='text'
                                                        className={cx('form-control')}
                                                        onChange={() => this.handleExperience(index, other.duration)}
                                                        value={other.experience}
                                                    />
                                                </fieldset>
                                                <span className={!experience.isValid ? 'text-danger small font-italic' : 'd-none'}>{!experience.isValid && other.experience == '' ? experience.message : ''}</span>
                                            </div>
                                        </div>
                                        <div
                                            className={cx(
                                                'col-md-3 col-sm-4 col-xs-12'
                                            )}
                                        >
                                            <div className={cx('form-group')}>
                                                <fieldset className={cx(globestyle.fset)}>
                                                    <legend className={cx(globestyle.legend, globestyle.small)}>Duration</legend>
                                                    <div className={cx(globestyle.selectdiv)}>
                                                        <div className='dropvalue' onClick={() => this.showDropdownMenu(index, 2)}>{other.duration ? other.duration : 'Duration'}</div>
                                                        {this.state.displayMenu && index == disaplyMenuIndex && dropdownno == 2 ?
                                                            <ul>
                                                                {(() => {
                                                                    const optionInput = [];
                                                                    for (let i = 1; i <= 25; i++) {
                                                                        optionInput.push(
                                                                            <li onClick={() => this.handleExperience(index, i)} key={i}>{i}</li>
                                                                        );
                                                                    }
                                                                    return optionInput;
                                                                })()}
                                                            </ul> : null
                                                        }
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        {index !== 0 ?
                                            <div className={cx(styles.add_more, 'mt-3')}>
                                                <a
                                                    onClick={() => this.removeExperience(index)}
                                                    className={cx(styles.add_more_btn)}
                                                >
                                                    <i className={cx('fa fa-minus fa-1x')} />
                                                    Remove
                                                </a>
                                            </div> : null}
                                    </div>

                                );

                            })
                        }

                        <div className={cx(styles.add_more)}>
                            <a
                                onClick={(e) => this.addExperience(e)}
                                className={cx(styles.add_more_btn)}
                            >
                                <i className={cx('fa fa-plus fa-1x')} />
                                Add more
                            </a>
                        </div>
                    </div>
                </form>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalBody className={globestyle.messageblock}>
                        <div>
                            <a className={cx('float_right', globestyle.close_button)} onClick={this.toggle}>
                                <i className={cx('far fa-times-circle')}></i>
                            </a>
                        </div>
                        <div className={cx(globestyle.message)}>{'Your information saved successfully!!'}</div>
                    </ModalBody>
                </Modal>

            </div>

        );
    }
}


function mapStateToProps(state) {
    return {
        proffesionalDetails: state.app.getproffesionaldetails,
        savedetails: state.app.submitproffesionaldetails,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        getProffesionaldetails: () => dispatch(actions.getProffesionaldetails()),
        saveProfdetails: (data) => dispatch(actions.editProffesionaldetails(data))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Proffessionaldetails);


