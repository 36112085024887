import { createStore, applyMiddleware } from 'redux';
import reduxPromise from 'redux-promise';

import reducers from './reducers';

export default function getStore(preloadedState=null) {
    let store;
    if (preloadedState) {
        store = createStore(
            reducers,
            preloadedState,
            global.window.__REDUX_DEVTOOLS_EXTENSION__ && global.window.__REDUX_DEVTOOLS_EXTENSION__(),
            applyMiddleware(reduxPromise)
        );
    } else {
        store = createStore(
            reducers,
            global.window.__REDUX_DEVTOOLS_EXTENSION__ && global.window.__REDUX_DEVTOOLS_EXTENSION__(),
            applyMiddleware(reduxPromise)
        );
    }

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextReducer = require('./reducers/index').default;
            store.replaceReducer(nextReducer);
        });
    }
    return store;
}
