import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.scss';
import cx from 'classnames';
import _ from 'lodash';
import EligibilityModal from './../EligibilityModal';
import PopUpModal from './../PopUpModal';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';


// let activePopup = null;

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            activePopup: '',
            competetiveExam: [],
        };
        this.showPopup = this.showPopup.bind(this);
    }

    handleNavTabClick = event => {
        event.stopPropagation();
        this.ExamTabContent();
    };

    showPopup = params => {
        this.setState({
            activePopup: params,
        });
        this.PopUpModal.toggle();
    };
    componentWillMount() {
        if (this.props.displayExamlist && this.props.displayExamlist.exams.length) {
            this.setState({
                'competetiveExam': this.props.displayExamlist.exams,
            });
        }
    }

    componentDidMount() {
        const examlistData = { status1: 'draft', status2: 'active' };
        this.props.onFetchexamlist(examlistData);
    }
    componentDidUpdate(nextProps) {
        if (this.props.displayExamlist!=nextProps.displayExamlist && this.props.displayExamlist && this.props.displayExamlist.exams.length) {
            this.setState({
                'competetiveExam': this.props.displayExamlist.exams,
            });
        }

    }

    render() {
        const ExamTabContent = () => {
            return (
                <div className='row'>
                    {
                        _.map(this.state.competetiveExam, (exam) => {
                            return (
                                <div key={exam.id} className={cx('col-sm-12 col-md-4', styles.examListContainer)}>
                                    <div className='d-flex p-5'>
                                        <div className={styles.examListContainer_image}></div>
                                        <div className={cx(styles.examListContainer_description, 'd-flex justify-content-around flex-column')}>
                                            <p>{exam.title}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    <div className={cx('text-primary', styles.examList)}>See all 23 exams</div>
                </div>
            );
        };

        const { activePopup } = this.state;
        return (

            <div className={styles.homePage}>
                <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/Path+88.svg' alt=''
                    className={cx('img img-responsive', styles.mainBackground)} />
                <div className={cx('container', styles.homepage_section)}>
                    <div className={cx(styles.overviewSection, 'row')}>
                        <div className='col-sm-12 col-md-8 text-white text-left d-flex flex-column justify-content-center'>
                            <div className={cx(styles.overviewSection_heading, 'text-left')}>
                                Sell your Test Series
                            </div>
                            <div className={cx(styles.overviewSection_description, 'font-weight-bold')}>
                                <p className='font-weight-bold'>
                                    Why stay locally, when students are scattered globally?</p>
                                <p>
                                    ExamTreasury Provides an online platform to connect with students preparing for Various exams.</p>
                            </div>
                            <button className={cx('btn', 'et-btn', 'text-capitalize', 'text-center', 'text-white')} onClick={() => this.showPopup('register')}>join now</button>
                        </div>
                        <div className='col-sm-12 col-md-4'>
                            <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/home+page+image.svg' alt='' className={styles.overviewSection_image} />
                        </div>
                    </div>
                </div>
                <div className={cx('container', styles.homepage_section)}>
                    <div className={cx(styles.joinUsSection, 'row')}>
                        <div className={cx(styles.section_heading, 'col-sm-12', 'text-center', 'text-black')}>
                            Who can join us
                        </div>
                        <div className='col-md-6 col-sm-12 m-auto'>
                            <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/Group+239.svg' alt='' className={cx(styles.joinUsSection_image, 'd-block', 'm-auto')} />
                        </div>

                        <div className='col-md-6 col-sm-12 text-black d-flex flex-column justify-content-between'>
                            <div className={cx(styles.joinUsSection_description, 'font-weight-bold')}>
                                <p className='font-weight-bold'>Individual Teachers</p>
                                <p className='font-weight-bold'>Coaching Institute and Teachers</p>
                                <p className='font-weight-bold'>School and College Faculties</p>
                                <p className='font-weight-bold'>Student Faculties</p>
                            </div>
                            <button className={cx('btn', 'et-btn', 'text-capitalize', 'text-center', 'text-white')} onClick={() => this.showPopup('register')}>join now </button>

                        </div>

                    </div>
                    <div className={cx(styles.joinUsSection, 'row')}>
                        <div className='col-md-6 col-sm-12 m-auto'>
                            <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/Group+240.svg' alt='' className={cx(styles.joinUsSection_image, 'd-block', 'm-auto')} />
                        </div>
                        <div className='col-md-6 col-sm-12 text-black '>
                            <div className={cx(styles.joinUsSection_description)}>
                                Are you in confusion about your eligibility ?
                                <p>
                                    Find who like you are teaching with us. If still has confusion or want more information? request a call, our counseller will assist you for all your query.
                                </p>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <button className={cx('btn', 'et-btn', 'et-btn__transparent', 'text-capitalize', 'text-center', 'ml-5')} data-toggle="modal" data-target="#eligibility-modal">Check you eligibility</button>
                                <button className={cx('btn', 'et-btn', 'et-btn__transparent', 'text-capitalize', 'text-center', 'mr-5')} onClick={() => this.showPopup('requestcall')}>Request a call from us</button>
                            </div>
                            <EligibilityModal />
                        </div>
                    </div>
                </div>
                {/* Exam Tabs */}
                <div className='container'>
                    <div className={cx(styles.section_heading, 'col-sm-12', 'text-center', 'text-black')}>
                        Exams
                    </div>

                    <ul className={cx('nav nav-tabs nav-fill', styles.examTabList)} id="myTab" role="tablist">
                        <li className="nav-item">
                            <Link to="#" className="nav-link active" id="competetive-tab" onClick={this.handleNavTabClick}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="#" className="nav-link" id="boards-tab" onClick={this.handleNavTabClick}>Profile</Link>
                        </li>
                    </ul>
                    <ExamTabContent />
                </div>

                <div className={cx(styles.why_join_us, 'container')}>
                    <div className={cx(styles.section_heading, 'text-center', 'text-black')}>
                        Why join us
                    </div>
                    <div className={cx(styles.why_join_us__section, 'row')}>
                        <div className='col-sm-12 col-md-3'>
                            <div className={styles.why_join_us__content}>
                                <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/Group+239.svg' alt='' className={styles.why_join_us__content_image} />
                            </div>
                            <p className='text-center my-5'>Pan India coverage</p>
                        </div>

                        <div className='col-sm-12 col-md-3'>
                            <div className={styles.why_join_us__content}>
                                <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/undraw_laravel_and_vue_59tp.svg' alt='' className={styles.why_join_us__content_image} />
                            </div>
                            <p className='text-center my-5'>Work remotely</p>
                        </div>

                        <div className='col-sm-12 col-md-3'>
                            <div className={styles.why_join_us__content}>
                                <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/undraw_finance_0bdk.svg' alt='' className={styles.why_join_us__content_image} />
                            </div>
                            <p className='text-center my-5'>Transparent revenue sharing</p>
                        </div>

                        <div className='col-sm-12 col-md-3'>
                            <div className={styles.why_join_us__content}>
                                <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/undraw_make_it_rain_iwk4.svg' alt='' className={styles.why_join_us__content_image} />
                            </div>
                            <p className='text-center my-5'>Multifold earning</p>
                        </div>
                    </div>

                    <button className='btn et-btn text-capitalize text-center text-white d-block m-auto my-5' onClick={() => this.showPopup('register')}>join now</button>
                </div>

                <div className={cx(styles.et_speaks, 'container')}>
                    <div className={cx(styles.section_heading, 'text-center', 'text-black')}>
                        What ExamTreasury family speaks
                    </div>

                    <div className={cx(styles.et_speaks__section, 'row')}>
                        <div className='col-sm-12 col-md-3'>
                            <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=0"></iframe>
                        </div>

                        <div className='col-sm-12 col-md-3'>
                            <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=0"></iframe>
                        </div>

                        <div className='col-sm-12 col-md-3'>
                            <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=0"></iframe>
                        </div>

                        <div className='col-sm-12 col-md-3'>
                            <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=0"></iframe>
                        </div>
                    </div>
                </div>

                <div className={cx(styles.network, 'container')}>
                    <div className={cx(styles.section_heading, 'text-center', 'text-black')}>
                        Our Network
                    </div>
                    <div className={cx(styles.network__section, 'row')}>
                        <div className='col-sm-12 col-md-4'>
                            <div className={cx(styles.network__content, 'd-flex justify-content-between')}>
                                <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/Group+239.svg' alt='' className={styles.network__content_image} />
                                <div className={cx(styles.network_description, 'd-flex flex-column justify-conten-evenly')}>
                                    <h2 className='text-center text-center'>278</h2>
                                    <p className='text-capitalize text-bold text-center'>quality teachers</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-12 col-md-4'>
                            <div className={cx(styles.network__content, 'd-flex justify-content-between')}>
                                <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/undraw_laravel_and_vue_59tp.svg' alt='' className={styles.network__content_image} />
                                <div className={cx(styles.network_description, 'd-flex flex-column justify-conten-evenly')}>
                                    <h2 className='text-center text-center'>1000+</h2>
                                    <p className='text-capitalize text-bold text-center'>a+ test series</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-12 col-md-4'>
                            <div className={cx(styles.network__content, 'd-flex justify-content-between')}>
                                <img src='https://s3.ap-south-1.amazonaws.com/et-teachers-assets/undraw_finance_0bdk.svg' alt='' className={styles.network__content_image} />
                                <div className={cx(styles.network_description, 'd-flex flex-column justify-conten-evenly')}>
                                    <h2 className='text-center text-center'>5000+</h2>
                                    <p className='text-capitalize text-bold text-center'>students</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <PopUpModal modelcontent={activePopup} onRef={ref => (this.PopUpModal = ref)} />
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        displayExamlist: state.app.listofexams,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onFetchexamlist: (examlistData) => dispatch(actions.getListofexams(examlistData)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage);

