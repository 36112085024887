import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import styles from './testseries.scss';
import globestyle from '../../sass/global.scss';
import actions from '../../redux/actions/app.js';
import cx from 'classnames';
import FolderIcon from '../../images/FolderIcon.svg';
import _ from 'lodash';

let params = {};
let exam_id = 0;
let test_series_id = 0;
let test_id = 0;
let subject_id = 0;

class UploadedFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filesList: null,
            uploads: [],
            selectedSubject: null,
            modal: false,
            submitMessage: '',
            imageModal : false
        };
        this.handleUpload = this.handleUpload.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangeUpload = this.handleChangeUpload.bind(this);
        this.handleSubmitTest = this.handleSubmitTest.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentWillMount() {
        if (this.props.activeData && this.props.activeData.activeExamId) {
            exam_id = this.props.activeData.activeExamId;
            let data = this.props.location.pathname.replace(this.props.match.url + '/', '');
            data = data.split('/');
            test_series_id = data[0];
            test_id = data[1];
            subject_id = data[2];
            params = { exam_id: exam_id, test_series_id: test_series_id, test_id: test_id, subject_id: subject_id };
            const listoftestData = { examid: exam_id, testseriesid: test_series_id };
            this.props.onFetchtestlist(listoftestData);
            this.props.getUploadedFiles(params);
        }
        if (this.props.displayTestlist && this.props.displayTestlist.tests.length) {
            const selectedSubject = this.getSubjectDetails(this.props.displayTestlist.tests);
            this.setState({
                'selectedSubject': selectedSubject,
            });
        }
    }

    componentDidUpdate(nextProps) {
        if (this.props.filesList !== nextProps.filesList && this.props.filesList) {
            this.setState({ filesList: this.props.filesList });
        }
        if (this.props.displayTestlist !== nextProps.displayTestlist && this.props.displayTestlist && this.props.displayTestlist.tests.length) {
            const selectedSubject = this.getSubjectDetails(this.props.displayTestlist.tests);
            this.setState({
                'selectedSubject': selectedSubject,
            });
        }
        if (this.props.activeData != nextProps.activeData && this.props.activeData && this.props.activeData.activeExamId && exam_id == 0) {
            if (!test_series_id) {
                let data = this.props.location.pathname.replace(this.props.match.url + '/', '');
                data = data.split('/');
                test_series_id = data[0];
                test_id = data[1];
                subject_id = data[2];
            }
            exam_id = this.props.activeData.activeExamId;
            params = { exam_id: exam_id, test_series_id: test_series_id, test_id: test_id, subject_id: subject_id };
            this.setState({
                datalist: params,
            });
            const listoftestData = { examid: exam_id, testseriesid: test_series_id };
            this.props.onFetchtestlist(listoftestData);
            this.props.getUploadedFiles(params);
        }
        if (this.props.uploadedFiles != nextProps.uploadedFiles && this.props.uploadedFiles && this.props.uploadedFiles.status_code == 200) {
            params = { exam_id: exam_id, test_series_id: test_series_id, test_id: test_id, subject_id: subject_id };
            this.props.getUploadedFiles(params);
        }
        if (this.props.deletedFile != nextProps.deletedFile && this.props.deletedFile && this.props.deletedFile.status_code == 200) {
            params = { exam_id: exam_id, test_series_id: test_series_id, test_id: test_id, subject_id: subject_id };
            this.props.getUploadedFiles(params);
        }
        if (this.props.updatedFile != nextProps.updatedFile && this.props.updatedFile && this.props.updatedFile.status_code == 200) {
            params = { exam_id: exam_id, test_series_id: test_series_id, test_id: test_id, subject_id: subject_id };
            this.props.getUploadedFiles(params);
        }
        if (this.props.submitTest != nextProps.submitTest && this.props.submitTest && this.props.submitTest.status_code == 200) {
            this.setState({
                submitMessage: this.props.submitTest.message,
            });
            this.toggle();
        }
    }

    getSubjectDetails(tests) {
        const selectedTestArray = tests.filter(function (item) {
            return (item.id == test_id);
        });
        const selectedTest = selectedTestArray[0].subjects;
        const selectedSubjectArray = selectedTest.filter(function (item) {
            return (item.subject_id == subject_id);
        });
        return selectedSubjectArray[0];
    }

    handleUpload(event) {
        const target = Array.from(event.target.files);
        let data = params;
        if (target) {
            data.uploads = target;
            this.props.onUploadFiles(data);
        }
    }

    handleChangeUpload(event, upload_id) {
        const target = event.target.files[0];
        let data = params;
        if (target) {
            data.uploads = target;
            data.upload_id = upload_id;
            this.props.onUploadChangeFile(data);
        }
    }

    handleDelete(event, id) {
        event.preventDefault();
        if (id) {
            let data = params;
            data.upload_id = id;
            this.props.onDeleteFile(data);
        }
    }

    handleSubmitTest(event) {
        event.preventDefault();
        const param = { exam_id: exam_id, test_series_id: test_series_id, test_id: test_id };
        this.props.onSubmitTest(param);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    showModal = () => {
        this.setState({
            imageModal : true
        });
    }
    imagetoggle = () => {
        this.setState(prevState => ({
            imageModal: !prevState.imageModal
        }));
    }
    handleBack() {
        this.props.history.push('/test-series/upload_test/'+params.test_series_id);
    }

    render() {
        const { filesList, selectedSubject, submitMessage } = this.state;
        const url = '/test-series/view-developed-question/' + params.exam_id + '/' + params.test_series_id + '/' + params.test_id + '/' + params.subject_id;

        return (
            <div>
                <nav aria-label="breadcrumb">
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to="/test-series">Home</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to="/">Test Series</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem, globestyle.active)} aria-current="page"><span>Files</span></li>
                    </ol>
                </nav>
                <div className={cx('clearfix')}></div>
                <div className={cx(styles.maincontent)}>
                    <div className={cx(styles.headings)}>
                        <div onClick={this.handleBack} className={cx(styles.goback)}>
                            <Link to='#'><i className={cx('fas', 'fa-arrow-left')}></i></Link>
                        </div>
                        <img src={FolderIcon} />
                        <h1>{selectedSubject ? selectedSubject.title : ''}</h1>
                    </div>
                    <div className={cx('clearfix')}></div>
                    <div className={cx(styles.maincontent)}>
                        <div className={cx('row', styles.actionblock)}>
                            <div className={cx('col-md-6 col-sm-6')}>
                                <div className={cx(styles.upload_box)}>
                                    <label htmlFor="file-upload" className={cx(styles.customfileupload)}>
                                        <i className={cx('fa fa-plus')}></i> Upload New File
                                    </label>
                                    <input id="file-upload" type="file" onChange={this.handleUpload} accept=".png, .jpeg, .jpg, .pdf" multiple />
                                    <span>(Read guidelines)</span>
                                </div>
                            </div>
                            <div className={cx('col-md-4 col-sm-4 text-right')}>
                                <div className={cx(styles.upload_box)}>
                                    <Link to={{
                                        pathname: url,
                                        state: {
                                            exam_id: params.exam_id,
                                            test_series_id: params.test_series_id,
                                            subject_id: params.subject_id,
                                            test_id:params.test_id
                                        }
                                    }} ><i className={cx('fa fa-eye')}></i>
                                        View Developed Content
                                    </Link>
                                </div>
                            </div>
                            <div className={cx('col-md-2 col-sm-2 text-right')}>
                                <Link to="#" className={cx(styles.approve_button)} onClick={this.handleSubmitTest}> <i className={cx(styles.check_mark, 'fa fa-check')}></i> Submit</Link>
                            </div>
                        </div>
                        <div className={cx('clearfix')}></div>
                        {filesList && filesList.uploaded_files && filesList.uploaded_files.length > 0 ?
                            <ol className={cx(styles.listsection)}>
                                {
                                    _.map(filesList.uploaded_files, (flist) => {
                                        const filename = flist.url.substring(flist.url.lastIndexOf('/') + 1);
                                        return (
                                            <li key={flist.id}>
                                                <div className={cx(styles.test_series)}>
                                                    <div className={cx('col-md-2')}>
                                                        <div className={cx(styles.view_more)} onClick = {this.showModal}>
                                                            <div className = {cx(styles.view_file)}>
                                                                <a href = {flist.url} target = "_blank"> <i className={cx('fa fa-eye')}></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={cx('col-md-9')}>
                                                        <p className={cx(styles.subjects_list)}>
                                                            {filename}
                                                            <span className={cx(styles.uploaded_time)}>UPLOADED ON 12-12-2018</span>
                                                        </p>
                                                        <div className={cx(styles.edit_icon)}>
                                                            <label htmlFor={'single-file-upload' + flist.id} className={cx(styles.pencil_icon)}>
                                                                <i className={cx('fa fa-pencil-alt')}></i>
                                                            </label>
                                                            <input id={'single-file-upload' + flist.id} type="file" onChange={(e) => this.handleChangeUpload(e, flist.id)} accept=".png, .jpeg, .jpg, .pdf" />
                                                            <Link to="#" onClick={(e) => this.handleDelete(e, flist.id)} className={cx(styles.pencil_icon)}>
                                                                <i className={cx('fa fa-trash')}></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className={cx('col-md-1')}>
                                                        <i className={cx(styles.details_icon, 'fa fa-ellipsis-v')}></i>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                }
                            </ol>
                            : null}
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalBody className={globestyle.messageblock}>
                        <div>
                            <a className={cx('float_right', globestyle.close_button)} onClick={this.toggle}>
                                <i className={cx('far fa-times-circle')}></i>
                            </a>
                        </div>
                        <div className={cx(globestyle.message)}>{submitMessage ? submitMessage : 'No Update!!'}</div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {
        filesList: (state.app.uploadedfiles ? state.app.uploadedfiles : null),
        activeData: (state.app.activedata ? state.app.activedata : null),
        uploadedFiles: (state.app.uploadedfiles ? state.app.uploadedfiles : null),
        displayTestlist: (state.app.listoftests ? state.app.listoftests : null),
        deletedFile: (state.app.deletedfile ? state.app.deletedfile : null),
        updatedFile: (state.app.updatedfile ? state.app.updatedfile : null),
        submitTest: (state.app.submittest ? state.app.submittest : null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUploadedFiles: (params) => dispatch(actions.getUploadedFiles(params)),
        onUploadFiles: (params) => dispatch(actions.uploadFiles(params)),
        onFetchtestlist: (listoftestData) => dispatch(actions.getListoftest(listoftestData)),
        onDeleteFile: (params) => dispatch(actions.deleteFile(params)),
        onUploadChangeFile: (params) => dispatch(actions.uploadChangeFile(params)),
        onSubmitTest: (params) => dispatch(actions.submitTest(params)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadedFiles);
