import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import styles from './sidebar.scss';
import cx from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';

let examId = 0;
class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            competetiveExam: [],
            activeExamId: 0,
            registerDetails:'',
            registerfailed:'',
            showexams : false,
            examlist:[],
            displayMenu: false,
        };
        this.callRegistration = this.callRegistration.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    }
    componentWillMount() {
        if (this.props.displayExamlist && this.props.displayExamlist.exams.length) {
            this.setState({
                'competetiveExam': this.props.displayExamlist.exams,
            });
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        const examlistData = { status1: 'draft', status2: 'active' };
        this.props.onFetchexamlist(examlistData);
        this.props.onFetchexams(examlistData);
    }

    componentDidUpdate(nextProps) {
        if (this.props.displayExamlist!=nextProps.displayExamlist && this.props.displayExamlist && this.props.displayExamlist.exams.length) {
            examId = this.props.displayExamlist.exams[0].id;
            this.setState({
                activeExamTitle : this.props.displayExamlist.exams[0].title
            });
            const checklocal = (localStorage.getItem('activeData')? JSON.parse(localStorage.getItem('activeData')) : null);
            if(!checklocal) {
                this.props.onActiveData(examId);
            } else {
                this.props.onActiveData(checklocal.activeExamId);
            }
            this.setState({
                'competetiveExam': this.props.displayExamlist.exams,
            });
        }
        if (this.props.registerDetails !== nextProps.registerDetails && this.props.registerDetails) {
            this.setState({ registerDetails: this.props.registerDetails });
        }
        if (this.props.registerfailed !== nextProps.registerfailed && this.props.registerfailed) {
            this.setState({ registerfailed: this.props.registerfailed });
        }
        if(this.props.examlist !== nextProps.examlist && this.props.examlist) {
            this.setState({ examlist : this.props.examlist.exams});
        }
    }

    handleChange (examid, examtitle) {
        this.setState({activeExamId: examid, activeExamTitle : examtitle});
        this.props.onActiveData(examid);
    }

    showexamlist = () => {
        this.setState({
            showexams:!this.state.showexams
        });
    }
    callRegistration(examid){
        this.setState({
            showexams : false
        });
        event.preventDefault();
        const registerparams = examid;
        this.props.onRegisterData(registerparams);
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                showexams: false,
            });
        }
    }

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });

    }

    render() {
        const { competetiveExam, registerDetails,registerfailed, examlist, activeExamTitle } = this.state;
        return (
            <div className={cx('col-xl-2', 'col-lg-3', 'col-xs-6', styles.leftblock, styles.hideleftbar)} id='mySidenav'>
                <button className={cx('d-lg-none', 'd-xl-none', styles.closebtn)}><i className='fas fa-times'></i></button>
                <div className={cx(styles.fieldset, (this.state.displayMenu ? styles.displayoptionfieldset : ''))}>
                    <h1 className={cx(styles.customlegend)}>Your exams</h1>
                    <div className={cx(styles.selectdiv)}>
                        <div className='dropvalue' onClick={this.showDropdownMenu}>{this.state.displayMenu ? '' : activeExamTitle}
                            {this.state.displayMenu ?
                                <ul>
                                    {
                                        _.map(competetiveExam, (exam) => {
                                            return (
                                                <li onClick={() => this.handleChange(exam.id, exam.title)} >{exam.title}</li>
                                            );
                                        })
                                    }
                                </ul> : null
                            }
                        </div>
                    </div>

                </div>
                <div className = {cx(styles.messageStyle)}>
                    <span className={registerfailed ? 'text-danger font-weight-bold d-flex justify-content-center' : 'd-none'}>{registerfailed ? registerfailed : ''}</span>
                    <span className={registerDetails ? 'text-success font-weight-bold d-flex justify-content-center' : 'd-none'}>{registerDetails ? registerDetails.message : ''}</span>
                </div>
                <div className={cx('col-xl-12', styles.registerlink)} ref={this.setWrapperRef}>
                    <Link to='#' onClick={this.showexamlist} >
                        <i className={cx('fas', 'fa-plus-circle','pr-1')}></i>Register for new Exam
                    </Link>
                    {this.state.showexams == true ?
                        <div className={cx(styles.popupbox)}>
                            <ul>
                                {
                                    _.map(examlist, (exam) => {
                                        return (
                                            <li onClick={() => this.callRegistration(exam.id)}>{exam.title}</li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                        : null
                    }


                </div>
                <div className={cx(styles.logoalignment, (this.state.displayMenu ? styles.navigationalignment:''))}>
                    <div>
                        <ul className={cx(styles.navigation)}>
                            <li>
                                <NavLink to='/test-series' className={cx('icon-test-series')} activeClassName={styles.activelink} >Test Series</NavLink>
                            </li>
                            <li>
                                <NavLink to='/doubts' onClick={e => e.preventDefault()} className={cx(styles.disabledlink ,'icon-Doubts')} activeClassName={styles.activelink} >Doubts</NavLink>
                            </li>
                            <li>
                                <NavLink to='#' onClick={e => e.preventDefault()} className={cx(styles.disabledlink ,'icon-analytics')} activeClassName={styles.activelink}>Analytics</NavLink>
                            </li>
                            <li>
                                <NavLink to='/revenue' className={cx('icon-revenue1')} activeClassName={styles.activelink}>Revenue</NavLink>
                            </li>
                            <li>
                                <NavLink to='/refer-and-earn' onClick={e => e.preventDefault()} className={cx(styles.disabledlink ,'icon-refer')} activeClassName={styles.activelink}>Refer and Earn</NavLink>
                            </li>
                            <li>
                                <NavLink to='/settings' className={cx('icon-settings')} activeClassName={styles.activelink}>Settings</NavLink>
                            </li>
                            <li>
                                <NavLink to='/help' className={cx('icon-help')} activeClassName={styles.activelink}>Help</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className={cx(styles.examtreasurylogo)}>
                        <div><Link to='#' className={cx(styles.roundedbtn)}>Get ET Teachers app</Link></div>
                        <img src={require('images/examtreasury.jpg')} alt='Examtreasury.com' className={cx(styles.logoalignment)}/>
                    </div>

                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        displayExamlist: (state.app.listofexams ? state.app.listofexams : null),
        examlist : (state.app.examlist ? state.app.examlist : null),
        registerDetails:state.app.registerforexam,
        registerfailed:state.app.registerfailed,
        activeData: (state.app.activedata ? state.app.activedata : null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onFetchexamlist: (examlistData) => dispatch(actions.getListofexams(examlistData)),
        onFetchexams: (examlistData) => dispatch(actions.getExamList(examlistData)),
        onActiveData: (data) => dispatch(actions.getActiveData(data)),
        onRegisterData:(registerparams) => dispatch(actions.getRegisterforexam(registerparams)),
    };
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SideBar));
