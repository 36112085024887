import _ from 'lodash';
import { handleActions } from 'redux-actions';
//import actionTypes from './../actionTypes';

const defaultState = _.get(global.window, '__INIT_STATE__.app', {
    draftlist: null,
    publishedlist:null,
    unpublishedlist:null,
});

export default handleActions({
    // [actionTypes.GET_DRAFT_LIST] (state, { payload }) {
    //     const draftlist = payload.data.data;
    //     return {
    //         ...state,
    //         draftlist,
    //     };
    // },
    // [actionTypes.GET_UNPUBLISHED_LIST] (state, { payload, error }) {
    //     if(!error && payload.data.status_code==200) {
    //         const unpublishedlist = payload.data.data;
    //         return {
    //             ...state,
    //             unpublishedlist,
    //         };
    //     }
    // },
    // [actionTypes.GET_PUBLISHED_LIST] (state, { payload, error }) {
    //     if(!error && payload.data.status_code==200) {
    //         const publishedlist = payload.data.data;
    //         return {
    //             ...state,
    //             publishedlist,
    //         };
    //     }
    // },

}, defaultState);
