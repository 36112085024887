/* eslint-disable no-unused-vars */
import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import style from './style.scss';
import globestyle from '../../sass/global.scss';
import actions from '../../redux/actions/app.js';
import * as moment from 'moment';
import _ from 'lodash';
import { styles } from 'material-ui-pickers/DatePicker/components/Calendar';

class NewTestSeries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subjectList: [],
            allsubjects: false,
            subjectSelected: [],
            displayMenu: false,
            displayTitle:'',
            title: { isValid: false, message: '' },
            year: { isValid: false, message: '' },
            subject: { isValid: false, message: '' },
        };
        this.handleAllChecked = this.handleAllChecked.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);

    }
    componentDidMount() {
        this.setState({
            subjectList: this.props.subjects ? this.props.subjects.subjects : [],
        });
    }



    getNextFiveYear() {
        let yearStart = moment().format('YYYY');
        let year = [];
        let i;
        for (i = 1; i <= 5; i++) {
            year.push(yearStart++);
        }
        return year;
    }

    handleAllChecked(event) {
        const allsubjects = event.target.checked;
        let checkboxes = document.getElementsByName('subjects[]');
        let subjectlist = [];
        if (allsubjects) {
            for (let i in checkboxes) {
                if (checkboxes[i].checked == false) {
                    checkboxes[i].checked = true;
                    if (checkboxes[i].value) {
                        subjectlist.push({ id: checkboxes[i].value, checked: true });
                    }
                }
            }
        } else {
            for (let j in checkboxes) {
                if (checkboxes[j].checked == true) {
                    checkboxes[j].checked = false;
                    if (checkboxes[j].value) {
                        subjectlist.push({ id: checkboxes[j].value, checked: false });
                    }
                }
            }
        }
        this.setState({
            allsubjects: allsubjects,
            subjectSelected: subjectlist,
        });
    }

    handleChecked() {
        let checkboxes = document.getElementsByName('subjects[]');
        let subjectlist = [];
        for (var j in checkboxes) {
            if (checkboxes[j].value) {
                subjectlist.push({ id: checkboxes[j].value, checked: checkboxes[j].checked });
            }
        }
        this.setState({
            subjectSelected: subjectlist,
        });
    }

    handleSubmit() {
        const title = this.refs.title.value;
        const year = this.state.displayTitle;
        const allsubject = this.state.allsubjects;
        const subjectlist = this.state.subjectSelected;
        let isValidTitle = null;
        let isValidYear = null;
        let isValidSubject = null;

        if (!title) {
            isValidTitle = { isValid: false, message: 'Title cannot be blank.' };
        } else {
            isValidTitle = { isValid: true, message: '' };
        }
        if (!year) {
            isValidYear = { isValid: false, message: 'Year cannot be blank.' };
        } else {
            isValidYear = { isValid: true, message: '' };
        }
        if (allsubject == false && !subjectlist.length) {
            isValidSubject = { isValid: false, message: 'Select the subject.' };
        } else {
            isValidSubject = { isValid: true, message: '' };
        }


        this.setState({
            title: isValidTitle,
            year: isValidYear,
            subject: isValidSubject,
        });

        if (isValidTitle.isValid && isValidYear.isValid && isValidSubject.isValid) {
            let submitData = {
                title: title,
                year: year,
                all_subjects: this.state.allsubjects,
            };
            if(subjectlist && subjectlist.length && this.state.allsubjects==false) {
                let subjectIds = [];
                this.state.subjectSelected.map((item) => {
                    if(item.checked==true) {
                        subjectIds.push(parseInt(item.id));
                    }
                });
                submitData.subject_ids = subjectIds;
            }
            this.props.saveTestSeries(submitData, { examid: this.props.activeData.activeExamId });


        }
    }
    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });

    }
    handleChange (title) {
        this.setState({displayTitle:title});
    }



    render() {
        const years = this.getNextFiveYear();
        const { year,displayTitle, title,subject ,subjectList, allsubjects,saveMessage } = this.state;
        return (
            <div className={cx(style.test_series_container)}>
                <div className={cx('row justify-content-md-center')}>
                    <div className={cx('col col-lg-10')}>
                        <form>
                            <div className={cx('row')}>
                                <div className={cx('col-md-12')}>
                                    <div className={cx(style.popup_title_container)}>
                                        <h1>Add new test series</h1>
                                        <p  className={cx('row justify-content-md-center text-danger small font-italic')}>{saveMessage?saveMessage:null}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={cx(style.form_container)}>
                                <div className={cx('row justify-content-md-center')}>
                                    <div className={cx('col col-lg-12')}>
                                        <div className={cx('form-group')}>
                                            <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                                <legend className={cx(globestyle.legend, globestyle.bluefont, globestyle.small)}>Title</legend>
                                                <input type='text' ref='title' className={cx('form-control', globestyle.small)} />
                                            </fieldset>
                                            <span className={cx(style.help_text)}>Ex. NDA & NA | PRACTICE TEST SERIES 2 | 2019</span>
                                            <span className={!title.isValid ? 'text-danger small font-italic': 'd-none'}>{!title.isValid ? title.message: ''}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('row justify-content-md-center')}>
                                    <div className={cx('col col-lg-12')} >
                                        <div className={cx('form-group')}>
                                            <fieldset className={cx(globestyle.fset, globestyle.blueborder)}>
                                                <legend className={cx(globestyle.legend, globestyle.bluefont, globestyle.small)}>Target Year</legend>
                                                <div className={cx(style.selectdiv)}>
                                                    <div className='dropvalue' onClick={this.showDropdownMenu}>{displayTitle?displayTitle:'Select year'}</div>
                                                    {this.state.displayMenu ?
                                                        <ul>
                                                            {years.map((item, i) => {
                                                                return (
                                                                    <li onClick = {()=>this.handleChange(item)} key={i} id={i}>{item}
                                                                    </li>
                                                                );
                                                            }

                                                            )}
                                                        </ul> : null
                                                    }
                                                </div>
                                            </fieldset>
                                            <span className={!year.isValid ? 'text-danger small font-italic': 'd-none'}>{!year.isValid ? year.message: ''}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className={cx(style.margin)}></div>
                                <div className={cx('row justify-content-md-center')}>
                                    <div className={cx('col col-lg-12')}>
                                        <div className={cx(style.sub_title_container)}>
                                            <h2>SUBJECT COVERED IN TEST SERIES</h2>
                                        </div>
                                    </div>
                                </div>
                                <span className={ !subject.isValid  ? 'text-danger small font-italic': 'd-none'}>{ !subject.isValid  ? subject.message: ''}</span>
                                <div className={cx(style.check_container)}>
                                    <div className={cx('row')}>
                                        <div className={cx('col')}>
                                            <div className={cx('pl-4')}>
                                                <label className={cx('form-check-label', style.checklbl)}>
                                                    <input onClick={this.handleAllChecked}
                                                        type='checkbox'
                                                        value="0"
                                                        className={cx('form-check-input')} name={'selectAll'} /><p className={cx('pl-1 pt-1')}> Select All</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('row justify-content-md-center')}>
                                        {
                                            subjectList.map(
                                                (item, i) =>
                                                    <div key={i} className={cx('col-md-4')}>
                                                        <div className={cx('pl-4')}>
                                                            <label className={cx('form-check-label', style.checklbl)}>
                                                                <input
                                                                    onClick={this.handleChecked}
                                                                    type="checkbox"
                                                                    className={cx('form-check-input subject')}
                                                                    value={item.id}
                                                                    name="subjects[]"
                                                                    disabled={allsubjects ? true : false} />
                                                                <p className={cx('pl-1 pt-1')}>{item.title}</p>
                                                            </label>
                                                        </div>
                                                    </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={cx(style.button_center)}>
                                    <button
                                        className={cx('theme-btn', 'blue')}
                                        type='button' onClick={this.handleSubmit}>
                                        CREATE
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>

                </div>

            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        subjects: state.app.subjectlists,
        testSeries: state.app.testseries,
        activeData: (state.app.activedata ? state.app.activedata : null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        subjectList: (examid) => dispatch(actions.getSubjectList(examid)),
        saveTestSeries: (params1, params2) => dispatch(actions.getSaveTestSeries(params1, params2)),

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewTestSeries);
// export default NewTestSeries;
