import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './innerheader.scss';
import cx from 'classnames';
import actions from '../../redux/actions/app.js';

let showProfileMenu = false;

class InnerHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo: null,
            isLoggedIn: true,
            showProfileMenu: false,
        };
        this.signout = this.signout.bind(this);
        this.expandMenu = this.expandMenu.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.props.onGetprofiledetails();
    }

    componentWillMount() {
        if(this.props.loginResult) {
            const userinfo = {
                user_id: this.props.loginResult.user_id,
                name: this.props.loginResult.user_profile.name,
            };
            this.setState({
                userinfo
            });
        }
    }
    componentDidUpdate(nextProps){
        if(this.props.profileDetails !== nextProps.profileDetails && this.props.profileDetails) {
            this.setState({
                profile_photo:this.props.profileDetails.data.profile_details.profile_photo,

            });

        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    signout = (e) => {
        e.preventDefault();
        localStorage.removeItem('userdetails');
        window.location.href = window.location.origin;
    }

    expandMenu = (e) => {
        e.preventDefault();
        if(showProfileMenu == true) {
            showProfileMenu = false;
        } else {
            showProfileMenu = true;
        }
        this.setState({
            showProfileMenu,
        });
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                showProfileMenu: false,
            });
        }
    }

    render() {
        const { userinfo,profile_photo } = this.state;
        return (
            <div className={cx('col-xl-12', styles.topnavigation)}>
                <button className={cx('navbar-toggler', 'd-lg-none', 'd-xl-none')} type="button" aria-label="Toggle navigation">
                    <span>
                        <i className={cx('fas', 'fa-bars', 'fa-1x')}></i>
                    </span>
                </button>
                <div className={cx('col-sm-2', 'col-lg-4', 'col-xl-2', 'float-right', styles.profileblock)} ref={this.setWrapperRef}>
                    <div className={cx(styles.profileinnerblock, (this.state.showProfileMenu ? styles.show : ''))}>
                        <Link to='#' onClick={this.expandMenu}>
                            {profile_photo ?
                                <img src={profile_photo} alt="Profile picture" /> : <div className={cx(styles.profilestyle)}><label className = {cx(styles.altimage)}>{userinfo.name[0]}</label></div>}
                            <p>{userinfo.name}</p>
                            <i className={cx('fas', 'fa-ellipsis-v')}></i>
                        </Link>
                        <div className={cx(styles.profilenav)}>
                            <ul>
                                <li><Link to={'/callprofile'}>My profile</Link></li>
                                <li><Link to='#' onClick={this.signout}>Sign out</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={cx('col-xl-2', 'col-lg-3', 'float-right', styles.notification)}>
                    <i className={cx('fas', 'fa-bell')}></i>
                    <span className={cx(styles.badge)}>2</span>
                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {
        loginResult: state.app.login,
        profileDetails:state.app.getprofiledetails,
    };
}

function mapDispatchToProps (dispatch){
    return{
        onGetprofiledetails:() => dispatch(actions.getProfiledetails()),
    };
}

export default connect(
    mapStateToProps,mapDispatchToProps
)(InnerHeader);
