import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import actions from '../../redux/actions/app.js';
import pagestyle from '../JoinNow/joinnow.scss';
import globestyle from '../../sass/global.scss';
import validation from '../../validations';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers';
import * as moment from 'moment';



class CallRequest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            fromTime: { isValid: false, message: '', value: null },
            toTime: { isValid: false, message: '', value: null },
            errorMessage :'',
            todaychecked:false,
            tomorrowchecked:false,
        };

    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState) {
        if (this.props.callRqst && prevProps.callRqst !== this.props.callRqst) {
            if (this.props.callRqst.status_code != 200) {
                this.setState({ error: this.props.callRqst.message });
            }
        }
    }
    componentDidMount() {
        this.props.onRef(this);
    }
    componentWillUnmount() {
        this.props.onRef(undefined);
    }


    saveStepThree() {
        const { selectedDate, fromTime, toTime, errorMessage } = this.state;
        const date = moment(selectedDate).format('YYYY-MM-DD');
        const from = moment(fromTime.value).format('hh:mm A');
        const to = moment(toTime.value).format('hh:mm A');
        if(from < '07:00 AM' && to > '09:00 PM'){
            this.setState({
                errorMessage : 'Calling time should be between 07:00 AM to 09:00 PM'
            });
        }
        else {
            this.setState({
                errorMessage :''
            });
        }
        // const isValidDate = validation('date', date);
        const isValidFrom = validation('time', from);
        const isValidto = validation('time', to);
        if (isValidFrom.valid && isValidto.valid && errorMessage == '') {
            let start = date + ' ' + from;
            let end = date + ' ' + to;
            const final_start = moment(start).format();
            const final_end = moment(end).format();
            let name = '';
            let user_id = '';
            let phone = '';
            if (this.props.signUpResult && this.props.otpLoginResult) {
                name = this.props.signUpResult.data.user_profile.name;
                user_id = this.props.otpLoginResult.data.user_profile.user_id;
                phone = this.props.signUpResult.data.user_profile.phone;
                this.props.requestCall({ name: name, phone: phone, user_id: user_id, from_date: final_start, end_date: final_end });
            } else {
                name = this.props.callRequestWsignUp.name;
                phone = this.props.callRequestWsignUp.phone;
                this.props.requestCall({ name: name, phone: phone, from_date: final_start, end_date: final_end });
            }

        } else {
            this.setState({
                // date: { isValid: isValidDate.valid, message: isValidDate.message },
                from: { isValid: isValidFrom.valid, message: isValidFrom.message },
                to: { isValid: isValidto.valid, message: isValidto.message },
            });
        }


    }

    handleCheckChange = e => {
        // eslint-disable-next-line no-unused-vars
        const { name, value } = e.target;
        var today = moment();
        let tdate = '';
        if (value == 'today') {
            tdate = moment(today).format('YYYY-MM-DD');
            this.setState({
                todaychecked:true,
                tomorrowchecked: false
            });
        } else if (value == 'tomorrow') {
            this.setState({
                tomorrowchecked:true,
                todaychecked: false
            });
            tdate = moment(today).add(1, 'day').format('YYYY-MM-DD');
        }
        this.setState({ selectedDate: tdate });

    };
    handleDateChange = date => {
        this.setState({ selectedDate:  date , todaychecked:false, tomorrowchecked:false,  });
    }
    handleFromTimeChange = time => {
        this.setState({ fromTime: { isValid: false, message: '', value: time } });
    }
    handleToTimeChange = time => {
        this.setState({ toTime: { isValid: false, message: '', value: time } });
    }
    render() {
        const { selectedDate, fromTime, toTime, error, errorMessage, todaychecked, tomorrowchecked } = this.state;

        return (
            <div>
                <div className={cx(pagestyle.call_schedule_contcainer)}>
                    <span className={error ? 'text-danger font-weight-bold d-flex justify-content-center' : 'd-none'}>{error ? error : ''}</span>
                    <span className={errorMessage ? 'text-danger font-weight-bold d-flex justify-content-center' : 'd-none'}>{errorMessage ? errorMessage : ''}</span>
                    <div className={cx(pagestyle.title_container)}>
                        <h1>{this.props.headermessage}</h1>
                        <p>{this.props.paragraph} <br></br><span>(Calling hours are 7 AM TO 9 PM)</span></p>
                    </div>
                    <form>
                        <div className={cx(pagestyle.times_container)}>
                            <div className={cx('row justify-content-md-center')}>
                                <div className={cx('col')} >

                                    <div className={cx('row justify-content-md-center')} >
                                        <div className={cx('col-md-3 text-right')}>
                                            <div className={cx('form-check form-check-inline', pagestyle.radio_container)} >
                                                <label className={cx('form-check-label')}>
                                                    <input className={cx('form-check-input')} onChange={this.handleCheckChange} type="radio" name="datetype" value="today" checked={todaychecked}  />
                                                    Today
                                                </label>
                                            </div>
                                        </div>
                                        <div className={cx('col-md-3')}>
                                            <div className={cx('form-check form-check-inline', pagestyle.radio_container)} >
                                                <label className={cx('form-check-label')}>
                                                    <input className={cx('form-check-input')} type="radio" onChange={this.handleCheckChange} name="datetype" value="tomorrow" checked={tomorrowchecked} />
                                                    Tomorrow
                                                </label>
                                            </div>
                                        </div>
                                        <div className={cx('col-md-4 pl-1')}>
                                            <div className={cx(pagestyle.datePicker)}>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        keyboard
                                                        format="YYYY-MM-DD"
                                                        disablePast={true}
                                                        value={selectedDate}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            placeholder: 'Select date',
                                                        }}
                                                        onChange={this.handleDateChange.bind(this)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <br />

                                    <div className={cx('row justify-content-md-center')}>
                                        <div className={cx('col-md-4')}>
                                            <div className={cx('form-group')}>
                                                <fieldset className={cx(globestyle.fset)}>
                                                    <legend className={cx(globestyle.legend)}>From</legend>
                                                    {/* <input ref='from' type='text' className={cx('form-control')} /> */}
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <TimePicker
                                                            value={fromTime.value}
                                                            disablePast={true}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                            onChange={this.handleFromTimeChange}
                                                        />

                                                    </MuiPickersUtilsProvider>
                                                </fieldset>
                                                <span className={!fromTime.isValid ? 'text-danger small font-italic' : 'd-none'}>{!fromTime.isValid ? fromTime.message : ''}</span>
                                            </div>
                                        </div>
                                        <div className={cx('col-md-4')}>
                                            <div className={cx('form-group')}>
                                                <fieldset className={cx(globestyle.fset)}>
                                                    <legend className={cx(globestyle.legend)}>To</legend>
                                                    {/* <input ref='to' id='totime' type='text' className={cx('form-control')} /> */}
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <TimePicker
                                                            value={toTime.value}
                                                            disablePast={true}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                            onChange={this.handleToTimeChange}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </fieldset>
                                                <span className={!toTime.isValid ? 'text-danger small font-italic' : 'd-none'}>{!toTime.isValid ? toTime.message : ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        loginResult: state.app.login,
        callRequestWsignUp: state.app.callrqstdata,
        signUpResult: (state.app.signup ? state.app.signup : null),
        callRqst: (state.app.callrequest ? state.app.callrequest : null),
        otpLoginResult: (state.app.otplogin ? state.app.otplogin : null),

    };
}

function mapDispatchToProps(dispatch) {

    return {
        requestCall: (userInfo) => dispatch(actions.getRequestCall(userInfo)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CallRequest);