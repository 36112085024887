import React from 'react';
import styles from './profiledetails.scss';
import cx from 'classnames';
import actions from '../../redux/actions/app.js';
import globestyle from '../../sass/global.scss';
import validation from '../../validations';
import { connect } from 'react-redux';
import _ from 'lodash';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import * as moment from 'moment';
import { Modal, ModalBody } from 'reactstrap';
import Otp from '../Otp';


let stateid=0;
let countryid=0;
let countries = [];
let states = [];
class Myprofile extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            name: { isValid: false, message: '' },
            phone: { isValid: false, message: '' },
            email: { isValid: false, message: '' },
            address: { isValid: false, message: '' },
            dob:{ isValid: false, message: '' },
            pincode:{ isValid: false, message: '' },
            city : { isValid: false, message: '' },
            state : { isValid: false, message: '' },
            Vcode:'',
            profile:[],
            displayMenu: false,
        };
        this.submitProfiledetails = this.submitProfiledetails.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this._child = React.createRef();
        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    }
    componentDidMount() {
        this.props.onRef(this);
        this.props.tofetchCities();
        this.props.onGetprofiledetails();
    }
    componentWillUnmount() {
        this.props.onRef(undefined);
    }
    componentDidUpdate(nextProps) {
        if (this.props.cityDetails !== nextProps.cityDetails && this.props.cityDetails) {
            this.setState({ cityDetails: this.props.cityDetails});
            countries = this.props.cityDetails.data.countries;
            if(this.props.profileDetails.data.profile_details.country){
                let country_id = this.props.profileDetails.data.profile_details.country.id;
                let country_name = this.props.profileDetails.data.profile_details.country.name;
                this.onCountryChange(country_id, country_name);
            }
            if(this.props.profileDetails.data.profile_details.state){
                let state_id = this.props.profileDetails.data.profile_details.state.id;
                let state_name = this.props.profileDetails.data.profile_details.state.name;
                this.onStateChange(state_id, state_name);
            }
        }
        if (this.props.otp !== nextProps.otp && this.props.otp) {
            this.setState({
                Vcode:this.props.otp
            });

        }
        if(this.props.profileDetails !== nextProps.profileDetails && this.props.profileDetails) {
            this.setState({
                profile_name : this.props.profileDetails.data.profile_details.name,
                profile_dob : this.props.profileDetails.data.profile_details.dob,
                profile_phone : this.props.profileDetails.data.profile_details.phone,
                profile_email : this.props.profileDetails.data.profile_details.email,
                profile_address : this.props.profileDetails.data.profile_details.address,
                profile_pincode : this.props.profileDetails.data.profile_details.pincode,
                profile_country : this.props.profileDetails.data.profile_details.country ? this.props.profileDetails.data.profile_details.country.name : '',
                profile_state : this.props.profileDetails.data.profile_details.state ? this.props.profileDetails.data.profile_details.state.name : '',
                profile_city : this.props.profileDetails.data.profile_details.city ? this.props.profileDetails.data.profile_details.city.name : '',
                profile_country_id : this.props.profileDetails.data.profile_details.country ? this.props.profileDetails.data.profile_details.country.id : '',
                profile_state_id : this.props.profileDetails.data.profile_details.state ? this.props.profileDetails.data.profile_details.state.id : '',
                profile_city_id : this.props.profileDetails.data.profile_details.city ? this.props.profileDetails.data.profile_details.city.id : '',
                profile_photo:this.props.profileDetails.data.profile_details.profile_photo,

            });

        }
        if(this.props.submitDetails !== nextProps.submitDetails && this.props.submitDetails && this.props.submitDetails.data.phone_changed==true) {
            this.setState({
                phonechange:this.props.submitDetails.data.phone_changed,
                otp_type:this.props.submitDetails.data.otp_type

            });
            this.toggle();
        }else if(this.props.submitDetails !== nextProps.submitDetails && this.props.submitDetails && this.props.submitDetails.status=='success'){
            this.setState({
                messageSuccess:this.props.submitDetails.status,
            });
            this.toggle();
        }

        if (this.props.newphonenumber !== nextProps.newphonenumber && this.props.newphonenumber.message) {
            this.setState({
                changenumberMessage:this.props.newphonenumber.message
            });
        }
        if (this.props.numberMessage !== nextProps.numberMessage && this.props.numberMessage) {
            this.setState({
                numberMessage: this.props.numberMessage,
            });

        }
        if(this.props.submitDetails !== nextProps.submitDetails && this.props.submitDetails && this.props.submitDetails) {
            this.props.onGetprofiledetails();
        }

    }

    submitProfiledetails(){
        const { dob } = this.state;
        const name = this.refs.name.value;
        const phone = this.refs.phone.value;
        const email = this.refs.email.value;
        const date =  moment(dob.value).format('YYYY-MM-DD');
        const address = this.refs.address.value;
        const pincode = this.refs.pincode.value;
        const country_id = this.state.profile_country_id;
        const state_id = this.state.profile_state_id;
        const city_id = this.state.profile_city_id;
        const Profile = this.state.profile;
        const isValidName = validation('name', name);
        const isValidPhone = validation('mobile', phone);
        const isValidEmail = validation('email', email);
        const isValidAddress = validation('query', address);
        const isValidDob = validation('query', dob);
        const isValidPincode = validation('pincode', pincode);
        const isValidState = validation('query', state_id);
        const isValidCity = validation('query', city_id);
        if (isValidName.valid && isValidPhone.valid && isValidEmail.valid &&isValidAddress.valid && isValidDob.valid && isValidPincode.valid && isValidState.valid && isValidCity.valid) {
            this.setState({
                name: { isValid: true, message: ''},
                phone: { isValid: true, message: ''},
                email: { isValid: true, message: ''},
                address: { isValid: true, message: ''},
                dob: { isValid: true, message: ''},
                pincode: { isValid: true, message: ''},
                state: { isValid: true, message: ''},
                city: { isValid: true, message: ''},
            });
            const profileData = {
                dob:date, pincode:pincode,profile_photo:Profile, country_id : country_id, state_id: state_id, city_id: city_id, name: name, phone: phone, email: email, address :address
            };

            this.props.onSubmitdetails(profileData);
            this.props.sendOtp({ phone: this.state.profile_phone, otp_type: 'edit-phone' });

        } else {
            this.setState({
                name: { isValid: isValidName.valid, message: isValidName.message },
                phone: { isValid: isValidPhone.valid, message: isValidPhone.message },
                email: { isValid: isValidEmail.valid, message: isValidEmail.message },
                address:{ isValid: isValidAddress.valid, message: isValidAddress.message },
                dob:{ isValid: isValidDob.valid, message: isValidDob.message },
                pincode:{ isValid: isValidPincode.valid, message: isValidPincode.message },
                state : { isValid: isValidState.valid, message: isValidState.message },
                city : { isValid: isValidCity.valid, message: isValidCity.message }
            });
        }
    }
    handleUpload(event) {
        const target = Array.from(event.target.files);
        if (target) {
            this.setState({
                profile : target[0]
            });
        }

    }


    onCountryChange (country_id, country_name) {
        this.setState({profile_country : country_name, profile_country_id: country_id});
        countryid = country_id;
        let filteredcountries = countries.filter(this.filterCountry);
        states = filteredcountries[0].states;
        this.setState({ profile_state_id : states[0].id, profile_state : states[0].name });
    }

    filterCountry(country) {
        return country.id == countryid;
    }

    onStateChange (state_id, state_name) {
        this.setState({profile_state : state_name , profile_state_id : state_id});
        stateid = state_id;
        let filteredstates = states.filter(this.filterState);
        this.setState({cities:filteredstates[0].cities, profile_city_id : filteredstates[0].cities[0].id, profile_city : filteredstates[0].cities[0].name });
    }
    filterState(state) {
        return state.id == stateid;
    }

    onCityChange (city_id, city_name) {
        this.setState({profile_city : city_name, profile_city_id : city_id});
    }

    handleDateChange = date => {
        this.setState({ dob: { isValid: false, message: '', value: date }, profile_dob :date });

    }
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    callPhonechange = () => {
        let otp = '';
        for (let i = 1; i <= this.Otp.state.otpCount; i++) {
            otp += this.Otp[`otp${i}`].value;
        }
        const phoneData={
            verification_code: parseInt(this.state.Vcode.data.verification_code),
            otp: parseInt(otp),
        };
        this.props.changePhonedata(phoneData);
    }
    showDropdownMenu(dropdownno) {
        // event.preventDefault();
        this.setState({ displayMenu: true , dropdownno : dropdownno }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });

    }
    render() {
        const{ name,phone,otp_type,numberMessage,changenumberMessage, email,address,dob,pincode, city, state, cities, profile_name,profile_phone, profile_email,profile_address,profile_dob,profile_pincode, profile_country, profile_state, profile_city,profile_photo, messageSuccess, phonechange } = this.state;
        return (
            <div className={cx(styles.refer_index)}>
                <div>
                    <div className={cx('row')}>
                        <div className={cx('col-md-2 col-sm-4 col-xs-12')}>
                            <div className={cx(styles.refer_index)}>
                                <div className={cx('row')}>
                                    <div className={cx('col')}>
                                        <div className={cx(styles.manage_bottom_margin)}>
                                            {profile_photo == null ?
                                                <img className={cx(
                                                    styles.manage_profile_image
                                                )} src={require('images/dummyuser.png')} alt='Profile.jpeg' /> :
                                                <img className={cx(
                                                    styles.manage_profile_image
                                                )} src={profile_photo} alt='Profile.jpeg' />

                                            }
                                            <div className={cx('row')} >
                                                <div className={cx(styles.imagelink, 'col-md-12 col-sm-12 col-xs-12')}>

                                                    <label htmlFor="file-upload">
                                                        <p className={cx(styles.changeimage)}>Change picture</p>
                                                    </label>
                                                    <input id="file-upload" type="file" onChange={this.handleUpload} accept=".jpg" />

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className={cx('col-md-10 col-sm-8 col-xs-12')}>
                            <div className = {cx(styles.notestyles)}>
                                <span>(Fields marked with <span className = {cx(styles.asteriskstyle)}>**</span> will not be visible in your public profile information)</span>
                            </div>
                            <div className={cx(styles.manage_top_margin, 'row')}>
                                <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <fieldset className={cx(globestyle.fset)}>
                                            <legend className={cx(globestyle.legend,globestyle.small)}>Name</legend>
                                            <input value={profile_name ? profile_name:''} ref='name' type='text' className={cx(globestyle.small,'form-control')} onChange={(event) => this.setState({ profile_name: event.target.value })} />
                                        </fieldset>
                                        <span className={!name.isValid ? 'text-danger small font-italic' : 'd-none'}>{!name.isValid ? name.message : ''}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={cx('row')}>
                                <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <fieldset className={cx(globestyle.fset)}>
                                            <legend className={cx(globestyle.legend,globestyle.small)}>DOB</legend>
                                            <div className={cx(styles.colstyle,'col-md-6')}>
                                                <div>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <DatePicker
                                                            keyboard
                                                            format="YYYY-MM-DD"
                                                            disablePast={false}
                                                            value={profile_dob}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                placeholder: 'Select date',
                                                            }}
                                                            onChange={this.handleDateChange.bind(this)}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <span className={!dob.isValid ? 'text-danger small font-italic' : 'd-none'}>{!dob.isValid ? dob.message : ''}</span>
                                    </div>
                                </div>
                                <span className = {cx(styles.asteriskstyle)}>**</span>
                            </div>
                            <div className={cx('row')}>
                                <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <fieldset className={cx(globestyle.fset)}>
                                            <legend className={cx(globestyle.legend,globestyle.small)}>Mobile number</legend>
                                            <input ref='phone' placeholder='+91|' type='text' className={cx(globestyle.small,'form-control')} maxLength='10'  value = { profile_phone ? profile_phone : ''} onChange ={(event) => this.setState({ profile_phone : event.target.value})}/>
                                        </fieldset>
                                        <span className={!phone.isValid ? 'text-danger small font-italic' : 'd-none'}>{!phone.isValid ? phone.message : ''}</span>
                                        <span className={ 'text-danger small font-italic' }>{numberMessage ? numberMessage : ''}</span>
                                    </div>
                                </div>
                                <span className = {cx(styles.asteriskstyle)}>**</span>
                            </div>
                            <div className={cx('row')}>
                                <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <fieldset className={cx(globestyle.fset)}>
                                            <legend className={cx(globestyle.legend,globestyle.small)}>Email ID</legend>
                                            <input ref='email' type='text' className={cx(globestyle.small,'form-control')} value = { profile_email ? profile_email : ''} onChange = {(event) => this.setState({ profile_email : event.target.value})}/>
                                        </fieldset>
                                        <span className={!email.isValid ? 'text-danger small font-italic' : 'd-none'}>{!email.isValid ? email.message : ''}</span>
                                    </div>
                                </div>
                                <span className = {cx(styles.asteriskstyle)}>**</span>
                            </div>
                            <div className={cx('row')}>
                                <div className={cx('col-md-6 col-sm-6 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <fieldset className={cx(globestyle.fset)}>
                                            <legend className={cx(globestyle.legend,globestyle.small)}>Address</legend>
                                            <input ref='address' type='text' className={cx(globestyle.small,'form-control')} value = { profile_address ? profile_address : ''} onChange = {(event) => this.setState({ profile_address : event.target.value})}/>
                                        </fieldset>
                                        <span className={!address.isValid ? 'text-danger small font-italic': 'd-none'}>{!address.isValid ? address.message: ''}</span>
                                    </div>
                                </div>
                                <span className = {cx(styles.asteriskstyle)}>**</span>
                            </div>
                            <div className={cx('row')}>
                                <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <fieldset className={cx(globestyle.fset)}>
                                            <legend className={cx(globestyle.legend,globestyle.small)}>Pincode</legend>
                                            <input ref='pincode' type='text' className={cx(globestyle.small,'form-control')} maxLength='6' value = { profile_pincode ? profile_pincode :''} onChange = {(event) => this.setState({ profile_pincode : event.target.value })}/>
                                        </fieldset>
                                        <span className={!pincode.isValid ? 'text-danger small font-italic': 'd-none'}>{!pincode.isValid ? pincode.message: ''}</span>
                                    </div>
                                </div>
                                <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <fieldset className={cx(globestyle.fset)}>
                                            <legend className={cx(globestyle.legend,globestyle.small)}>Country</legend>
                                            <div className={cx(globestyle.selectdiv)}>
                                                <div className='dropvalue' onClick={()=>this.showDropdownMenu(1)}>{profile_country ? profile_country :'Select country'}</div>
                                                {this.state.displayMenu && this.state.dropdownno == 1 ?
                                                    <ul>
                                                        {
                                                            _.map(countries, (country, i) => {
                                                                return (
                                                                    <li onClick = {()=>this.onCountryChange(country.id, country.name)} key = {i}>{country.name}</li>
                                                                );
                                                            })
                                                        }
                                                    </ul>: null
                                                }
                                            </div>
                                        </fieldset>
                                        <span className={!state.isValid ? 'text-danger small font-italic' : 'd-none'}>{!state.isValid ? state.message : ''}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('row')}>
                                <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <fieldset className={cx(globestyle.fset)}>
                                            <legend className={cx(globestyle.legend,globestyle.small)}>State</legend>
                                            <div className={cx(globestyle.selectdiv)}>
                                                <div className='dropvalue' onClick={()=>this.showDropdownMenu(2)}>{profile_state ? profile_state :'Select state'}</div>
                                                {this.state.displayMenu && this.state.dropdownno == 2 ?
                                                    <ul>
                                                        {
                                                            _.map(states, (state, i) => {
                                                                return (
                                                                    <li onClick = {()=>this.onStateChange(state.id, state.name)} key = {i}>{state.name}</li>
                                                                );
                                                            })
                                                        }
                                                    </ul>: null
                                                }
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                                    <div className={cx('form-group')}>
                                        <fieldset className={cx(globestyle.fset)}>
                                            <legend className={cx(globestyle.legend,globestyle.small)}>City</legend>
                                            <div className={cx(globestyle.selectdiv)}>
                                                <div className='dropvalue' onClick={()=>this.showDropdownMenu(3)}>{profile_city ? profile_city :'Select city'}</div>
                                                {this.state.displayMenu && this.state.dropdownno == 3?
                                                    <ul>
                                                        {
                                                            _.map(cities, (city, i) => {
                                                                return (
                                                                    <li onClick = {()=>this.onCityChange(city.id, city.name)} key = {i}>{city.name}</li>
                                                                );
                                                            })
                                                        }
                                                    </ul>: null
                                                }
                                            </div>
                                        </fieldset>
                                        <span className={!city.isValid ? 'text-danger small font-italic' : 'd-none'}>{!city.isValid ? city.message : ''}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                            <ModalBody className={globestyle.messageblock}>
                                <div>
                                    <a className={cx('float_right', globestyle.close_button)} onClick={this.toggle}>
                                        <i className={cx('far fa-times-circle')}></i>
                                    </a>
                                </div>
                                {messageSuccess ?
                                    <span className={ 'text-success font-weight-bold d-flex justify-content-center'}>{ 'Your information saved successfully'}</span> : null}
                                {phonechange && phonechange ?
                                    <div>
                                        <span className={changenumberMessage ? 'text-success font-weight-bold d-flex justify-content-center' : 'd-none'}>{changenumberMessage ? changenumberMessage : ''}</span>
                                        <Otp phone={profile_phone ? profile_phone : null} otpType={otp_type ? otp_type : null} onRef={ref => (this.Otp = ref)} />
                                        <div className={cx('row justify-content-md-center')}>
                                            <div className={cx('text-center')} >
                                                <button
                                                    className={cx('theme-btn', 'blue')}
                                                    onClick={this.callPhonechange}
                                                    type="button">
                                                    SUBMIT
                                                </button>
                                            </div>
                                        </div>
                                    </div> : null
                                }

                            </ModalBody>
                        </Modal>

                    </div>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        cityDetails: state.app.statedetails,
        otp: state.app.otp ,
        numberMessage:state.app.numberMessage,
        profileDetails:state.app.getprofiledetails,
        submitDetails:state.app.submitprofiledetails,
        newphonenumber:state.app.newphonenumber,
        otpLoginResult: (state.app.otplogin ? state.app.otplogin : null),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        tofetchCities: () => dispatch(actions.fetchcities()),
        sendOtp: (otpData) => dispatch(actions.getOtp(otpData)),
        onSubmitdetails:(profileData) => dispatch(actions.editProfiledetails(profileData)),
        onGetprofiledetails:()=>dispatch(actions.getProfiledetails()),
        changePhonedata:(phoneData)=>dispatch(actions.toeditPhonenumber(phoneData)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Myprofile);
