import { combineReducers } from 'redux';

import app from './app';
import testseries from './testseries';

export default combineReducers({
    app,                          /** reducer for home-page */
    testseries,                  /** reducer for test-series */
});


