import _ from 'lodash';

export default _.mapKeys([
    'APP_GET_EXAMS',
    'APP_GET_BOARDS',
    'APP_LOGIN',
    'APP_SIGNUP',
    'APP_SENDOTP',
    'GET_DRAFT_LIST',
    'GET_PUBLISHED_LIST',
    'GET_UNPUBLISHED_LIST',
    'GET_LIST_OF_TEST',
    'GET_LIST_OF_EXAMS',
    'GET_EXAM_LIST',
    'MOVE_DRAFT_TO_PUBLISH',
    'MOVE_DRAFT_TO_UNPUBLISHED',
    'SCHEDULE_PUBLISH',
    'ADD_NEW_TEST',
    'ADD_NEW_TEST_SERIES',
    'APP_VERIFY_OTP',
    'APP_REQUEST_CALL',
    'APP_EXAM_SUBJECT_LIST',
    'GET_LIST_OF_PARENT_TEST',
    'APP_UPLOADED_FILES',
    'ADD_UPLOADED_FILES',
    'ADD_TEST_SERIES',
    'GET_ALL_REVENUE',
    'GET_REVENUE_DETAILS',
    'GET_REVENUE_FOR_TEST_SERIES',
    'GET_ACTIVE_DATA',
    'REGISTER_FOR_EXAM',
    'SEND_OTP_WSIGNUP',
    'SUBMIT_QUERY',
    'FORGOT_PASSWORD',
    'CHANGE_PASSWORD',
    'DELETE_TEST_FILE',
    'UPDATE_TEST_FILE',
    'APP_SUBMIT_TEST',
    'DEVELOPED_CONTENT',
    'CREATE_ISSUE',
    'ADD_COMMENT',
    'RESOLVED',
    'FINANCIAL_DETAILS',
    'GET_FINANCIAL_DETAILS',
    'PROFFESSIONAL_DETAILS',
    'GET_PROFFESSIONAL_DETAILS',
    'PROFILE_DETAILS',
    'GET_PROFILE_DETAILS',
    'FETCH_STATES_CITIES',
    'EDIT_PHONE_NUMBER',
]);
