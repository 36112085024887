import React from 'react';
import { Link } from 'react-router-dom';
import styles from './help.scss';
import globestyle from '../../sass/global.scss';
import cx from 'classnames';
import Help from '../../images/Help.jpg';
import validation from '../../validations';
import actions from '../../redux/actions/app.js';
import { connect } from 'react-redux';
import PopUpModal from './../PopUpModal';

class Helpform extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            subject: { isValid: false, message: '' },
            query: { isValid: false, message: '' },
            queryresult:'',
            activePopup: '',
        };
        this.handlesubmit = this.handlesubmit.bind(this);
        this.showPopup = this.showPopup.bind(this);
    }


    showPopup = params => {
        this.setState({
            activePopup: params,
        });
        this.PopUpModal.toggle();
    };

    handlesubmit(){
        const subject = this.refs.subject.value;
        const query = this.refs.query.value;
        const isValidSubject = validation('subject', subject);
        const isValidQuery = validation('query', query);
        if(isValidSubject.valid && isValidQuery.valid) {
            this.setState({
                subject: { isValid: true, message: ''},
                query: { isValid: true, message: ''},
            });
            const submitData = { subject: subject, query: query};
            this.props.onSubmitquery(submitData);
            document.getElementById('subject').value = '';
            document.getElementById('query').value = '';
        } else {
            this.setState({
                subject: { isValid: isValidSubject.valid, message: isValidSubject.message },
                query: { isValid: isValidQuery.valid, message: isValidQuery.message },
            });
        }


    }
    componentDidUpdate(nextProps) {
        if (this.props.queryresult !== nextProps.queryresult && this.props.queryresult) {
            if (this.props.queryresult.status_code == 200) {
                this.setState({ queryresult: this.props.queryresult.status});
            }
        }
    }


    render() {
        const { subject,query,queryresult,activePopup} = this.state;

        return (
            <div className={cx(styles.refer_index)}>
                <nav aria-label="breadcrumb">
                    <ol className={cx('breadcrumb', globestyle.breadcrumb)}>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem)}><Link to='/test-series'>Home</Link></li>
                        <li className={cx('breadcrumb-item', globestyle.breadcrumbitem, globestyle.active)} aria-current="page"><span>Help</span></li>
                    </ol>
                </nav>
                {queryresult ?
                    <div className={cx('alert alert-success')}>
                        <span className={cx(styles.setheight)}>Your response has been submitted successfully.</span>
                    </div> : null
                }
                <div className={cx('clearfix')}></div>
                <div className={cx(styles.maincontent)}>
                    <div className={cx('row')}>
                        <div className={cx('col')}>
                            <div className={cx(styles.manage_bottom_margin)}>
                                <p className={cx(styles.refer_tagp)}>
                                    <strong>Complaints / Query</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <form>
                        <div className={cx('row')}>
                            <div className={cx('col-md-4 col-sm-5 col-xs-12')}>
                                <div className={cx('form-group')}>
                                    <fieldset className={cx(globestyle.fset)}>
                                        <legend className={cx(globestyle.legend)}>Subject</legend>
                                        <input type='text' ref='subject' className={cx('form-control')} id = 'subject'/>
                                    </fieldset>
                                    <span className={!subject.isValid ? 'text-danger small font-italic': 'd-none'}>{!subject.isValid ? subject.message: ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className={cx('row')}>
                            <div className={cx('col-md-12 col-sm-12 col-xs-12')}>
                                <div className={cx('form-group')}>
                                    <fieldset className={cx(globestyle.fset, globestyle.lessradius)}>
                                        <legend className={cx(globestyle.legend)}>About what it is</legend>
                                        <textarea type='text' ref='query' className={cx('form-control')} rows='4' id = 'query'/>
                                    </fieldset>
                                    <span className={!query.isValid ? 'text-danger small font-italic': 'd-none'}>{!query.isValid ? query.message: ''}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className={cx('row')}>
                        <div className={cx('col-md-3 col-sm-6 col-xs-12')}>
                            <div className={cx('form-group')}>
                                <button className={cx('theme-btn', 'blue')} type='submit' onClick={this.handlesubmit}>
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={cx('d-flex justify-content-center')}>
                        <div className={cx(styles.requestbox)}>
                            <div className={cx('col-md-2 col-sm-5 col-xs-12')}>
                                <img className={cx(
                                    styles.manage_profile_image
                                )} src={Help} />
                            </div>
                            <div className={cx('col-md-5 col-sm-6 col-xs-12')}>
                                <p className={cx(styles.requesttext)}><strong>For any assistance or query</strong></p>
                                <p className={cx(styles.requestcall)}>(calling hours 7am to 9pm)</p>
                            </div>
                            <div className={cx('col-md-5 col-sm-5 col-xs-12')}>
                                <button type='button' className={cx(styles.requestbutton, 'btn btn-outline-primary')} onClick={() => this.showPopup('requestcall')}>Request a call from us</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <PopUpModal modelcontent={activePopup} onRef={ref => (this.PopUpModal = ref)} />
                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {
        queryresult: state.app.submitquery,
    };
}

function mapDispatchToProps (dispatch) {
    return {
        onSubmitquery: (submitData) => dispatch(actions.getSubmitquery(submitData)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Helpform);
