import { createAction } from 'redux-actions';
import actionTypes from './../actionTypes';
import * as apis from 'apis';

const payloadObjectMetaCreator = function() {
    return arguments[0] || {};
};

export default {
    getExams: createAction(actionTypes.APP_GET_EXAMS, apis.getExams, payloadObjectMetaCreator),
    getBoards: createAction(actionTypes.APP_GET_BOARDS, apis.getBoards, payloadObjectMetaCreator),
    getLogin: createAction(actionTypes.APP_LOGIN, apis.getLogin, payloadObjectMetaCreator),
    getSignUp: createAction(actionTypes.APP_SIGNUP, apis.getSignUp, payloadObjectMetaCreator),
    getOtp: createAction(actionTypes.APP_SENDOTP, apis.getOtp, payloadObjectMetaCreator),
    getRequestCall: createAction(actionTypes.APP_REQUEST_CALL, apis.getRequestCall, payloadObjectMetaCreator),
    getVerifyOtp: createAction(actionTypes.APP_VERIFY_OTP, apis.getVerifyOtp, payloadObjectMetaCreator),
    getDraftlist: createAction(actionTypes.GET_DRAFT_LIST, apis.getTestSerieslist, payloadObjectMetaCreator),
    getUnpublishedlist: createAction(actionTypes.GET_UNPUBLISHED_LIST, apis.getTestSerieslist, payloadObjectMetaCreator),
    getPublishedlist: createAction(actionTypes.GET_PUBLISHED_LIST, apis.getTestSerieslist, payloadObjectMetaCreator),
    getListoftest: createAction(actionTypes.GET_LIST_OF_TEST, apis.getListoftest, payloadObjectMetaCreator),
    getListofexams: createAction(actionTypes.GET_LIST_OF_EXAMS, apis.getListofexams, payloadObjectMetaCreator),
    getExamList: createAction(actionTypes.GET_EXAM_LIST, apis.getExamList, payloadObjectMetaCreator),

    getDrafttopublish: createAction(actionTypes.MOVE_DRAFT_TO_PUBLISH, apis.getDrafttopublish, payloadObjectMetaCreator),
    getDrafttounpublish: createAction(actionTypes.MOVE_DRAFT_TO_UNPUBLISHED, apis.getDrafttounpublish, payloadObjectMetaCreator),

    getschedulepublish: createAction(actionTypes.SCHEDULE_PUBLISH, apis.getschedulepublish, payloadObjectMetaCreator),
    addNewTest: createAction(actionTypes.ADD_NEW_TEST, apis.addNewTest, payloadObjectMetaCreator),
    getaddnewtestseries: createAction(actionTypes.ADD_NEW_TEST_SERIES, apis.getaddnewtestseries, payloadObjectMetaCreator),

    getSubjectList: createAction(actionTypes.APP_EXAM_SUBJECT_LIST, apis.getSubjectList, payloadObjectMetaCreator),


    getListofparenttest:createAction(actionTypes.GET_LIST_OF_PARENT_TEST, apis.getListofparenttest, payloadObjectMetaCreator),

    getUploadedFiles: createAction(actionTypes.APP_UPLOADED_FILES, apis.getUploadedFiles, payloadObjectMetaCreator),
    uploadFiles: createAction(actionTypes.ADD_UPLOADED_FILES, apis.uploadFiles, payloadObjectMetaCreator),
    getSaveTestSeries: createAction(actionTypes.ADD_TEST_SERIES, apis.getSaveTestSeries, payloadObjectMetaCreator),
    deleteFile: createAction(actionTypes.DELETE_TEST_FILE, apis.deleteFile, payloadObjectMetaCreator),
    uploadChangeFile: createAction(actionTypes.UPDATE_TEST_FILE, apis.uploadChangeFile, payloadObjectMetaCreator),
    submitTest: createAction(actionTypes.APP_SUBMIT_TEST, apis.submitTest, payloadObjectMetaCreator),

    getRevenues: createAction(actionTypes.GET_ALL_REVENUE, apis.getRevenues, payloadObjectMetaCreator),
    getRevenuedetails:createAction(actionTypes.GET_REVENUE_DETAILS, apis.getRevenuedetails, payloadObjectMetaCreator),
    getRevenuefortestseries:createAction(actionTypes.GET_REVENUE_FOR_TEST_SERIES, apis.getRevenuefortestseries, payloadObjectMetaCreator),
    getActiveData: createAction(actionTypes.GET_ACTIVE_DATA, apis.getActiveData, payloadObjectMetaCreator),
    getRegisterforexam:createAction(actionTypes.REGISTER_FOR_EXAM, apis.getRegisterforexam, payloadObjectMetaCreator),

    sendOtpWsignUp:createAction(actionTypes.SEND_OTP_WSIGNUP, apis.getSendOtpWsignUp, payloadObjectMetaCreator),
    getSubmitquery:createAction(actionTypes.SUBMIT_QUERY,apis.getSubmitquery, payloadObjectMetaCreator),
    getForgotpassword:createAction(actionTypes.FORGOT_PASSWORD,apis.getForgotpassword, payloadObjectMetaCreator),
    getChangepassword:createAction(actionTypes.CHANGE_PASSWORD,apis.getChangepassword, payloadObjectMetaCreator),
    getDevelopedContent:createAction(actionTypes.DEVELOPED_CONTENT,apis.getDevelopedContent, payloadObjectMetaCreator),
    getCreateIssue: createAction(actionTypes.CREATE_ISSUE,apis.getCreateIssue, payloadObjectMetaCreator),
    getAddComment:createAction(actionTypes.ADD_COMMENT,apis.getAddComment, payloadObjectMetaCreator),
    issueResolved:createAction(actionTypes.RESOLVED,apis.issueResolved, payloadObjectMetaCreator),
    getFinancialdetails:createAction(actionTypes.GET_FINANCIAL_DETAILS,apis.getFinancialdetails, payloadObjectMetaCreator),
    editFinancialdetails:createAction(actionTypes.FINANCIAL_DETAILS,apis.editFinancialdetails, payloadObjectMetaCreator),

    getProffesionaldetails:createAction(actionTypes.GET_PROFFESSIONAL_DETAILS,apis.getProffesionaldetails,payloadObjectMetaCreator),
    editProffesionaldetails:createAction(actionTypes.PROFFESSIONAL_DETAILS,apis.editProffesionaldetails, payloadObjectMetaCreator),

    editProfiledetails:createAction(actionTypes.PROFILE_DETAILS,apis.editProfiledetails, payloadObjectMetaCreator),
    getProfiledetails:createAction(actionTypes.GET_PROFILE_DETAILS,apis.getProfiledetails,payloadObjectMetaCreator),
    fetchcities:createAction(actionTypes.FETCH_STATES_CITIES,apis.fetchcities,payloadObjectMetaCreator),
    toeditPhonenumber:createAction(actionTypes.EDIT_PHONE_NUMBER,apis.toeditPhonenumber,payloadObjectMetaCreator),
};
