import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// import BackImage from 'client/images/back.svg';
import backUrl, { ReactComponent as BackImage } from 'client/images/back.svg';
import { ReactComponent as UltimateImage } from 'client/images/ultimate.svg';

// const backImage = require('client/images/back.svg');

class ExamPage extends Component {
    render() {
        const metaData = {
            title: 'Exam page',
            desc: 'This is description',
        };

        return (
            <div>
                <Helmet>
                    <title>{metaData.title}</title>
                    <meta name="description" content={metaData.desc} />
                    <meta property="og:title" content={metaData.title} />
                    <meta property="og:description" content={metaData.desc} />
                </Helmet>
                <h1>ExamPage</h1>
                <div>
                    <UltimateImage style={{width: '100px', height: '100px'}}/>
                </div>

                <Link to={'/'} title="Home Page">
                    <img src={backUrl} alt="Back" style={{width: '20px'}}/>
                    <BackImage style={{width: '20px', fill: '#55bbea'}}/>
                    Back to Home Page
                </Link>
            </div>
        );
    }
}

export default ExamPage;
